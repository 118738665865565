import { SET_MOST_RECENT_POST_FILTER } from "../ActionTypes";

const initialState = [];

const RecentPostReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MOST_RECENT_POST_FILTER:
            const previousValue = state?.value;
            console.log('state in MOST RECENT POST', action.payload)
            return action.payload;
        default:
            return state;
    }
};

export default RecentPostReducer