import { Label, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  FaCloudDownloadAlt,
  FaInstagram,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import LoadingSpinner from "../ResultPage/LoadingSpinner";
import TableSkeleton from "../hashtagReport/TableSkeleton";
import { HiBadgeCheck, HiTrash } from "react-icons/hi";
import InfoIconTooltip from "../../components/SearchResultFilters/InfoIconTooltip";
import axiosInstance from "../../interseptor"; // Ensure this import path is correct
import { Link } from "react-router-dom";
import ViralTableSkelaton from "../viralbrandreport/ViralTableSkelaton";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const UPDATE_BRAND_LIST_URL = "/livesearch/viral_brand_update";
const UPDATE_PROFILE_URL = "/livesearch/viral_profile_update";
const UPDATE_INFLUENCER_LIST_URL = "/livesearch/get_influencer_profile";

const CategoryPage = () => {
  const [reportList, setReportList] = useState([]);
  const [influencerList, setInfluencerList] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [isLoading, setIsLoading] = useState(true);
  const [countryList, setCountryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandInput, setBrandInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedCategoriesId, setSelectedCategoriesId] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [disableButton, setDisableButton] = useState();
  const [searchString, setSearchString] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityId, setSelectedCityId] = useState("");
  const [citiesData, setCitiesData] = useState({});
  const [selectedDates, setSelectedDates] = useState({});
  const [userLoading, setUserLoading] = useState({});
  const [userData, setUserData] = useState({});

  const handleStartDateChange = (event, userId) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [userId]: { ...prevDates[userId], startDate: event.target.value }
    }));
  };

  const handleEndDateChange = (event, userId) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [userId]: { ...prevDates[userId], endDate: event.target.value }
    }));
  };

  const type = ["Profile", "Channel", "Fanpage", "Community", "Brand"];
  const gender = ["Male", "Female", "Nuetral"];

  const storedUsername = localStorage.getItem("userdata");
  const username = JSON.parse(storedUsername);

  const updateViralBrandList = async (selectedCountry, selectedCityId, selectedCategoriesId, selectedGender, selectedType, username, userId, action) => {
    const filteredCategory = selectedCategoriesId[userId] || [];
    const filteredCity = selectedCityId[userId] || [];
    const filteredCountry = selectedCountry[userId] || [];
    console.log(filteredCountry, 'selectedCountry')

    try {
      const data = new FormData();
      data.append("logintype", "viral_profile_update");
      data.append("device_id", "search");
      data.append("data", "all");
      data.append("update_country", filteredCountry || "");
      data.append("update_city", filteredCity || "");
      data.append("update_category", filteredCategory || "");
      data.append("gender", selectedGender.toLowerCase() || "");
      data.append("update_type", selectedType.toLowerCase() || "");
      data.append("username", username.toLowerCase() || "");
      data.append("user_id", userId || "");
      data.append("type", action);
      
      const response = await axiosInstance.post(UPDATE_PROFILE_URL, data);
      console.log(response?.data?.d, "Response from API");
      
    } catch (error) {
      console.error("Error fetching report list:", error);
    }

    setDisableButton(userId);
  };

  const GET_CATEGORY_LIST_URL = "/livesearch/category";

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    const fetchNew = async () => {
      try {
        setLoading(true);
  
        const formdata = new FormData();
        formdata.append("logintype", "profile_count");
        formdata.append("device_id", "search");
  
        const result = await axiosInstance.post("livesearch/profile_count", formdata);
        console.log("result 212", result);
        
      } catch (error) {
        console.error("Error:", error);
      } finally {
      }
    };

    fetchNew()
  },[userLoading])

  const fetchCountryList = async () => {
    try {
      setLoading(true);

      const formdata = new FormData();
      formdata.append("logintype", "location");
      formdata.append("device_id", "search");

      const result = await axiosInstance.post("livesearch/location", formdata);
      console.log("result 11", result);
      if (result?.data?.status === 1) {
        const sortedData = result?.data?.list.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        console.log("sorted data country", sortedData);
        setCountryList(sortedData);
      } else {
        console.error("Error:", result.msg);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCategoryList = async () => {
    try {
      const data = new FormData();
      data.append("logintype", "category");
      data.append("device_id", "search");

      const response = await axiosInstance.post(GET_CATEGORY_LIST_URL, data);
      console.log("checking category", response?.data?.list);
      setCategoryList(response?.data?.list);
      if (response?.data?.status === 0) {
      } else {
      }
    } catch (error) {
      console.error("Error fetching report list:", error);
    }
  };

  // Simulate fetching data
  useEffect(() => {
    const fetchDataInParallel = async () => {
      try {
        // Start all fetch operations in parallel
        await Promise.all([fetchCountryList(), getCategoryList()]);

        // Set the report data with dummy data
        // setReportData(dummyReportData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        // Set loading state to false after all data is fetched
        // setIsLoading(false);
      }
    };

    fetchDataInParallel();
  }, []);
  
  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1); // Increment current page
  };

  const getReportList = async (brandInput, index) => {
    // const GET_REPORT_LIST_URL = "/livesearch/reportlist"
    setIsLoading(true);
    setCurrentTab(index || 0);
    try {
      const data = new FormData();
      data.append("logintype", "get_influencer_profile");
      data.append("device_id", "search");
      data.append("is_updated", 1);
      data.append("brand_name", brandInput);
      data.append("type", index);

      const response = await axiosInstance.post(
        UPDATE_INFLUENCER_LIST_URL,
        data
      );
      console.log("checking reportlist", response?.data?.d);
      if (response?.data?.status === 0) {
        setIsLoading(false);
        setInfluencerList([]);
      } else {
        setIsLoading(false);
        setInfluencerList(response?.data?.sucess);
      }
    } catch (error) {
      console.error("Error fetching report list:", error);
      setIsLoading(false);
    }
  };

  const getCities = (countryId, userId) => {
    const formData = new FormData();
    formData.append("id", countryId);
  
    axios
      .post("https://viralpitch.co/d2/dashboard/influencer/getstate.php", formData)
      .then((response) => {
        // Store cities data only for this user
        setCitiesData((prev) => ({
          ...prev,
          [userId]: response.data, // Store cities data for the specific userId
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //   console.log(brandInput, 'brandInpbrandInputut')
  // const getInfluencerList = async () => {
  //     try {
  //         const data = new FormData();
  //         data.append("logintype", "get_influencer_profile");
  //         data.append("device_id", "search");

  //         const response = await axiosInstance.post(UPDATE_INFLUENCER_LIST_URL, data);

  //         console.log('checking reposartlist', response?.data?.sucess)
  //         setInfluencerList(response?.data?.sucess)

  //     } catch (error) {
  //         console.error("Error fetching report list:", error);
  //     }
  // };

  useEffect(() => {
    getReportList("report", 0);
  }, []);

  const handleSort = (column) => {
    // If the same column is clicked, toggle the sorting order
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the new sorting column
      setSortColumn(column);
      setSortOrder("asc"); // Default to ascending order
    }
  };

  const getUsername = (link) => {
    let username = link?.split("/").pop();
    return username;
  };

  const handleSelectedCountry = (event, userId) => {
  const countryId = event.target.value;
  
  // Set selected country for this user
  setSelectedCountry((prev) => ({
    ...prev,
    [userId]: countryId,
  }));

  getCities(countryId, userId); // Fetch cities for this country and user
  };

  const handleSelectedCity = (selectedList, selectedItem, userId) => {
    setSelectedCity((prev) => ({
      ...prev,
      [userId]: selectedItem.name, // Save selected city for the user
    }));

    setSelectedCityId((prev) => ({
      ...prev,
      [userId]: selectedItem.id, // Save selected city for the user
    }));
  };

  const formatCitiesData = () => {
    return Object.entries(citiesData).map(([name, id]) => ({
      name: name || '', // Default to an empty string if name is undefined
      id: id || '' // Default to an empty string if id is undefined
    }));
  };

  const handleAddCategory = (userId, selectedList, selectedItem) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [userId]: [...(prev[userId] || []), selectedItem.name],
    }));

    setSelectedCategoriesId((prev) => ({
      ...prev,
      [userId]: [...(prev[userId] || []), selectedItem.id],
    }));
  };

  const handleRemoveCategory = (userId, selectedList, removedItem) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [userId]: (prev[userId] || []).filter(
        (item) => item !== removedItem.name
      ),
    }));
    setSelectedCategoriesId((prev) => ({
      ...prev,
      [userId]: (prev[userId] || []).filter((item) => item !== removedItem.id),
    }));
  };

  const handleSelectedType = (event) => {
    setSelectedType(event.target.value);
  };

  const handleSelectedGender = (event) => {
    setSelectedGender(event.target.value);
  };

  const paginatedReportList = influencerList?.slice(
    0,
    currentPage * ITEMS_PER_PAGE
  );

  const handleSearch = (searchInput) => {
    if (searchInput && typeof searchInput === 'string') {
      setSearchString(searchInput.toLowerCase());
    }
  };

  const handleUserData = async (userId, selectedDates) => {
    console.log(selectedDates, 'userId, selectedDates')

    if (Object.keys(selectedDates).length === 0) {
      return toast.error("Please select start date and end date");
    }else if(!selectedDates[userId].startDate){
      return toast.error("Please select start date");
    }else if(!selectedDates[userId].endDate){
      return toast.error("Please select end date");
    }

    try {
      setUserLoading((prevLoading) => ({ ...prevLoading, [userId]: true }));
      
      const formdata = new FormData();
      formdata.append("logintype", "update_creator_details");
      formdata.append("device_id", "search");
      formdata.append("userId", userId);
      formdata.append("startDate", selectedDates[userId]?.startDate || '');
      formdata.append("endDate", selectedDates[userId]?.endDate || '');  
      
      const result = await axiosInstance.post("livesearch/update_creator_details", formdata);
      console.log("result 212", result);
      setUserData((prevLoading) => ({ ...prevLoading, [userId]: true }));
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setUserLoading((prevLoading) => ({ ...prevLoading, [userId]: false }));
    }
  }
  
  return (
    <>
      <ToastContainer autoClose={4000}/>
      <div className="w-[98%] mx-[1%] mt-2   flex flex-col  ">
        <div className="flex justify-between">
          <div className="w-full flex items-center justify-between">
            <div className="ml-3">
              <h1 className="text-2xl font-semibold text-[#323237]">
                Update Influencers
              </h1>
              <div className="mt-2 flex gap-2">
                <button
                  onClick={() => {
                    setBrandInput("report");
                    getReportList("report", 0);
                    setIsLoading(true);
                  }}
                  className={`border w-40 py-2 px-2 ${
                    currentTab === 0 ? "bg-[#1dbb99]" : "bg-[#323237]"
                  } text-white rounded-md`}
                >
                  Report
                </button>
                <button
                  onClick={() => {
                    setBrandInput("archived");
                    getReportList("archived", 1);
                    setIsLoading(true);
                  }}
                  className={`border w-40 py-2 px-2 ${
                    currentTab === 1 ? "bg-[#1dbb99]" : "bg-[#323237]"
                  } text-white rounded-md`}
                >
                  Archived
                </button>
                <button
                  onClick={() => {
                    setBrandInput("updated");
                    getReportList("updated", 2);
                    setIsLoading(true);
                  }}
                  className={`border w-40 py-2 px-2 ${
                    currentTab === 2 ? "bg-[#1dbb99]" : "bg-[#323237]"
                  } text-white rounded-md`}
                >
                  Updated
                </button>
              </div>
              <p className="mt-3 text-gray-500">
                Showing {paginatedReportList?.length} / {influencerList?.length}
              </p>
            </div>
          </div>
        </div>

        <div className="w-full overflow-x-scroll">
        {
          <table className="mt-5 w-full text-sm overflow-x-scroll border text-left text-gray-500 dark:text-gray-400">
            <thead className="sticky top-0 bg-[#EEF5FF] text-md text-black">
              <tr className="h-12">
                <th className="sticky left-0 z-30 bg-[#EEF5FF]  text-sm font-[300] capitalize pl-2">Name</th>
                <th className="text-sm font-[300] capitalize pl-2">Followers</th>
                <th className="text-sm font-[300] capitalize pl-2">Start Date</th>
                <th className="text-sm font-[300] capitalize pl-2">End Date</th>
                <th className="text-sm font-[300] capitalize pl-2">User</th>
                <th className="text-sm font-[300] capitalize pl-2">Country</th>
                <th className="text-sm font-[300] capitalize pl-2">City</th>
                <th className="text-sm font-[300] capitalize pl-2">Category</th>
                <th className="text-sm font-[300] capitalize pl-2">Gender</th>
                <th className="text-sm font-[300] capitalize pl-2">Type</th>
                {username?.name === "Aakash Dubey" ? (
                  <></>
                ) : (
                  <th className="text-sm font-[300] capitalize"></th>
                )}

                <th className="text-sm font-[300] capitalize"></th>
              </tr>
            </thead>

            <tbody className="divide-y">
              {isLoading ? (
                <ViralTableSkelaton />
              ) : (
                paginatedReportList?.map((item, index) => {
                  return (
                    <tr key={index} className="py-2  my-auto">
                      <td className="sticky left-0 z-30 bg-white  pl-2 flex items-center py-2">
                        <div className="flex gap-2 items-center">
                          {getUsername(item?.username) !== undefined && (
                            <div className="w-14 h-14 rounded-full shadow-md border overflow-hidden">
                              <img
                                className="w-full h-full object-cover"
                                src={
                                  item?.profile_image ||
                                  "/asset/onErrorImage.jpg"
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null || undefined; // prevents looping
                                  currentTarget.src = "/asset/onErrorImage.jpg";
                                }}
                                alt="img"
                              />
                            </div>
                          )}

                          {getUsername(item?.username) !== undefined && (
                            <div className="flex flex-col">
                              <div className="flex items-center gap-0.5">
                                <Link
                                  to={`https://www.instagram.com/${item?.username}/`}
                                  target="_blank"
                                  className="hover:text-blue-500 font-semibold" // Use a suitable hover class here
                                >
                                  {getUsername(item?.username)}
                                </Link>
                                {item?.is_verified && (
                                  <HiBadgeCheck
                                    className="text-primary-500"
                                    size={16}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="pl-2 text-center">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(item?.followers)}
                      </td>
                      <td className="pl-2">
                        <input
                          type="date"
                          className="rounded-sm border-gray-300 text-sm"
                          id="date-input"
                          value={selectedDates[item.userId]?.startDate || ''}
                          onChange={(e) => handleStartDateChange(e, item?.userId)}
                        />
                      </td>
                      <td className="pl-2">
                        <input
                          type="date"
                          className="rounded-sm border-gray-300 text-sm"
                          id="date-input"
                          value={selectedDates[item.userId]?.endDate || ''}
                          onChange={(e) => handleEndDateChange(e, item?.userId)}
                        />
                      </td>
                      <td className="pl-2">
                        {
                          userLoading[item.userId] ? <LoadingSpinner/> 
                          : userData[item.userId] ? 
                          <select
                            onChange={handleSelectedGender}
                            className="text-sm h-10 rounded-sm border-gray-300"
                          >
                            <option>{"Select"}</option>
                            {brandInput !== "archived" &&
                              gender?.map((category, index) => (
                                <option key={index}>{category}</option>
                              ))}
                          </select>
                        : <button 
                          onClick={() => handleUserData(item?.userId, selectedDates)}
                          className="bg-black text-white px-2 py-2 rounded-md whitespace-nowrap"
                          >
                            Get Data
                          </button>
                        }
                      </td>
                      <td className="pl-2">
                        {item.country !== 0 &&
                          item.country !== "0" &&
                          item.country !== "" && (
                            <p className="capitalize pb-0.5">{item?.country}</p>
                          )}
                        <select
                          onChange={(e) => handleSelectedCountry(e, item?.userId)}
                          className="text-sm h-10 rounded-sm border-gray-300"
                        >
                          <option>{item?.country_vp || "Select"}</option>
                          {countryList.map((data, index) => (
                            <option key={index} value={data?.id}>
                              {data?.name}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className="pl-2">
                        <Multiselect
                          className="bg-white"
                          options={
                            citiesData[item?.userId]
                              ? Object.entries(citiesData[item?.userId]).map(([name, id]) => ({
                                  name,
                                  id,
                                }))
                              : [] // Show empty if no cities are available for this user
                          }
                          displayValue="name"
                          selectedValues={
                            Array.isArray(selectedCity[item?.userId])
                              ? selectedCity[item?.userId].map((name) => ({
                                  name,
                                  id: citiesData[item?.userId]?.[name], // Make sure id exists
                                }))
                              : [] // Fallback to an empty array if not an array
                          }
                          onSelect={(selectedList, selectedItem) =>
                            handleSelectedCity(selectedList, selectedItem, item?.userId)
                          }
                          onRemove={(selectedList, removedItem) =>
                            handleSelectedCity(selectedList, removedItem, item?.userId)
                          }
                          placeholder="Select City"
                          loading={loading}
                          onSearch={handleSearch} 
                          style={{
                            multiselectContainer: {
                              width: "100%",
                              borderRadius: "0.375rem",
                              // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            },
                            searchBox: {
                              border: "0.6px solid #D9D9D9",
                              borderRadius: "0.375rem",
                              padding: "0px",
                            },
                            inputField: {
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#333",
                            },
                            chips: {
                              background: "#6d5eac",
                              color: "#fff",
                            },
                            option: {
                              color: "#333",
                            },
                            groupHeading: {
                              color: "#6d5eac",
                              background: "#6d5eac",
                            },
                            dropdownList: {
                              background: "#6d5eac",
                              "&:hover": {
                                background: "#6d5eac",
                              },
                            },
                          }}
                        />
                      </td>
                      <td className="pl-2">
                        <Multiselect
                          className="bg-white"
                          options={categoryList} // Assuming this is correctly structured with id, name, and icon
                          displayValue="name" // Display only the name
                          selectedValues={(
                            selectedCategories[item?.userId] || []
                          ).map((name) => {
                            return categoryList.find(
                              (category) => category?.name === name
                            );
                          })}
                          onSelect={(selectedList, selectedItem) =>
                            handleAddCategory(
                              item?.userId,
                              selectedList,
                              selectedItem
                            )
                          }
                          onRemove={(selectedList, removedItem) =>
                            handleRemoveCategory(
                              item?.userId,
                              selectedList,
                              removedItem
                            )
                          }
                          placeholder="Search Category"
                          loading={loading}
                          onSearch={(searchInput) => {
                            setSearchString(searchInput); // Update the search string state
                          }}
                          style={{
                            multiselectContainer: {
                              width: "100%",
                              borderRadius: "0.375rem",
                              // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            },
                            searchBox: {
                              border: "0.6px solid #D9D9D9",
                              borderRadius: "0.375rem",
                              padding: "0px",
                            },
                            inputField: {
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#333",
                            },
                            chips: {
                              background: "#6d5eac",
                              color: "#fff",
                            },
                            option: {
                              color: "#333",
                            },
                            groupHeading: {
                              color: "#6d5eac",
                              background: "#6d5eac",
                            },
                            dropdownList: {
                              background: "#6d5eac",
                              "&:hover": {
                                background: "#6d5eac",
                              },
                            },
                          }}
                        />
                      </td>
                      <td className="pl-2">
                        <select
                          onChange={handleSelectedGender}
                          className="text-sm h-10 rounded-sm border-gray-300"
                        >
                          <option>{"Select"}</option>
                          {brandInput !== "archived" &&
                            gender?.map((category, index) => (
                              <option key={index}>{category}</option>
                            ))}
                        </select>
                      </td>
                      <td className="pl-2">
                        <select
                          onChange={handleSelectedType}
                          className="text-sm h-10 rounded-sm border-gray-300"
                        >
                          <option>{item?.type || "Select"}</option>
                          {brandInput !== "archived" &&
                            type.map((data, index) => (
                              <option key={index}>{data}</option>
                            ))}
                        </select>
                      </td>
                      {username?.name === "Aakash Dubey" ? (
                        <></>
                      ) : (
                        <td className="pl-2">
                          {disableButton === item?.userId
                            ? ""
                            : brandInput !== "archived" && (
                                <button
                                  onClick={() =>
                                    updateViralBrandList(
                                      selectedCountry,
                                      selectedCityId,
                                      selectedCategoriesId,
                                      selectedGender,
                                      selectedType,
                                      item?.username,
                                      item?.userId,
                                      "archive"
                                    )
                                  }
                                >
                                  <HiTrash className="text-xl cursor-pointer" />
                                </button>
                              )}
                        </td>
                      )}
                      {username?.name === "Aakash Dubey" ? (
                        <></>
                      ) : (
                        <td className="pl-2">
                          {disableButton === item?.userId
                            ? ""
                            : brandInput !== "archived" && (
                                <button
                                  onClick={() => {
                                    updateViralBrandList(
                                      selectedCountry,
                                      selectedCityId,
                                      selectedCategoriesId,
                                      selectedGender,
                                      selectedType,
                                      item?.username,
                                      item?.userId,
                                      "update"
                                    );
                                  }}
                                  className="px-2 py-2 mr-2 border rounded-lg shadow-md bg-black text-white whitespace-nowrap"
                                  title="Click here to update the changes"
                                >
                                  Update Profile
                                </button>
                              )}

                          {item.is_live === 0 && (
                            <InfoIconTooltip
                              bgColor="text-red-600"
                              infoContent={"Post is not live/Invalid Post Link"}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        }
        </div>
        
        {influencerList?.length > currentPage * ITEMS_PER_PAGE && (
          <div className="flex justify-center my-5">
            <button
              className="px-4 py-2 bg-[#1dbb99] text-white rounded-lg shadow-md"
              onClick={handleLoadMore}
            >
              {loading ? <LoadingSpinner /> : "Load More"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CategoryPage;
