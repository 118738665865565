import React, { useState } from "react";
import { Modal, Button } from "flowbite-react";
import PaymentModal from "./PaymentModal";

const BuyCreditsModal = ({ show, onClose }) => {
  const [credits, setCredits] = useState(500);
  const [rateInRupees, setRateInRupees] = useState(credits * 10);
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreditChange = (e) => {
    const value = e.target.value;
    setCredits(value);
    setRateInRupees(value * 10);
    setErrorMessage("");
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const handleProceedToPay = () => {
    if (credits < 500) {
      setErrorMessage("Number of credits must be 500 or more.");
    } else {
      setShowPaymentModal(true);
      onClose();
    }
  };

  const handleClosePaymentModal = () => {
    setShowPaymentModal(false);
  };

  return (
    <>
      <Modal show={show} onClose={onClose} size="md" popup={true}>
        <Modal.Header>Buy Credits</Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="creditsInput"
                className="block mb-2 text-sm font-medium"
              >
                Number of Credits (Min 500)
              </label>
              <input
                id="creditsInput"
                type="number"
                value={credits}
                onChange={handleCreditChange}
                className={`block w-full p-2 text-sm border rounded-lg focus:ring focus:border-blue-300 ${
                  errorMessage ? "border-red-500" : ""
                }`} // Add a red border if there's an error
                placeholder="Enter number of credits"
              />
              {errorMessage && (
                <p className="mt-2 text-sm text-red-500">{errorMessage}</p> // Display error message
              )}
            </div>

            <div>
              <p className="text-sm">Rate in INR: ₹ {rateInRupees}</p>
            </div>

            <div>
              <label
                htmlFor="currency"
                className="block mb-2 text-sm font-medium"
              >
                Convert to
              </label>
              <select
                id="currency"
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                className="block w-full p-2 text-sm border rounded-lg focus:ring focus:border-blue-300"
              >
                <option value="INR">INR</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                {/* Add more currency options as needed */}
              </select>
            </div>

            <Button
              onClick={handleProceedToPay}
              className="w-full bg-green-500 text-white"
            >
              Proceed to Pay
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* PaymentModal for entering card and billing details */}
      {showPaymentModal && <PaymentModal onClose={handleClosePaymentModal} />}
    </>
  );
};

export default BuyCreditsModal;
