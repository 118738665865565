import React, { useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Select } from "antd";
import "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHashTagFilter,
  setKeywordsFilter,
  setMentionsFilter,
} from "../../redux/filters/actions";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const Keyword = ({hoverStates, handleMouseOver, sendToSearchResultFilter,}) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const keywordsURL = searchParams.get("keywords")
  const mentionURL = searchParams.get("mentions")
  const hashtagURL = searchParams.get("hashtag")

  const keywordsArray = keywordsURL ? keywordsURL.split(",") : [];
  const mentionArray = mentionURL ? mentionURL.split(",") : [];
  const hashtagArray = hashtagURL ? hashtagURL.split(",") : [];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const { Option } = Select;

  function handleChange(value) {
    // setSearchValue(value);
    dispatch(setKeywordsFilter(value));
    if (value.length > 0) {
      setSearchParam((searchParams) => {
          searchParams.set("keywords", value);
          return searchParams;
      });
    }else{
      setSearchParam((searchParams) => {
        searchParams.delete("keywords");
        return searchParams;
    });
    }
  }

  function handleSearch(value) {
    
  }

  function handleMentionChange(value) {
    // setSearchMentionValue(value);
    dispatch(setMentionsFilter(value));
    if (value.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("mentions", value);
        return searchParams;
      });
    }else{
      setSearchParam((searchParams) => {
        searchParams.delete("mentions");
        return searchParams;
      });
    }
  }

  function handleMentionSearch(value) {
   
  }

  function handleHashtagChange(value) {
    
    dispatch(setHashTagFilter(value));
    if (value.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("hashtag", value);
        return searchParams;
      });
    }else{
      setSearchParam((searchParams) => {
        searchParams.delete("hashtag");
        return searchParams;
      });
    }
  }

  function handleHashtagSearch(value) {
    
  }

  return (
    <>
      <div ref={buttonRef} className="Keyword flex justify-start">
        <div className="relative w-full">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="filterTextColor flex items-center bg-white"
          >
            <span
              className={`${
                isDropdownOpen ? "font-semibold" : ""
              } flex items-center`}
            >
              Keywords
              {isDropdownOpen ? (
                <RxCross2 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2  text-lg" />
              )}
            </span>
          </button>
          <ul
            ref={dropdownRef}
            className={`absolute w-[200px] z-50 p-5 bg-white rounded-lg shadow-lg ${
              isDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Keywords
                  <InfoIconTooltip infoContent="Enter one or more hashtags to discover which influencers have included them in their recent posts." />
                </div>
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  onSearch={handleSearch}
                  tokenSeparators={[","]}
                  className="w-[100px] rounded-md py-3 border-[0.6px] border-[#D9D9D9] text-gray-600"
                  placeholder="Add Keywords"
                  notFoundContent={
                    <span className="ant-empty-description pl-2 py-5 text-[#3D3D3D]">
                      Start Typing...
                    </span>
                  }
                  value={keywordsArray}
                >
                  {keywordsArray.map((keyword) => (
                    <Option key={keyword}>{keyword}</Option>
                  ))}
                </Select>
              </div>

              <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Mentions
                  <InfoIconTooltip infoContent="Identify influencers by the mentions they use in their posts texts." />
                </div>
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  onChange={handleMentionChange}
                  onSearch={handleMentionSearch}
                  tokenSeparators={[","]}
                  className="w-[100px] rounded-md py-3 border-[0.6px] border-[#D9D9D9] text-gray-600"
                  placeholder="Add Mention"
                  notFoundContent={
                    <span className="ant-empty-description pl-2 py-5 text-[#3D3D3D]">
                      Start Typing...
                    </span>
                  }
                  value={mentionArray}
                >
                  {mentionArray.map((keyword) => (
                    <Option key={keyword}>{keyword}</Option>
                  ))}
                </Select>
              </div>

              <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Hashtags
                  <InfoIconTooltip infoContent="Identify influencers by the hashtags they use in their posts texts." />
                </div>
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  onChange={handleHashtagChange}
                  onSearch={handleHashtagSearch}
                  tokenSeparators={[","]}
                  className="w-[100px] rounded-md py-3 border-[0.6px] border-[#D9D9D9] text-gray-600"
                  placeholder="Add Hashtag"
                  notFoundContent={
                    <span className="ant-empty-description pl-2 py-5 text-[#3D3D3D]">
                      Start Typing...
                    </span>
                  }
                  value={hashtagArray}
                >
                  {hashtagArray?.map((keyword) => (
                    <Option key={keyword}>{keyword}</Option>
                  ))}
                </Select>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Keyword;
