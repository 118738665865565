import { SET_AUDIENCE_GENDER_FILTER } from "../ActionTypes";

const initialState = [];

const AudienceGenderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUDIENCE_GENDER_FILTER:
            const previousValue = state?.value;
            console.log('state in audience gender', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default AudienceGenderReducer