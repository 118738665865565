import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import InfoIconTooltip from "./InfoIconTooltip";
import { setPreviouslyExported } from "../../redux/filters/actions";
import { useLocation, useSearchParams } from "react-router-dom";

const PreviouslyExportedFilter = ({ hoverStates, handleMouseOver }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();
	const searchParams = new URLSearchParams(location.search);

	const previousExportedUrl = searchParams.get("previousExported");
	const previousExportedArray = previousExportedUrl ? true : false;

	const handleCheckboxChange = (e) => {
		const isChecked = e.target.checked;

		dispatch(setPreviouslyExported(isChecked)); // Toggle the checkbox state
		if (isChecked) {
			setSearchParam((searchParams) => {
				searchParams.set("previousExported", true);
				return searchParams;
			});
		} else {
			setSearchParam((searchParams) => {
				searchParams.delete("previousExported");
				return searchParams;
			});
		}
	};

	const handleButtonClick = () => {
		handleMouseOver("isPreviouslyExported");
	};

	return (
		<>
			<div className="isVerified flex justify-start">
				<div className="relative w-full">
					<button
						onClick={handleButtonClick}
						className="flex items-center w-full justify-between bg-white"
					>
						<span className={`text-[#3D3D3D] flex items-center`}>
							{/* {hoverStates.isPreviouslyExported ? <RxCross2 className='mr-1 text-lg' /> : <BiPlus className='mr-1 text-lg' />} */}
							<input
								id="checkid"
								type="checkbox"
								className="rounded-md border-[0.6px] border-[#D9D9D9] mr-2"
								checked={previousExportedArray}
								onChange={handleCheckboxChange}
							/>
							Only Previously Exported
						</span>
						<InfoIconTooltip
							infoContent={
								"Show influencers that have been previously exported to CSV, JSON or API."
							}
						/>
					</button>
					{/* <ul
                        className={`w-full z-50 px-2 py-1 pl-0 bg-white rounded-md ${hoverStates.isPreviouslyExported ? "block" : "hidden"}`}
                    >
                        <div className="flex items-center gap-2 w-full">
                            <input
                                id="checkid"
                                type="checkbox"
                                className="rounded-md border-[0.6px] border-[#D9D9D9]"
                                checked={selected_PreviouslyExported}
                                onChange={handleCheckboxChange}
                            />
                            <label>Show only previously exported</label>
                        </div>
                    </ul> */}
				</div>
			</div>
		</>
	);
};

export default PreviouslyExportedFilter;
