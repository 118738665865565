// creditHistoryReducer.js
import {
	SET_CREDIT_HISTORY,
	SET_AVAILABLE_POINTS,
	SET_LOADING,
	SET_ERROR,
	RESET_CREDIT_HISTORY,
} from "../ActionTypes";

const initialState = {
	creditHistory: [],
	availablePoints: 0,
	loading: false,
	error: null,
};

const creditHistoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CREDIT_HISTORY:
			return {
				...state,
				creditHistory: action.payload,
			};
		case SET_AVAILABLE_POINTS:
			return {
				...state,
				availablePoints: action.payload,
			};
		case SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case SET_ERROR:
			return {
				...state,
				error: action.payload,
			};
		case RESET_CREDIT_HISTORY:
			return initialState;
		default:
			return state;
	}
};

export default creditHistoryReducer;
