import { combineReducers } from 'redux';
import ageReducer from './filters/reducers/ageReducer';
import genderReducer from './filters/reducers/genderReducer';
import CreatorLocationReducer from './filters/reducers/CreatorLocationReducer';
import TopicsReducer from './filters/reducers/TopicsReducer';
import KeywordsReducer from './filters/reducers/KeywordsReducer';
import CreatorBrandReducer from './filters/reducers/CreatorBrandReducer';
import NumberOfContentReducer from './filters/reducers/NumberOfContentReducer';
import IsVpReducer from './filters/reducers/IsVpReducer';
import IsVerifiedReducer from './filters/reducers/IsVerifiedReducer';
import ContactDetailReducer from './filters/reducers/ContactDetailReducer';
import RecentPostReducer from './filters/reducers/RecentPostReducer';
import CreatorLanguageReducer from './filters/reducers/CreatorLanguageReducer';
import CreatorLookALikeReducer from './filters/reducers/CreatorLookALikeReducer';
import AudienceLookALikeReducer from './filters/reducers/AudienceLookALikeReducer';
import FollowerGwothReducer from './filters/reducers/FollowerGwothReducer';
import ReelViewsReducer from './filters/reducers/ReelViewsReducer';
import BrandSponcoredReducer from './filters/reducers/BrandSponcoredReducer';
import SponcoredPostReducer from './filters/reducers/SponcoredPostReducer';
import EngRateReducer from './filters/reducers/EngRateReducer';
import AverageLikesReducer from './filters/reducers/AverageLikesReducer';
import AudienceBrandReducer from './filters/reducers/AudienceBrandReducer';
import AudienceInterestReducer from './filters/reducers/AudienceInterestReducer';
import AudienceLanguageReducer from './filters/reducers/AudienceLanguageReducer';
import AudienceLocationReducer from './filters/reducers/AudienceLocationReducer';
import AudienceAgeReducer from './filters/reducers/AudienceAgeReducer';
import AudienceGenderReducer from './filters/reducers/AudienceGenderReducer';
import MentionsReducer from './filters/reducers/MentionsReducer';
import HashtagReducer from './filters/reducers/HashtagReducer';
import AccountTypeReducer from './filters/reducers/AccountTypeReducer';
import BioPhaseReducer from './filters/reducers/BioPhaseReducer';
import CreatorInterestReducer from './filters/reducers/CreatorInterestReducer';
import FollowersReducer from './filters/reducers/FollowersReducer';
import PlatformReducer from './filters/reducers/PlatformReducer';
import AllFIlterOpenReducer from './filters/reducers/AllFIlterOpenReducer';
import CredibleAccountReducer from './filters/reducers/CredibleAccountReducer';
import EthnicityReducer from './filters/reducers/EthnicityReducer';
import ExcludePrivateAccountsReducer from './filters/reducers/ExcludePrivateAccountsReducer';
import PreviousExportedReducer from './filters/reducers/PreviousExportedReducer';
import HasAudienceDataReducer from './filters/reducers/HasAudienceDataReducer';
import ExcludeofficialArtistsReducer from './filters/reducers/ExcludeofficialArtistsReducer';
import EngAndEngRateReducer from './filters/reducers/EngAndEngRateReducer';
import SavesReducer from './filters/reducers/SavesReducer';
import SharesReducer from './filters/reducers/SharesReducer';
import ContactReducer from './filters/reducers/ContactReducer';
import PartnershipReducer from './filters/reducers/PartnershipReducer';
import ReelPlaysReducer from './filters/reducers/ReelPlaysReducer';
import LastPostReducer from './filters/reducers/LastPostReducer';
import AudienceTypeReducer from './filters/reducers/AudienceTypeReducer';
import handleSubmitReducer from './filters/reducers/handleSubmitReducer';
import clearAllReducer from './filters/reducers/clearAllReducer';
import blinkReducer from './filters/reducers/blinkReducer';
import creditHistoryReducer from './filters/reducers/creditHistoryReducer';

const rootReducer = combineReducers({
	follower: FollowersReducer,
	age: ageReducer,
	creatorGender: genderReducer,
	creatorLocation: CreatorLocationReducer,
	keywords: KeywordsReducer,
	topics: TopicsReducer,
	creatorBrand: CreatorBrandReducer,
	numberOfContent: NumberOfContentReducer,
	isVpResigtered: IsVpReducer,
	isVerified: IsVerifiedReducer,
	hasContactDetails: ContactDetailReducer,
	mostRecentPost: RecentPostReducer,
	creatorLanguage: CreatorLanguageReducer,
	creatorInterest: CreatorInterestReducer,
	bioPhrase: BioPhaseReducer,
	accountType: AccountTypeReducer,
	hashtag: HashtagReducer,
	mentions: MentionsReducer,
	audienceGender: AudienceGenderReducer,
	audienceAge: AudienceAgeReducer,
	audienceLocation: AudienceLocationReducer,
	audienceLanguage: AudienceLanguageReducer,
	audienceInterest: AudienceInterestReducer,
	audienceBrand: AudienceBrandReducer,
	averageLikes: AverageLikesReducer,
	engagementRate: EngRateReducer,
	isSponcoredPost: SponcoredPostReducer,
	previousBrandSponcored: BrandSponcoredReducer,
	reelPlays: ReelPlaysReducer,
	reelViews: ReelViewsReducer,
	followerGrowth: FollowerGwothReducer,
	audienceLookALike: AudienceLookALikeReducer,
	creatorLookALike: CreatorLookALikeReducer,
	platform: PlatformReducer,
	allFilterOpen: AllFIlterOpenReducer,
	ethnicity: EthnicityReducer,
	contact: ContactReducer,
	credibleAccount: CredibleAccountReducer,
	previouslyExported: PreviousExportedReducer,
	excludePrivateAccounts: ExcludePrivateAccountsReducer,
	hasAudienceData: HasAudienceDataReducer,
	excludeOfficialArtists: ExcludeofficialArtistsReducer,
	engAndEngrate: EngAndEngRateReducer,
	saves: SavesReducer,
	shares: SharesReducer,
	partnership: PartnershipReducer,
	lastPost: LastPostReducer,
	audienceType: AudienceTypeReducer,
	handleSubmit: handleSubmitReducer,
	clearAll: clearAllReducer,
	blink: blinkReducer,
	creditHistory: creditHistoryReducer,
});

export default rootReducer;