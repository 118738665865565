import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { thunk } from "redux-thunk"; // Middleware for async actions
import storage from "redux-persist/lib/storage"; // Default storage (localStorage/sessionStorage)
import rootReducer from "./rootReducer"; // Your combined rootReducer

// Persist configuration setup
const persistConfig = {
	key: "root", // Key to identify the persisted state
	storage, // Use local storage by default
	whitelist: ["someReducer"], // Optional: specify which reducers you want to persist (if needed)
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Apply middleware (thunk and logger)
const middleware = [thunk];

// Create the Redux store
export const store = createStore(
	persistedReducer,
	applyMiddleware(...middleware) // Apply thunk and any other middleware (e.g., logger)
);

// Create the persistor to persist the store
export const persistor = persistStore(store);
