import { SET_ENGAGEMENT_AND_ENGAGEMENTRATE } from "../ActionTypes";

const initialState = [];

const EngAndEngRateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ENGAGEMENT_AND_ENGAGEMENTRATE:
            const previousValue = state?.value;
            console.log('SET_ENGAGEMENT_AND_ENGAGEMENTRATE', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default EngAndEngRateReducer