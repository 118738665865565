import React, { useState, useRef } from "react";
import {
	Table,
	Avatar,
	Badge,
	Dropdown,
	Button,
	Modal,
	Label,
	Select,
	TextInput,
} from "flowbite-react";
import axiosInstance from "../../interseptor";
import { ToastContainer, toast } from "react-toastify";
import {
	HiPlusSm,
	HiPlus,
	HiEye,
	HiDotsVertical,
	HiChartBar,
	HiMail,
} from "react-icons/hi";
import CreatePlan from "../../components/PlanComponent/CreatePlan";
import CreateNewList from "../../components/profile/CreateNewList";
import { createPortal } from "react-dom";
const ADD_TO_LIST_URL = "/livesearch/addtolist";

const REGEX = /^[a-zA-Z.\- ?]+$/;

const AddToList = ({
	isPlanmodalopen,
	closePlanModal,
	plans,
	getPlans,
	lists,
	curListId,
	handleCurList,
	planId,
	selectedInfluencers,
	getList,
	openNewPlanInput,
	setOpenNewPlanInput,
	clearCheckedInfluencer,
	setSelectedInfluencers,
}) => {
	const newListNameRef = useRef("");
	const newPlanNameRef = useRef("");
	const [type, setType] = useState("influencerList");
	const handleChange = (e) => {
		const id = e.target.id;
		const value = e.target.value;

		if (id == "plan") {
			newPlanNameRef.current.value = value;
		}

		if (id == "list") {
			newListNameRef.current.value = value;
		}
	};

	const submitInfluencer = async () => {
		// console.log("sel in", selectedInfluencers);
		try {
			// let data = new FormData();
			// data.append("logintype", "addtolist");
			// data.append("device_id", "search");
			// data.append("list_id", curListId);
			// data.append("username[]", infName);

			const data = {
				logintype: "addtolist",
				device_id: "search",
				list_id: curListId,
				"username[]": JSON.stringify([...selectedInfluencers]),
			};

			const response = await axiosInstance.post(ADD_TO_LIST_URL, data);
			console.log("response of add inf", response);
			if (response && response?.status == 200) {
				// console.log('1111')
				if (response && response?.data && response?.data?.status == 1) {
					// console.log('22222222')
					console.log({ message: response?.data?.msg, code: 401 });
					if (
						response &&
						response?.data &&
						response?.data?.status_code == 200
					) {
						// console.log('3333333')
						if (response.data.status == 1) {
							// console.log('444444')
							let validated = REGEX.test(response?.data?.msg);
							if (validated) {
								// console.log('55555555555555')
								closeModal();
								getPlans();
								// console.log('ressssssssssssssssssssssssssssss', response)

								toast.success(response?.data?.msg, {
									pauseOnHover: false,
								});

								clearCheckedInfluencer();
								setSelectedInfluencers([]);
							}
						} else if (response.data.status == 0) {
							closeModal();
							toast.error(response?.data?.msg, {
								pauseOnHover: false,
							});
						}

						return false;
					}
					return false;
				} else if (
					response &&
					response?.data &&
					response?.data?.status == 2
				) {
					console.log({ message: response?.data?.msg, code: 401 });
					return false;
				} else if (
					response &&
					response?.data &&
					response?.data?.status == 3
				) {
					console.log({ message: response?.data?.msg, code: 401 });
					return false;
				}
				toast.success(response?.data?.msg, { pauseOnHover: false });
				closeModal();

				// setLists(response.data.list.list_details)

				return response?.data.list;
			}
		} catch (err) {
			console.log(err);
		}
	};
	const closeModal = () => {
		closePlanModal();
		// handleCurList(null)
	};

	console.log({ type });

	return (
		<>
			{isPlanmodalopen &&
				createPortal(
					<div className="bg-gray-700/75 w-full h-[100vh] fixed left-0 top-0 z-40 ">
						<div
							className="fixed left-1/2 top-52 -translate-x-1/2 bg-white border-2 border-gray-500 p-4 w-1/3 rounded-xl shadow-md"
							show={isPlanmodalopen}
							onClose={closePlanModal}
						>
							{type === "influencerList" ? (
								<>
									<h1 className="text-gray-600 dark:text-gray-600 text-lg font-semibold mb-4 text">
										Add Influencer to List
									</h1>
									<div>
										<div className="flex flex-col gap-4">
											{/* <CreatePlan /> */}
											<div className="w-[100%]">
												{/* {newPlanNameRef.current == '' && */}
												{/* <> */}
												<Label>Select Plan</Label>
												<div className="flex items-center w-[100%] gap-4">
													<div className="w-full ">
														<Select
															onChange={(e) =>
																getList(
																	e.target
																		.value,
																)
															}
															id="deliverables"
															required={true}
														>
															<option
																defaultValue="select"
																disabled
																selected
															>
																Select a Plan
															</option>

															{plans &&
																plans.map(
																	(plan) => {
																		return (
																			<option
																				value={
																					plan.planid
																				}
																				key={
																					plan.planid
																				}
																			>
																				{
																					plan.plan_name
																				}
																			</option>
																		);
																	},
																)}
														</Select>
													</div>

													<div
														style={{
															width: "200px",
														}}
													>
														<CreatePlan
															color="dark"
															size="sm"
															getPlans={getPlans}
															className="whitespace-nowrap"
														/>
													</div>
												</div>
											</div>

											<div className="w-[100%] ">
												<Label>Select List</Label>

												<div className="flex items-center w-[100%] gap-4">
													<div className="w-full ">
														<Select
															id="deliverables"
															required={true}
															onClick={(e) =>
																handleCurList(
																	e.target
																		.value,
																)
															}
															disabled={
																lists?.length >
																0
																	? false
																	: true
															}
														>
															<option disabled>
																Select a List
															</option>

															{lists &&
																lists.map(
																	(list) => {
																		return (
																			<option
																				value={
																					list.list_id
																				}
																				key={
																					list.list_id
																				}
																			>
																				{
																					list.list_name
																				}
																			</option>
																		);
																	},
																)}
														</Select>
													</div>

													<div
														style={{
															width: "200px",
														}}
													>
														<button
															onClick={() =>
																setType(
																	"createList",
																)
															}
															// onClick={openModal}
															className="w-[90%] bg-[#323237] border border-[#323237] flex items-center justify-center py-2 rounded-lg text-white hover:opacity-90 -ml-[1px]"
															disabled={
																planId == null
															}
														>
															<HiPlus className="mr-0.5" />
															<span className="whitespace-nowrap">
																Create List
															</span>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="flex mt-4 gap-2">
										<Button
											size="sm"
											onClick={submitInfluencer}
											style={{
												backgroundColor: "#1dbb99",
											}}
											disabled={
												!planId ||
												!curListId ||
												planId?.length == 0 ||
												curListId?.length == 0
											}
										>
											Add
										</Button>
										<Button
											size="sm"
											color="gray"
											onClick={closePlanModal}
										>
											<p>Close</p>
										</Button>
									</div>
								</>
							) : (
								<>
									<CreateNewList
										color="dark"
										size="sm"
										planId={planId}
										className="whitespace-nowrap"
										getList={getList}
										setType={setType}
										toastFn={toast}
									/>
								</>
							)}
						</div>
					</div>,
					document.body,
				)}
			{/* {console.log('add to list called')} */}
			<ToastContainer autoClose={1500} />
		</>
	);
};

export default AddToList;
