import React from "react";
import { useRef, useState, useEffect } from "react";
import constants from "../../constants/constant";
import {
	HiCheck,
	HiCheckCircle,
	HiPencil,
	HiCake,
	HiBadgeCheck,
} from "react-icons/hi";
import { Badge, Avatar } from "flowbite-react";
import * as BSIcon from "react-icons/bs";
import SearchCategorySkeleton from "../skeleton/searchCategory";
import SearchInfluencerSkeleton from "../skeleton/searchInfluencer";
import suggestionListRequest from "../../Hooks/SearchSeg/action";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import instagramImg from "./instagram-icon.png";
import youtubeImg from "./youtube-icon.png";
import { BiArrowToBottom, BiCaretDown, BiCaretUp } from "react-icons/bi";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiCloseFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { toggleHandleSubmit } from "../../redux/filters/reducers/handleSubmitReducer";
import { resetClearAll } from "../../redux/filters/reducers/clearAllReducer";
import {
	setBlink,
	resetBlink,
} from "../../redux/filters/reducers/blinkReducer";
import {
	setCreatorLocationFilter,
	setPlatformFilter,
	setTopicsFilter,
	setFollowerFilter,
	setAgeFilter,
	setGenderFilter,
	setNumberOfContentFilter,
	setKeywordsFilter,
	setCreatorBrandFilter,
	setIsVpResigtered,
	setIsVerifiedFilter,
	setHasContactDetailsFilter,
	setMostRecentPostFilter,
	setCreatorLanguageFilter,
	setCreatorInterestFilter,
	setBioPhraseFilter,
	setMentionsFilter,
	setHashTagFilter,
	setAccountTypeFilter,
	setAudienceLocationFilter,
	setAudienceAgeFilter,
	setAudienceGenderFilter,
	setAudienceLanguageFilter,
	setAudienceBrandFilter,
	setAudienceInterestFilter,
	setAverageLikesFilter,
	setEngRateFilter,
	setSponcoredPostFilter,
	setPreviousBrandSponcorFilter,
	setReelViewFilter,
	setReelPlaysFilter,
	setFolloweGrowthFilter,
	setCreatorLookALikeFilter,
	setAudienceLookALikeFilter,
	setAllFilterOpen,
	setEthnicityFilter,
	setContactFilter,
	setCredibleAccountFilter,
	setPreviouslyExported,
	setExcludePrivateAccount,
	setHasAudienceData,
	setExcludeOfficialArtists,
	setEngAndEngRate,
	setSaves,
	setShares,
	setPartnership,
	setLastPost,
	setAudienceType,
} from "../../redux/filters/actions";

import axiosInstance from "../../interseptor";
import { CiSearch } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import instagram from "../asset/instagram.png";
import tiktok from "../asset/tiktok.png";
import youtube from "../asset/youtube1.png";
import ToggleButtonGroup from "./ToggleButton";

const Button = ({ label, active, onClick }) => {
	return (
		<button
			className={`py-2 px-4 rounded ${
				active ? "bg-green-100 text-black" : "bg-blue-500 text-white"
			}`}
			onClick={onClick}
		>
			{label}
		</button>
	);
};

function SearchComponent({ filtersProp }) {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const platformUrl = searchParams.get("platform");
	console.log(platformUrl, "sdaasdasd");

	const platforms = [
		{ id: 1, label: "Instagram", Activeicon: instagram, icon: <FaInstagram /> },
		{
			id: 2,
			label: "YouTube",
			Activeicon: youtube,
			icon: <FaYoutube />,
		},
		{ id: 3, label: "TikTok", Activeicon: tiktok, icon: <FaTiktok /> },
	];
	// for showing platform - true -false

	// const encodedFilter = encodeURIComponent(JSON.stringify(filtersProp))
	// console.log('checking filter prop', filtersProp)
	// Check if filtersProp is an array and not empty
	const hasValidFilters = Array.isArray(filtersProp) && filtersProp.length > 0;

	// Encode the filter only if it's valid
	const encodedFilter = hasValidFilters
		? encodeURIComponent(JSON.stringify(filtersProp))
		: "";
	// console.log('checknig encode', encodedFilter)
	// console.log('encoded filter',encodedFilter)
	// const encodedFilter = filtersProp ? encodeURIComponent(JSON.stringify(filtersProp)) : '';
	const dispatch = useDispatch();
	const [showPlatform, setShowPlatform] = useState(false);
	const [searchParam, setSearchParam] = useSearchParams();
	const [loadingTopics, setLoadingTopics] = useState(false);
	const [activeButton, setActiveButton] = useState(1);
	const [overlayPosition, setOverlayPosition] = useState(0);
	// for showing platform - true -false - while searching
	const [showSearchSuggestion, setShowSearchSuggestion] = useState(false);
	const [selectedPlatform, setSelectedPlatform] = useState(platforms[0]);
	const [loading, setloading] = useState(false);
	const [searches, setSearches] = useState([]);
	const [searchName, setSearchName] = useState([]);
	const [searchTopic, setSearchTopic] = useState([]);
	const [searchUsername, setSearchUsername] = useState([]);
	const [isProfile, setIsProfile] = useState(false);
	// const [blink, setBlink] = useState(false);

	const blink = useSelector((state) => state.blink);
	const isClearAllClicked = useSelector((state) => state.clearAll);

	const selectedData = useSelector((state) => ({
		followers: state.follower,
		creatorGender: state.follower,
		creatorAge: state.age,
		creatorLocation: state.creatorLocation,
		keywords: state.keywords,
		topicsNew: state.topics,
		creatorBrand: state.creatorBrand,
		numberOfContent: state.numberOfContent,
		isVpResigtered: state.isVpResigtered,
		isVerified: state.isVerified,
		hasContactDetails: state.hasContactDetails,
		mostRecentPost: state.mostRecentPost,
		creatorLanguage: state.creatorLanguage,
		creatorInterest: state.creatorInterest,
		bioPhrase: state.bioPhrase,
		accountType: state.accountType,
		hashtag: state.hashtag,
		mentions: state.mentions,
		audienceGenderRedux: state.audienceGender,
		audienceAge: state.audienceAge,
		audienceLocation: state.audienceLocation,
		audienceLanguage: state.audienceLanguage,
		audienceInterest: state.audienceInterest,
		audienceBrand: state.audienceBrand,
		averageLikes: state.averageLikes,
		engagementRate: state.engagementRate,
		isSponcoredPost: state.isSponcoredPost,
		previousBrandSponcored: state.previousBrandSponcored,
		reelViews: state.reelViews,
		followerGrowth: state.followerGrowth,
		audienceLookALike: state.audienceLookALike,
		creatorLookALike: state.creatorLookALike,
		selectedPlatform: state.platform,
	}));

	const handleButtonClick = (buttonIndex) => {
		const newPosition = buttonIndex === 1 ? 0 : 150;
		setActiveButton(buttonIndex);
		setOverlayPosition(newPosition);
	};

	const objectToQueryString = (obj) => {
		return Object.keys(obj)
			.map(
				(key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
			)
			.join("&");
	};

	const queryStringData = objectToQueryString(selectedData);

	const [suggestion, setSuggestion] = useState({
		status_code: 200,
		status: 1,
		list: {
			path: "https://storage.googleapis.com/viralimage/",
		},
	});
	const [filteredArray, setfilteredArray] = useState([]);
	const [searchInput, setsearchInput] = useState("");
	const [topInfluencers, setTopInfluencers] = useState([
		{
			verified: false,
			social_user_name: "FASHION PHOTOGRAPHER ",
			social_name: "fashionphotography.amd",
			im: "f423ccaf193be19e4de6841683befed7.jpg",
		},
		{
			verified: false,
			social_user_name: "Passion Fashion",
			social_name: "passionfashion_bah",
			im: "4ef51d37ca7e4a83fc20e92b7bb0c376.jpg",
		},
		{
			verified: false,
			social_user_name: "Honey Bunny fashion",
			social_name: "honey_bunny_fashion",
			im: "8e62c727df1a31b71499954e9fa4d644.jpg",
		},
	]);

	const searchSuggestionRef = useRef(null);
	const inputRef = useRef(null);

	const removeFromSearch = (index) => {
		const updatedSearches = [...searches];
		updatedSearches.splice(index, 1);
		setSearches(updatedSearches);

		const updatedSearchNames = [...searchName];
		updatedSearchNames.splice(index, 1);
		setSearchName(updatedSearchNames);
		console.log(updatedSearchNames, "updatedSearchNames");
		dispatch(setTopicsFilter(updatedSearchNames));

		if (updatedSearchNames.length > 0) {
			// searchParams.set("topicsNew", searchName.join(","));
			setSearchParam((searchParams) => {
				searchParams.set("topicsNew", updatedSearchNames.join(","));
				return searchParams;
			});
		} else {
			// searchParams.delete("topicsNew");
			setSearchParam((searchParams) => {
				searchParams.delete("topicsNew");
				return searchParams;
			});
		}
	};

	const handlePlatformChange = (platform) => {
		if (platform) {
			// Clear the search chips and names
			setSearches([]);
			setSearchName([]);
			console.log("Cleared searches and search names");

			// Dispatch actions to clear all filters
			// dispatch(setTopicsFilter([])); // Clear topics filter
			dispatch(setFollowerFilter(null)); // Clear follower filter
			dispatch(setAgeFilter(null)); // Clear age filter
			dispatch(setGenderFilter(null)); // Clear gender filter
			dispatch(setNumberOfContentFilter(null)); // Clear content number filter
			dispatch(setKeywordsFilter(null)); // Clear keywords filter
			dispatch(setCreatorBrandFilter(null)); // Clear creator brand filter
			dispatch(setIsVpResigtered(null)); // Clear VP registered filter
			dispatch(setIsVerifiedFilter(false)); // Clear verified filter
			dispatch(setHasContactDetailsFilter(null)); // Clear contact details filter
			dispatch(setMostRecentPostFilter(null)); // Clear recent post filter
			dispatch(setCreatorLanguageFilter(null)); // Clear language filter
			dispatch(setCreatorInterestFilter(null)); // Clear interest filter
			dispatch(setBioPhraseFilter(null)); // Clear bio phrase filter
			dispatch(setMentionsFilter(null)); // Clear mentions filter
			dispatch(setHashTagFilter(null)); // Clear hashtag filter
			dispatch(setAccountTypeFilter(null)); // Clear account type filter
			dispatch(setAudienceLocationFilter(null)); // Clear audience location filter
			dispatch(setAudienceAgeFilter(null)); // Clear audience age filter
			dispatch(setAudienceGenderFilter(null)); // Clear audience gender filter
			dispatch(setAudienceLanguageFilter(null)); // Clear audience language filter
			dispatch(setAudienceBrandFilter(null)); // Clear audience brand filter
			dispatch(setAudienceInterestFilter(null)); // Clear audience interest filter
			dispatch(setAverageLikesFilter(null)); // Clear average likes filter
			dispatch(setEngRateFilter(null)); // Clear engagement rate filter
			dispatch(setSponcoredPostFilter(null)); // Clear sponsored post filter
			dispatch(setPreviousBrandSponcorFilter(null)); // Clear previous brand sponsor filter
			dispatch(setReelViewFilter(null)); // Clear reel view filter
			dispatch(setReelPlaysFilter(null)); // Clear reel plays filter
			dispatch(setFolloweGrowthFilter(null)); // Clear follower growth filter
			dispatch(setCreatorLookALikeFilter(null)); // Clear creator look-alike filter
			dispatch(setAudienceLookALikeFilter(null)); // Clear audience look-alike filter
			dispatch(setEthnicityFilter(null)); // Clear ethnicity filter
			dispatch(setContactFilter(null)); // Clear contact filter
			dispatch(setCredibleAccountFilter(false)); // Clear credible account filter
			dispatch(setEngAndEngRate(null)); // Clear engagement & engagement rate filter
			dispatch(setSaves(null)); // Clear saves filter
			dispatch(setShares(null)); // Clear shares filter
			dispatch(setPartnership(null)); // Clear partnership filter
			dispatch(setLastPost(null)); // Clear last post filter
			dispatch(setAudienceType(null)); // Clear audience type filte
			dispatch(setPreviouslyExported(false));
			dispatch(setExcludeOfficialArtists(false));
			dispatch(setHasAudienceData(false));
			dispatch(setExcludePrivateAccount(false));

			// Clear the URL search parameters (except for platform and creatorLocation)
			setSearchParam((searchParams) => {
				searchParams = new URLSearchParams(); // Reset all params

				// Set platform and creatorLocation based on the selected platform
				searchParams.set("platform", platform.label);

				if (platform.label === "Instagram") {
					console.log("Dispatching setCreatorLocationFilter for Instagram");
					dispatch(setCreatorLocationFilter(["304716-k-India"]));
					searchParams.set("creatorLocation", "304716-k-India");
				} else if (platform.label === "TikTok") {
					console.log("Dispatching setCreatorLocationFilter for TikTok");
					dispatch(setCreatorLocationFilter(["304751-k-Indonesia"]));
					searchParams.set("creatorLocation", "304751-k-Indonesia");
				} else if (platform.label === "YouTube") {
					console.log("Dispatching setCreatorLocationFilter for YouTube");
					dispatch(setCreatorLocationFilter(["304716-k-India"]));
					searchParams.set("creatorLocation", "304716-k-India");
				}

				return searchParams;
			});

			// Set the selected platform
			setSelectedPlatform(platform);
			console.log("Dispatching setSelectedPlatform:", platform);

			// Dispatch the platform filter action
			dispatch(setPlatformFilter(platform));

			// Toggle platform visibility state
			setShowPlatform(!showPlatform);
		}
	};

	const handleSearch = (e) => {
		const searchValue = e.target.value;
		console.log("checking search value", searchValue);
		const trimmedValue = searchValue.trim(); // Remove leading and trailing spaces
		console.log({ isProfile }, "jlaaaaaaaaknjasd");

		let newValue = trimmedValue;
		// Check if "@" is present in the input value after the first character
		if (trimmedValue.startsWith("@")) {
			// Remove "@" from all positions except the starting position
			newValue = trimmedValue.replace(/@/g, (match, offset) =>
				offset === 0 ? "" : ""
			);
		}
		setsearchInput(isProfile ? `@${newValue}` : newValue);
		if (!isProfile) {
			newValue = trimmedValue.replace(/@/g, (match, offset) =>
				offset === 0 ? "" : ""
			);
			setsearchInput(newValue);
		}

		// If isProfile is true, prepend "@" symbol
		// console.log(trimmedValue, 'trimmedValue')
		getSearchSuggestions(trimmedValue);
	};

	const addToSearch = (searched) => {
		console.log(searched, "searched");
		// handleSearchSuggestion(false)
		if (!searchName.includes(searched.name)) {
			setSearches((searches) => [...searches, searched?.id]);
			setSearchName((searches) => [...searches, searched?.name]);
		}
		setsearchInput("");
	};

	// useEffect(() => {
	//   dispatch(setPlatformFilter(selectedPlatform));
	// }, []);

	useEffect(() => {
		dispatch(setTopicsFilter(searchName));
	}, [dispatch, searchName]);

	// useEffect(() => {
	// 	// If "Clear All" was clicked, reset the "blink" immediately and keep it off
	// 	dispatch(resetBlink());
	// }, [dispatch, isClearAllClicked]);

	// Depend only on isClearAllClicked

	// Effect to handle changes to search parameters (when search params change, blink)
	useEffect(() => {
		// If "Clear All" was not clicked, start pulsing when searchParams change
		dispatch(setBlink());
	}, [dispatch, searchParam]);

	useEffect(() => {
		if (searchInput.length > 1 && searchInput[0] !== "@") {
			getSearchSuggestions(searchInput);
		}
	}, [searchInput]);

	const getSearchSuggestions = async (val) => {
		if (selectedPlatform?.key === "instagram" && val.length > 3) {
			setloading(true);

			try {
				let start = Date.now();

				const response = await suggestionListRequest(val);
				console.log("search res", response.list);
				setSuggestion(response.list);
				setTopInfluencers(response.list.pl);
				let timeTaken = Date.now() - start;

				// console.log("Total time taken getSearchSuggestions: " + timeTaken + " milliseconds");
				setloading(false);

				setSuggestion(response);

				const filterKeys = ["post", "bio", "hashtag", "profile"];

				const filteredArray = Object.entries(response.list)
					.filter(([key, value]) => filterKeys.includes(key) && value === 1)
					.map(([key]) => {
						switch (key) {
							case "post":
								return 4;
							case "bio":
								return 1;
							case "hashtag":
								return 3;
							case "profile":
								return 2;
							default:
								return null;
						}
					});

				setfilteredArray(filteredArray);
			} catch (error) {
				// Handle error
			}
		}
	};

	const selectPlatformChange = (item) => {
		let start = Date.now();

		setSelectedPlatform(item);
		// setShowPlatform(false);

		let timeTaken = Date.now() - start;
		console.log(
			"Total time taken selectPlatformChange: " + timeTaken + " milliseconds"
		);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				inputRef.current &&
				!inputRef.current.contains(event.target) &&
				searchSuggestionRef.current &&
				!searchSuggestionRef.current.contains(event.target)
			) {
				setShowSearchSuggestion(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSearchSuggestion = (val, e) => {
		if (
			searchSuggestionRef.current &&
			searchSuggestionRef.current.contains(e?.target)
		) {
			return;
		}
		setShowSearchSuggestion(val);
	};

	const handleTopicsSearch = () => {
		dispatch(resetBlink());
		console.log(searchName, "searchInpusearchInputt");
		dispatch(setTopicsFilter(searchName));

		if (searchName.length > 0) {
			// searchParams.set("topicsNew", searchName.join(","));
			setSearchParam((searchParams) => {
				searchParams.set("topicsNew", searchName.join(","));
				return searchParams;
			});
		} else {
			// searchParams.delete("topicsNew");
			setSearchParam((searchParams) => {
				searchParams.delete("topicsNew");
				return searchParams;
			});
		}
		dispatch(toggleHandleSubmit());
	};

	const topCategories = constants.catagories;

	// console.log('checking topcategories', topCategories)
	console.log("checking searchUsername", searchUsername);

	const filteredCatagogary = searchTopic?.filter((item) =>
		item.name.toLowerCase().includes(searchInput.toLowerCase())
	);

	const PlatformIcon = BSIcon[selectedPlatform.icon];

	useEffect(() => {
		const fetchData = async (value) => {
			setLoadingTopics(true);

			try {
				const formdata = new FormData();
				formdata.append("logintype", "searchtopic");
				formdata.append("device_id", "seacrh");
				formdata.append("platform", selectedPlatform.label);

				if (value[0] === "@") {
					formdata.append("filter", "lookalike");
					formdata.append("search_string", value.substring(1));
				} else {
					formdata.append("topic", value);
				}

				const response = await axiosInstance.post(
					"livesearch/searchtopic",
					formdata
				);

				setSearchTopic(response?.data?.response?.data);
				setSearchUsername([]);
				setLoadingTopics(false);

				if (searchInput.length === 0) {
					setSearchTopic([]);
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};

		if (searchInput.length > 1) {
			fetchData(searchInput);
		}
	}, [searchInput, selectedPlatform.label]);

	const fetchSimilarUsername = async (value) => {
		try {
			const formdata = new FormData();
			formdata.append("filter", "lookalike");
			formdata.append("logintype", "getfilters");
			formdata.append("device_id", "search");
			formdata.append("platform", selectedPlatform.label.toLocaleLowerCase());
			formdata.append("search_string", value || "");

			const response = await axiosInstance.post(
				"livesearch/getfilters",
				formdata
			);

			console.log("result of similar jhvjhuser", response?.data?.status);
			if (response?.data?.status == 1) {
				console.log("im here");
				setSearchUsername(response?.data?.response?.data?.data);
				setSearchTopic([]);
				setLoadingTopics(false);
				// console.log('lookalike data', response.response.data.data);
			} else {
				// setError(response.msg);
				console.error("Error:", response?.data?.msg);
			}
		} catch (error) {
			// setError('An error occurred while fetching data.');
			console.error("Error:", error);
		} finally {
			// setLoading(false);
		}
	};

	useEffect(() => {
		if (searchInput.length > 1 && searchInput[0] === "@") {
			fetchSimilarUsername(searchInput.substring(1));
		}
	}, [searchInput]);

	function sendDataToParent(val) {
		setIsProfile(val);
		setsearchInput("");
		if (val) {
			setSearchTopic([]);
		} else {
			setSearchUsername([]);
		}
	}

	return (
		<div className="w-full justify-center  mt-5 m-auto">
			<div className="w-full flex overflow-x-auto">
				{platforms.map((platform) => (
					<button
						key={platform.id}
						className={`flex-shrink-0 inline-flex justify-center font items-center h-14 w-[160px] py-3 px-4 text-base font-medium text-center
            ${
							(platformUrl === "Instagram"
								? 1
								: platformUrl === "YouTube"
								? 2
								: platformUrl === "TikTok"
								? 3
								: 0) === platform.id && "rounded-bl-[8px]"
						}
            ${platform.id === 1 && "rounded-tl-[8px]"}
            ${platform.id === 3 && "rounded-tr-[8px]"}
            ${
							(platformUrl === "Instagram"
								? 1
								: platformUrl === "YouTube"
								? 2
								: platformUrl === "TikTok"
								? 3
								: 0) === platform.id
								? "bg-black text-white slide-animation"
								: "bg-white"
						}
            ${
							(platformUrl === "Instagram"
								? 1
								: platformUrl === "YouTube"
								? 2
								: platformUrl === "TikTok"
								? 3
								: 0) === platform.id && "rounded-br-[8px]"
						}
            focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600 relative`}
						onClick={() => handlePlatformChange(platform)}
						type="button"
						style={{
							transition:
								"background-color 0.3s, color 0.3s, border-radius 0.3s",
						}}
					>
						<div className="flex gap-1 items-center justify-center">
							{/* Use appropriate icon based on the selected platform */}
							{(platformUrl === "Instagram"
								? 1
								: platformUrl === "YouTube"
								? 2
								: platformUrl === "TikTok"
								? 3
								: 0) === platform.id ? (
								<img
									src={platform.Activeicon}
									className="w-[20px]"
									alt="Platform Icon"
								/>
							) : (
								platform.icon
							)}
							{platform.label}
						</div>
					</button>
				))}
			</div>

			<div className="shadow-md w-full bg-white px-4 pb-2 pt-0.5 rounded-tr-[8px] ">
				<div className="mb-6 h-14">
					<ToggleButtonGroup childToParent={sendDataToParent} />
				</div>

				<div className="relative w-full flex-nowrap border-[0.6px] rounded-[4px] border-[#D9D9D9]">
					<span className="w-full ml-12   rounded-r-lg flex flex-wrap items-center border-none border border-gray-300 p-0 gap-[5px]">
						{searchName?.map((name, index) => (
							<button
								key={index}
								className="my-1 mx-1 px-2 py-1 flex whitespace-nowrap items-center capitalize bg-blue-100 text-blue-800 rounded-lg text-sm"
								onClick={() => removeFromSearch(index)}
							>
								{name}
								<RiCloseFill />
							</button>
						))}

						<div className="absolute w-[20px] left-[25px] top-[39%]">
							{" "}
							<IoIosSearch />
						</div>
						<input
							ref={inputRef}
							onChange={(e) => handleSearch(e)}
							onFocus={() => handleSearchSuggestion(true)}
							type="search"
							className={`p-3  ${
								searchName.length > 0 ? "w-fit min-w-[100px]" : "w-[90%]"
							} border-none outline-none searchinput focus:none ring-0 ring-transparent h-14 `}
							placeholder={`${
								searchName.length > 0
									? "Add More"
									: "Search for your favorite topic to get started or ‘username’ to check any profile analytics"
							}`}
							required
							autoComplete="false"
							value={!isProfile ? searchInput.replace("@", "") : searchInput} // Render value without "@" if isProfile is true
						/>

						<div className="h-full" onClick={handleTopicsSearch}>
							<button
								className={`px-4 py-4 z-50 rounded-r-sm text-white absolute top-0 right-0 ${
									blink ? "bg-[#6d5eac]" : "bg-[#d7cff7]"
								}`}
							>
								Search
							</button>
							{/* </Link> */}
						</div>
					</span>

					<div
						className={`absolute bg-white z-10 h-auto rounded-lg w-full ${
							showSearchSuggestion ? "" : "hidden"
						}`}
						style={constants.searchInputStyle}
						ref={searchSuggestionRef}
					>
						{loadingTopics ? (
							// Render loading indicator while data is being fetched
							<div className="text-lg font-semibold pb-5 p-5">Loading...</div>
						) : // Render your actual component once data is available
						filteredCatagogary?.length > 0 ? (
							<div className="pl-5 pt-5">
								<div className="text-gray500 text-lg font-normal">Topic</div>
								<div className="flex flex-wrap">
									{filteredCatagogary?.map((category) => (
										<button
											key={category?.id}
											onClick={() => addToSearch(category)}
										>
											<Badge className="my-2 mr-2 p-1 cursor-pointer bg-indigo-100 text-indigo capitalize">
												{category?.name}
											</Badge>
										</button>
									))}
								</div>
							</div>
						) : searchUsername?.length > 0 ? (
							<div className="pl-5 pt-5">
								<div className="text-gray-500 text-lg font-normal">
									Similer Username
								</div>
								<div className="flex flex-wrap">
									{searchUsername
										?.filter(
											(category) => category?.username || category?.custom_name
										) // Filter out items without a username
										.slice(0, 20) // Limit the display to the first 20 items
										.map((category) => (
											<Link
												to={`/profile/?id=${
													selectedPlatform.label.toLowerCase() === "youtube"
														? category?.user_id
														: category?.username
												}&platformUrl=${encodeURIComponent(
													selectedPlatform.label
												)}`}
											>
												<button key={category?.user_id}>
													<Badge className="my-2 rounded-full mr-2 p-1 pr-3 cursor-pointer bg-indigo-100 text-indigo capitalize">
														<div className="flex items-center gap-1">
															{category?.picture && (
																<div className="w-8 h-8 rounded-full overflow-hidden">
																	<img
																		className="w-full h-full object-cover"
																		src={category?.picture}
																		alt="img"
																	/>
																</div>
															)}

															<div className="flex items-center gap-1">
																<div className="flex flex-col justify-start border items-start">
																	<div className="flex gap-1">
																		<p>
																			{category?.username ||
																				category?.custom_name}
																		</p>
																		<div>
																			{category?.is_verified && (
																				<HiBadgeCheck
																					className="text-primary-500"
																					size={16}
																				/>
																			)}
																		</div>
																	</div>
																	<p className="text-gray-600 text-xs font-thin">
																		{Intl.NumberFormat("en-US", {
																			notation: "compact",
																			maximumFractionDigits: 1,
																		}).format(category?.followers)}
																		&nbsp; Followers
																	</p>
																</div>
															</div>
														</div>
													</Badge>
												</button>
											</Link>
										))}
								</div>
							</div>
						) : (
							searchInput !== "" &&
							searchInput.length > 1 &&
							searchInput.includes("@") && (
								<>
									<Link
										to={`/profile/?id=${searchInput}&platformUrl=${encodeURIComponent(
											selectedPlatform.label
										)}`}
									>
										<button>
											<Badge className="my-2 rounded-full mr-2 p-1 pr-3 cursor-pointer bg-indigo-100 text-indigo capitalize">
												<div className="flex items-center gap-1">
													{
														<div className="w-8 h-8 rounded-full overflow-hidden">
															<img
																className="w-full h-full object-cover"
																src={"/avatar.png"}
																alt="img"
															/>
														</div>
													}

													<div className="flex items-center gap-1">
														<div className="flex flex-col justify-start border items-start">
															<div className="flex gap-1">
																<p>{searchInput}</p>
															</div>
															<p className="text-gray-600 text-xs font-thin">
																Not Known &nbsp; Followers
															</p>
														</div>
													</div>
												</div>
											</Badge>
										</button>
									</Link>
								</>
							)
						)}

						{/* <div className="searchkeyword pl-5" >
              {
                !loading && filteredArray && filteredArray.map((searchlocations) => {
                  let keyName;
                  switch (searchlocations) {
                    case 1:
                      keyName = "Bio";
                      break;
                    case 2:
                      keyName = "Profile";
                      break;
                    case 3:
                      keyName = "Hashtag";
                      break;
                    case 4:
                      keyName = "Post";
                      break;
                    default:
                      keyName = "";
                  }

                  return (
                    <Link key={searchlocations} to={`/result/?s=` + searchInput + `&platform=` + selectedPlatform.id + `&p=` + searchlocations}
                      onClick={() => handleSearchSuggestion(false)}
                    >
                      <div className="flex my-3 w-full cursor-pointer">
                        <div className="flex w-[100%]">
                          <div className="px-2 text-gray600 text-text-sm font-semibold">Search {searchInput} in {selectedPlatform.key} {keyName}</div>
                        </div>
                      </div>
                    </Link>
                  )
                })
              }
              {loading && <div className="w-full"><SearchInfluencerSkeleton total={3} /></div>}
            </div> */}

						{/* <div>
              {topInfluencers && topInfluencers?.length > 0 && (
                <div className="text-gray500 text-lg font-normal">Top Influencer</div>
              )}
              <div>
                {!loading &&
                  topInfluencers && topInfluencers?.map((influencer) => (
                    <Link key={influencer?.im} to={`/profile/?id=${influencer?.social_name}`} className="flex my-3 w-full cursor-pointer">
                      <Avatar size="xs" img={`${suggestion.list.path}${influencer.im}`} rounded={true} />
                      <div className="flex w-[88%]">
                        <div className="px-2 text-gray600 text-text-sm font-semibold capitalize">
                          {influencer?.social_user_name}
                        </div>
                        <div className="text-gray600 text-text-sm">
                          {influencer?.tagName}
                        </div>
                      </div>
                      <div className="mt-[5px] mx-2 min-w-[16px]">
                        {influencer?.isVerfied ? <HiCheckCircle fill="#1C64F2" /> : ' '}
                      </div>
                    </Link>
                  ))}
                {loading && (
                  <div className="w-full">
                    <SearchInfluencerSkeleton total={4} />
                  </div>
                )}
              </div>
            </div> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SearchComponent;
