import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import axiosInstance from "../../interseptor";
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { ToastContainer, toast } from "react-toastify";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { decryptData, updateData } from "./Encryption";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
	setCreatorLocationFilter,
	setEngRateFilter,
	setMostRecentPostFilter,
	setPlatformFilter,
} from "../../redux/filters/actions";
const LOGIN_URL = "livesearch/login";
// import { GrStar } from 'react-icons/gr'
// import { AiFillAndroid, AiFillApple } from 'react-icons/ai';

function Login({ setToken }) {
	const navigate = useNavigate();
	const [loggedIn, setLoggedIn] = useState(false);
	const [updated, setUpdated] = useState(false);
	const token = localStorage.getItem("token_data");
	const [otp, setOtp] = useState("");
	const [errorCode, setErrorCode] = useState(null);
	const [seconds, setSeconds] = useState(60);
	const [type, setType] = useState(1);
	const [rememberChecked, setRememberChecked] = useState(false);
	const domainName = window.location.hostname;
	const dispatch = useDispatch();
	const selectedData = useSelector((state) => ({
		creatorLocation: state.creatorLocation,
		mostRecentPost: state.mostRecentPost,
		engagementRate: state.engagementRate,
		selectedPlatform: state.platform,
	}));

	const { creatorLocation, mostRecentPost, engagementRate, selectedPlatform } =
		selectedData;

	async function loginUser({ email, password }) {
		// console.log('loginUser')
		try {
			const formData = new FormData();
			formData.append("email", email);
			formData.append("password", password);
			formData.append("type", type);
			formData.append("otp", otp);
			formData.append("logintype", "verify_otp");
			formData.append("device_id", "searchabhi");

			// console.log('login url', LOGIN_URL)

			const response = await axiosInstance.post(LOGIN_URL, formData);

			// this.props.history.push("/home");
			// localStorage.setItem("user",JSON.stringify(response?.data?.data));
			// console.log('-loginjs-loginusr--------------------')
			// console.log(response)
			// console.log(response?.data?.token_data?.token)
			// console.log('-loginjs-loginusr--------------------')
			return response?.data;
		} catch (err) {
			console.log(err);
		}
	}

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = async (e, type) => {
		e.preventDefault();

		setType(type);
		setOtp("");
		setSeconds(60);
		var formData = new FormData();
		formData.append("logintype", "login_send_otp");
		formData.append("device_id", "search");
		formData.append("email", email);
		formData.append("password", password);
		formData.append("type", type);

		const response = await axiosInstance.post(
			"/livesearch/login_send_otp",
			formData
		);
		if (response.data.msg) {
			if (response.data.status == 2 || response.data.status === 3) {
				toast.error(response.data.msg);
			} else {
				toast.success(response.data.msg);
			}
		}

		setSeconds(60);

		if (response.data.status == 1) {
			setLoggedIn(true);
		}
	};

	// Function to add a unique email to localStorage
	function addUniqueEmail(email) {
		// Function to retrieve cookie value by name
		function getCookie(name) {
			const cookieArr = document.cookie.split(";");
			for (let i = 0; i < cookieArr.length; i++) {
				const cookiePair = cookieArr[i].split("=");
				if (name === cookiePair[0].trim()) {
					return decodeURIComponent(cookiePair[1]);
				}
			}
			return null;
		}

		// Retrieve existing emails from cookie
		const existingEmails = getCookie("emails")
			? JSON.parse(getCookie("emails"))
			: [];
		// Add the email to the array of emails
		existingEmails.push(email);
		// Set the cookie with the updated emails
		var expirationDate;
		if (rememberChecked) {
			expirationDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000); // 7 days expiration
		} else {
			expirationDate = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000); // 1 days expiration
			// expirationDate = new Date(Date.now() + 10 * 1000); // 1 days expiration
		}
		document.cookie = `emails=${JSON.stringify(
			existingEmails
		)}; expires=${expirationDate.toUTCString()}; path=/`;
	}

	const verifyHandler = async () => {
		const responsedata = await loginUser({ email, password });
		// console.log('-loginjs-(e)=>handleSubmit(e,1)--------------------')
		// console.log("res", email, email === "sandeep.kumar@viralpitch.co");
		// console.log('-loginjs-(e)=>handleSubmit(e,1)--------------------')
		if (responsedata?.code == 401 || responsedata?.msg) {
			toast.error(responsedata?.msg);

			setErrorCode(responsedata.code);
		} else {
			if (!Object.keys(responsedata).includes("rights_navigation")) {
				localStorage.setItem("token", responsedata?.token_data);
				setToken(responsedata);
				addUniqueEmail(email);
				setUpdated(!updated);
				setErrorCode("");
				// if (email === "sandeep.kumar@viralpitch.co") {

				//   updateData("rights_navigation", '73,74,75,76,77,78,79,80,81')
				//   updateData("rights_action", JSON.stringify(
				//     {
				//       "73": "119,120",
				//       "74": "121,122,123,124,125",
				//       "75": "126,128,129",
				//       "76": "130,131,132,133",
				//       "77": "134,135"
				//     }))
				// }
				toast.warn("You dont have access to website. Please contact to admin");
			} else {
				setErrorCode("");
				localStorage.setItem("token", responsedata?.token_data);
				if (responsedata?.rights_navigation.length > 0) {
					updateData("rights_navigation", responsedata?.rights_navigation);
					updateData(
						"rights_action",
						JSON.stringify(responsedata?.rights_action)
					);
					addUniqueEmail(email);
					setToken(responsedata);
					setLoggedIn(true);
					setUpdated(!updated);

					console.log("disptach successfull");

					toast.success("Login Successful");
				} else {
					toast.warn(
						"You dont have access to website. Please contact to admin"
					);
				}
			}
		}
	};

	useEffect(() => {
		let timer;
		if (loggedIn && seconds > 0) {
			dispatch(setPlatformFilter("Instagram"));
			dispatch(setCreatorLocationFilter(["304716-k-India"]));
			dispatch(setMostRecentPostFilter([1]));
			dispatch(setEngRateFilter([0.01]));
			timer = setInterval(() => {
				setSeconds((prevSeconds) => prevSeconds - 1);
			}, 1000);
		} else if (seconds === 0) {
			// setLoggedIn(false); // Automatically close modal when timer reaches 0
			// You can add additional actions when the timer reaches 0
		}

		return () => clearInterval(timer); // Clear interval when component unmounts or when loggedIn changes
	}, [loggedIn, seconds, dispatch]);

	const queryString = `?creatorLocation=304716-k-India&mostRecentPost=1&eng_rate=0.01&platform=Instagram`;

	return (
		<>
			{token ? (
				<Navigate to={`/home${queryString}`}></Navigate>
			) : (
				<>
					<div className="w-full bg-white rounded-lg shadow dark:border md:mt-5 max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
						<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
							<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
								Sign in to your account
							</h1>
							<form
								className="flex flex-col gap-4"
								action=""
								onSubmit={(e) => handleSubmit(e, 1)}
							>
								<div>
									<div className="mb-2 block">
										<Label htmlFor="email" value="Your email" />
									</div>
									<TextInput
										id="email"
										type="email"
										placeholder="name@yourdomain.com"
										required={true}
										value={email}
										autoComplete="username"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								<div>
									<div className="mb-2 block">
										<Label htmlFor="password" value="Your password" />
									</div>
									<TextInput
										id="password"
										type="password"
										placeholder="******"
										required={true}
										value={password}
										autoComplete="current-password"
										onChange={(e) => setPassword(e.target.value)}
									/>
									{errorCode == "401" && (
										<Label className="text-red-500">
											Incorrect email or password
										</Label>
									)}
								</div>
								<div className="flex items-center justify-between">
									<Label htmlFor="remember">
										<Checkbox
											id="remember"
											value={rememberChecked}
											onChange={() => setRememberChecked(!rememberChecked)}
											className="mr-2"
										/>{" "}
										Remember me
									</Label>
								</div>
								{/*  <Link
                    to="/forget"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </Link>
                </div> */}

								<Button type="submit">Submit</Button>
								{/* <Button >Login With Google</Button> */}
								 <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don&apos;t have an account yet?{" "}
                  <Link
                    to="/signup"
                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Sign up
                  </Link>
                </p> 
							</form>

							{/* <div className=''>
                <marquee className='w-full'>
                  <div className='flex items-center'>
                    <h1 className='text-gray-500 text-sm flex items-center'>
                      Unlock a Seamless Experience – Download Our App Now! &nbsp; &nbsp; &nbsp; <GrStar /> &nbsp; &nbsp; &nbsp; Unlock a Seamless Experience – Download Our App Now! &nbsp; &nbsp; &nbsp; <GrStar /> &nbsp; &nbsp; &nbsp;
                    </h1>
                    <h1 className='text-gray-500 text-sm flex items-center'>
                      Unlock a Seamless Experience – Download Our App Now! &nbsp; &nbsp; &nbsp; <GrStar /> &nbsp; &nbsp; &nbsp; Unlock a Seamless Experience – Download Our App Now!
                    </h1>
                  </div>
                </marquee>
                <div className='w-full flex justify-between'>
                  <Link to="https://play.google.com/store/apps/details?id=com.viralpitchnew" target='_blank'>
                    <span className='text-primary-600 text-sm underline flex items-center'><AiFillAndroid className='mr-1 text-black' /> Download for Android</span>
                  </Link>
                  <Link to="https://apps.apple.com/app/viral-pitch/id1644679898" target='_blank'>
                    <span className='text-primary-600 text-sm underline flex items-center'><AiFillApple className='mr-1 text-black' /> Download for iOS</span>
                  </Link>
                </div>
              </div> */}
						</div>
					</div>
					{domainName === "coke.viralpitch.co" && (
						<div className="flex items-center gap-2">
							<div className="w-[50px] mt-2">
								<img
									className="w-[100%] h-[100%]"
									src="https://viralpitch.co/public/groupm-1.png"
									alt="logo"
								/>
							</div>
							<p className="text-sm flex items-center gap-2 mt-2">
								Powered by
								<img
									className="w-50 h-4"
									src="https://viralpitch.co/demoadmin/Infls/assets/img/logo.png"
									alt="logo"
								/>
								™
							</p>
						</div>
					)}

					{domainName === "scarlett.viralpitch.co" && (
						<div className="flex items-center gap-2">
							<div className="w-[50px] mt-2">
								<img
									className="w-[100%] h-[100%]"
									src="https://viralpitch.co/public/groupm-1.png"
									alt="logo"
								/>
							</div>
							<p className="text-sm flex items-center gap-2 mt-2">
								Powered by
								<img
									className="w-50 h-4"
									src="https://viralpitch.co/demoadmin/Infls/assets/img/logo.png"
									alt="logo"
								/>
								™
							</p>
						</div>
					)}

					{domainName === "skii.viralpitch.co" && (
						<div className="flex items-center gap-2">
							<div className="w-[50px] mt-2">
								<img
									className="w-[100%] h-[100%]"
									src="https://viralpitch.co/public/groupm-1.png"
									alt="logo"
								/>
							</div>
							<p className="text-sm flex items-center gap-2 mt-2">
								Powered by
								<img
									className="w-50 h-4"
									src="https://viralpitch.co/demoadmin/Infls/assets/img/logo.png"
									alt="logo"
								/>
								™
							</p>
						</div>
					)}

					{domainName === "airtel.viralpitch.co" && (
						<div className="flex items-center gap-2">
							<div className="w-[50px] mt-2">
								<img
									className="w-[100%] h-[100%]"
									src="https://viralpitch.co/public/groupm-1.png"
									alt="logo"
								/>
							</div>
							<p className="text-sm flex items-center gap-2 mt-2">
								Powered by
								<img
									className="w-50 h-4"
									src="https://viralpitch.co/demoadmin/Infls/assets/img/logo.png"
									alt="logo"
								/>
								™
							</p>
						</div>
					)}

					<Modal
						className="p-0"
						show={loggedIn}
						onClose={() => {
							setLoggedIn(false);
						}}
					>
						<Modal.Header>OTP Verification</Modal.Header>
						<Modal.Body className="flex flex-col justify-center   ">
							<OtpInput
								value={otp}
								onChange={setOtp}
								numInputs={6}
								containerStyle={{ display: "flex", justifyContent: "center" }}
								renderSeparator={<span className="mr-2"></span>}
								inputStyle={{ width: "50px", borderRadius: "20px" }}
								renderInput={(props) => (
									<input className="w-[50px]" {...props} />
								)}
							/>
							{seconds === 0 ? (
								<>
									<p
										onClick={(e) => {
											handleSubmit(e, 2);
										}}
										className="underline cursor-pointer mt-5"
									>
										{" "}
										Didn't Recieved Code?{" "}
									</p>
								</>
							) : (
								<>
									{" "}
									<p className="underline cursor-pointer mt-5">
										{seconds} seconds left
									</p>
								</>
							)}
						</Modal.Body>
						<Modal.Footer className="flex justify-center">
							<button
								className=" flex items-center justify-center rounded-md text-md bg-[#686FFD] text-white font-[600] py-3 px-6 mr-3"
								onClick={verifyHandler}
							>
								Verify
							</button>
						</Modal.Footer>
					</Modal>
				</>
			)}

			<ToastContainer />
		</>
	);
}

Login.propTypes = {
	setToken: PropTypes.func.isRequired,
};

export default Login;
