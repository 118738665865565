import React, { useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Select } from "antd";
import "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFolloweGrowthFilter } from "../../redux/filters/actions";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const FollowersGrowth = ({hoverStates, handleMouseOver, sendToSearchResultFilter,}) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const followerGrowthUrl = searchParams.get("followerGrowth");
  const followerGrowthArray = followerGrowthUrl ? followerGrowthUrl.split(",") : [];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const selected_follower_growth = useSelector((state) => state.followerGrowth);
  const [rangeValues, setRangeValues] = useState(
    selected_follower_growth || []
  );
  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);

  const handleFromChange = (value) => {
    if (value === "Clear") {
      setRangeValues([]);
      dispatch(setFolloweGrowthFilter([]));
      setSearchParam(searchParams => {
        searchParams.delete("followerGrowth")
        return searchParams;
    });
    } else {
      setRangeValues([value]);
      dispatch(setFolloweGrowthFilter([value]));

      if([value].length > 0){
        setSearchParam(searchParams => {
          searchParams.set("followerGrowth", [value])
          return searchParams;
        });
        }
      }
    setFromDropdownOpen(false);
  };

  const fromOptions = [
    "Clear",
    "1 Month",
    "2 Month",
    "3 Month",
    "4 Month",
    "5 Month",
    "6 Month",
  ];

  return (
		<>
			<div ref={buttonRef} className="FollowerGrowth flex justify-start">
				<div className="relative w-full">
					<button
						onClick={() => setIsDropdownOpen(!isDropdownOpen)}
						className="flex filterTextColor items-center bg-white"
					>
						<span
							className={`${
								isDropdownOpen ? "font-semibold" : ""
							} flex items-center`}
						>
							{/* {isDropdownOpen ? <RxCross2 className='mr-1 text-lg' /> : <BiPlus className='mr-1 text-lg' />} */}
							Growing
							<span
								className={`filterTextColor ${
									isDropdownOpen ? "" : ""
								} flex items-center`}
							>
								{isDropdownOpen ? (
									<RxCross2 className="ml-2 text-lg" />
								) : (
									<IoChevronDownSharp className="ml-2 text-lg" />
								)}
							</span>
						</span>
					</button>
					<ul
						ref={dropdownRef}
						className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${
							isDropdownOpen ? "block" : "hidden"
						}`}
					>
						<div className="flex flex-col gap-2 w-full">
							<div className="flex items-center gap-2 whitespace-nowrap">
								<MdCampaign className="text-2xl" /> Follower Growth{" "}
								<InfoIconTooltip
									infoContent={`Identify influencers by number of their followers.`}
								/>
							</div>
							<div className="relative">
								<input
									type="text"
									className="w-full py3 rounded-md border-[0.6px] border-[#D9D9D9]"
									placeholder="In last"
									value={followerGrowthArray}
									onClick={() => setFromDropdownOpen(!fromDropdownOpen)}
									readOnly
								/>
								{fromDropdownOpen && (
									<div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
										{fromOptions.map((option) => (
											<div
												key={option}
												className="p-2 cursor-pointer hover:bg-gray-200"
												onClick={() => handleFromChange(option)}
											>
												{option}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					</ul>
				</div>
			</div>
		</>
	);
};

export default FollowersGrowth;
