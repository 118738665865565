import { IconButton, Tooltip } from "@mui/material";
import { color } from "highcharts";
import React from "react";
import { HiInformationCircle } from "react-icons/hi";
import { IoInformationCircleOutline } from "react-icons/io5";
const InfoIconTooltip = ({ infoContent, bgColor }) => {
  return (
    <Tooltip title={infoContent} className="text-inherit">
      <IconButton style={{ padding: "0px", color: "inherit" }}>
        <IoInformationCircleOutline
          className={`${
            bgColor ? `${bgColor} text-xl` : " text-xl"
          } text-inherit`}
        />
      </IconButton>
    </Tooltip>
  );
};

export default InfoIconTooltip;
