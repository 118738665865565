import { SET_AVERAGE_LIKES_FILTER } from "../ActionTypes";

const initialState = [];

const AverageLikesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AVERAGE_LIKES_FILTER:
            const previousValue = state?.value;
            console.log('state in avg. likes', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default AverageLikesReducer