import { SET_PREVIOUS_BRAND_SPONCOR_FILTER } from "../ActionTypes";

const initialState = [];

const BrandSponcoredReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PREVIOUS_BRAND_SPONCOR_FILTER:
            const previousValue = state?.value;
            console.log('state in PREVIOUS SPONCORED SPONCOR', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default BrandSponcoredReducer