export const SET_FOLLOWER_FILTER = 'SET_FOLLOWER_FILTER';
export const SET_AGE_FILTER = 'SET_AGE_FILTER';
export const SET_GENDER_FILTER = 'SET_GENDER_FILTER';
export const SET_CREATOR_LOCATION_FILTER = 'SET_CREATOR_LOCATION_FILTER'
export const SET_TOPICS_FILTER = 'SET_TOPICS_FILTER'
export const SET_NUMBEROFCONTENT_FILTER = 'SET_NUMBEROFCONTENT_FILTER'
export const SET_KEYWORDS_FILTER = 'SET_KEYWORDS_FILTER'
export const SET_CREATOR_BRAND_FILTER = 'SET_CREATOR_BRAND_FILTER'
export const SET_IS_VP_RESIGTERED_FILTER = 'SET_IS_VP_RESIGTERED_FILTER'
export const SET_IS_VERIFIED_FILTER = 'SET_IS_VERIFIED_FILTER'
export const SET_HAS_CONTACT_DETAILS_FILTER = 'SET_HAS_CONTACT_DETAILS_FILTER'
export const SET_MOST_RECENT_POST_FILTER = 'SET_MOST_RECENT_POST_FILTER'
export const SET_CREATOR_LANGUAGE_FILTER = 'SET_CREATOR_LANGUAGE_FILTER'
export const SET_CREATOR_INTEREST_FILTER = 'SET_CREATOR_INTEREST_FILTER'
export const SET_BIO_PHASE_FILTER = 'SET_BIO_PHASE_FILTER'
export const SET_MENTIONS_FILTER = 'SET_MENTIONS_FILTER'
export const SET_HASHTAG_FILTER = 'SET_HASHTAG_FILTER'
export const SET_ACCOUNT_TYPE_FILTER = 'SET_ACCOUNT_TYPE_FILTER'
export const SET_AUDIENCE_LOCATION_FILTER = 'SET_AUDIENCE_LOCATION_FILTER'
export const SET_AUDIENCE_AGE_FILTER = 'SET_AUDIENCE_AGE_FILTER'
export const SET_AUDIENCE_GENDER_FILTER = 'SET_AUDIENCE_GENDER_FILTER'
export const SET_AUDIENCE_LANGUAGE_FILTER = 'SET_AUDIENCE_LANGUAGE_FILTER'
export const SET_AUDIENCE_BRAND_FILTER = 'SET_AUDIENCE_BRAND_FILTER'
export const SET_AUDIENCE_INTEREST_FILTER = 'SET_AUDIENCE_INTEREST_FILTER'
export const SET_AVERAGE_LIKES_FILTER = 'SET_AVERAGE_LIKES_FILTER'
export const SET_ENG_RATE_FILTER = 'SET_ENG_RATE_FILTER'
export const SET_SPONCORED_POST_FILTER = 'SET_SPONCORED_POST_FILTER'
export const SET_PREVIOUS_BRAND_SPONCOR_FILTER = 'SET_PREVIOUS_BRAND_SPONCOR_FILTER'
export const SET_REEL_VIEW_FILTER = 'SET_REEL_VIEW_FILTER'
export const SET_REEL_PLAYS_FILTER = 'SET_REEL_PLAYS_FILTER'
export const SET_FOLLOWER_GROWTH_FILTER = 'SET_FOLLOWER_GROWTH_FILTER'
export const SET_CREATOR_LOOKALIKE_FILTER = 'SET_CREATOR_LOOKALIKE_FILTER'
export const SET_AUDIENCE_LOOKALIKE_FILTER = 'SET_AUDIENCE_LOOKALIKE_FILTER'
export const SET_PLATFORM = 'SET_PLATFORM'
export const SET_ALL_FILTER_OPEN = 'SET_ALL_FILTER_OPEN'
export const SET_ETHNICITY_FILTER = 'SET_ETHNICITY_FILTER'
export const SET_CREDIBLE_ACCOUNT_FILTER = 'SET_CREDIBLE_ACCOUNT_FILTER'
export const SET_PREVIOUS_EXPORTED_FILTER = 'SET_PREVIOUS_EXPORTED_FILTER'
export const SET_EXCLUDE_PRIVATE_ACCOUNTES = 'SET_EXCLUDE_PRIVATE_ACCOUNTES'
export const SET_HAS_AUDIENCE_DATA = 'SET_HAS_AUDIENCE_DATA'
export const SET_EXCLUDE_OFFICIAL_ARTISTS = 'SET_EXCLUDE_OFFICIAL_ARTISTS'
export const SET_ENGAGEMENT_AND_ENGAGEMENTRATE = 'SET_ENGAGEMENT_AND_ENGAGEMENTRATE'
export const SET_SAVES = 'SET_SAVES'
export const SET_SHARES = 'SET_SHARES'
export const SET_CONTACT = 'SET_CONTACT'
export const SET_PARTNERSHIP = 'SET_PARTNERSHIP'
export const SET_LASTPOST = 'SET_LASTPOST'
export const SET_AUDIENCE_TYPE = 'SET_AUDIENCE_TYPE'
// actionTypes.js

export const SET_CREDIT_HISTORY = "SET_CREDIT_HISTORY";
export const SET_AVAILABLE_POINTS = "SET_AVAILABLE_POINTS";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const RESET_CREDIT_HISTORY = "RESET_CREDIT_HISTORY";
