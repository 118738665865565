// redux/clearAllReducer.js
const initialState = false;

const clearAllReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_CLEAR_ALL":
			return true; // Indicates that "clear all" has been clicked
		case "RESET_CLEAR_ALL":
			return false; // Resets the "clear all" state
		default:
			return state;
	}
};

export const setClearAll = () => ({
	type: "SET_CLEAR_ALL",
});

export const resetClearAll = () => ({
	type: "RESET_CLEAR_ALL",
});

export default clearAllReducer;
