import React, { useEffect, useState, useRef } from "react";
import FormData from "form-data";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { AiOutlineDownload } from "react-icons/ai";
import {
  BsFillArrowLeftCircleFill,
  BsPlusCircle,
  BsTextIndentLeft,
} from "react-icons/bs";
import LoadingSpinner from "../ResultPage/LoadingSpinner";
import SearchedHistory from "../SearchedHistory";
import axiosInstance from "../../interseptor";
import instagram from "../../components/asset/instagram.png";
import tiktok from "../../components/asset/tiktok.png";
import youtube from "../../components/asset/youtube1.png";
import { IoIosSearch } from "react-icons/io";
import { LuUpload } from "react-icons/lu";
import { Badge } from "flowbite-react";
import { HiBadgeCheck } from "react-icons/hi";
import constant from "../../constants/constant";
import { RiCloseFill } from "react-icons/ri";
import { decryptData } from "../../components/Auth/Encryption";

// import { Table, Badge, Dropdown, Button } from "flowbite-react";
import { Modal } from "flowbite-react";
import { RxCross2 } from "react-icons/rx";
import { createPortal } from "react-dom";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import TableRowSK from "../../components/skeleton/tableRowSK";
import {
  Router,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SearchResultFilters from "../../components/SearchResultFilters/SearchResultFilters";
import PlanComponent from "../../components/PlanComponent/PlanComponent";
import ProfilePopup from "../../components/profile/ProfilePopup";
import "../ResultPage/resultPage.css";
import AddToList from "../ResultPage/AddtoList";
// import { Button } from "flowbite-react/lib/esm";
import { AiOutlineContacts } from "react-icons/ai";
import { RiGalleryFill, RiPriceTag3Line } from "react-icons/ri";
import DismissableModal from "../ResultPage/DismissableModal";
import { setAllFilterOpen } from "../../redux/filters/actions";
import { useDispatch, useSelector } from "react-redux";
import ErrorMsgModal from "../ErrorMsgModal";
import { FaCloudDownloadAlt } from "react-icons/fa";
import PreviouslyExportedFilter from "../../components/SearchResultFilters/PreviouslyExported";
import IncludeContact from "../../components/SearchResultFilters/IncludeContact";
const GET_PLANS_URL = "/livesearch/planlist";
const VIEW_PLAN_URL = "/livesearch/viewplan";
// const CSVFILE_URL = `http://localhost:3000/sample.csv`
const CSVFILE_URL =
  "https://viralpitch.co/d2/dashboard/similar_profile_sample.csv";

const SimilarProfile = () => {
  const platforms = [
    { id: 1, label: "Instagram", Activeicon: instagram, icon: <FaInstagram /> },
    {
      id: 2,
      label: "YouTube",
      Activeicon: youtube,
      icon: <FaYoutube />,
    },
    { id: 3, label: "TikTok", Activeicon: tiktok, icon: <FaTiktok /> },
  ];
  const [jsonResult, setJsonResult] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [userData, setUserData] = useState("");
  const [realUserData, setRealUserData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [platform, setPlatform] = useState(platforms[0]);
  const [platformDropdown, setPlatformDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportId, setReportId] = useState();
  const [shouldContinueInterval, setShouldContinueInterval] = useState(true);
  const [showDownload, setShowDownload] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(platforms[0]);
  const [searchUsername, setSearchUsername] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [loadingTopics, setLoadingTopics] = useState(false);
  const [username, setAllusername] = useState([]);
  const [searchInput, setsearchInput] = useState("");
  const [searchTopic, setSearchTopic] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [plans, setPlans] = useState(null);
  const [lists, setLists] = useState();
  const [curListId, setCurListId] = useState();
  const [planId, setPlanId] = useState(null);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [isHide, setIsHide] = useState({});
  const [isEnd, setIsEnd] = useState(false);
  const [page, setPage] = useState(0);

  const categories = constant.catagories;
  const [show, setShow] = useState(false);
  const categoryMapping = {};
  const [final, setFinal] = useState([]);
  const [noDataFound, setNoDataFound] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const location = useLocation();
  const exportDataRef = useRef();
  const [searchParam, setSearchParam] = useSearchParams();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const quaryPlatform = searchParam.get("platform");
  const quaryFollowers = searchParam.get("followers");
  const quaryAudienceGender = searchParam.get("audienceGender");
  const quaryCreatorGender = searchParam.get("creatorGender");
  const quaryCreatorAge = searchParam.get("creatorAge");
  const quaryCreatorLocation = searchParam.get("creatorLocation");
  const quaryKeywords = searchParam.get("keywords");
  const quaryTopics = searchParam.get("topicsNew");
  const quaryCreatorBrand = searchParam.get("creatorBrand");
  const quaryNumberOfContent = searchParam.get("numberOfContent");
  const quaryIsVpRegistered = searchParam.get("isVpResigtered");
  const quaryIsVerified = searchParam.get("isVerified");
  const quaryHasContentDetails = searchParam.get("hasContactDetails");
  const quaryMostRecentPost = searchParam.get("mostRecentPost");
  const quaryCreatorLanguage = searchParam.get("creatorLanguage");
  const quaryCreatorInterest = searchParam.get("creatorInterest");
  const quaryBioPhrase = searchParam.get("bioPhrase");
  const quaryAccountType = searchParam.get("accountType");
  const quaryHashtag = searchParam.get("hashtag");
  const quaryMentions = searchParam.get("mentions");
  const quaryAudienceAge = searchParam.get("audienceAge");
  const quaryAudienceLocation = searchParam.get("audienceLocation");
  const quaryAudienceLanguage = searchParam.get("audienceLanguage");
  const quaryAudienceInterest = searchParam.get("audienceInterest");
  const quaryAudienceBrand = searchParam.get("audienceBrand");
  const quaryAverageLikes = searchParam.get("averageLikes");
  const quaryEngRate = searchParam.get("engagementRate");
  const quaryEngagements = searchParam.get("engagements");
  const quaryEng_rate = searchParam.get("eng_rate");
  const quaryIsSponcoredPost = searchParam.get("isSponcoredPost");
  const quaryPreviousBrandSponcored = searchParam.get("previousBrandSponcored");
  const quaryReelViewsUrl = searchParam.get("reelViews");
  const quaryFollowerGrowth = searchParam.get("followerGrowth");
  const quaryAudienceLookALike = searchParam.get("audienceLookALike");
  const quaryCreatorLookALike = searchParam.get("creatorLookALike");
  const quaryCredibleAccount = searchParam.get("credibleAccount");
  const quaryPreviouslyExported = searchParam.get("previousExported");
  const quaryExcludePrivateAccounts = searchParam.get("excludePrivateAccounts");
  const quaryReelPlays = searchParam.get("reel_plays");
  const quaryAudienceType = searchParam.get("audienceType");
  const quaryContact = searchParam.get("contact");
  const quaryPartnership = searchParam.get("partnership");
  const quaryIsPrevExported = searchParam.get("isPreviousExported");
  const quaryIncludeContact = searchParam.get("includeContact");

  // console.log("partership present or not:", quaryPartnership);

  const queryParams = new URLSearchParams(location.search);
  const selectedPlatformUrl = queryParams.get("platform");

  const topics = queryParams.get("filter") ? queryParams.get("filter") : "";

  let decodedTopics;
  if (topics && topics.length > 0) {
    let decodedUri = decodeURIComponent(topics);

    if (decodedUri) {
      try {
        decodedTopics = JSON.parse(decodedUri);
        // console.log('from url',decodedTopics)
        // dispatch(setGenderFilter(decodedTopics[0].CreatorGender));
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle the error appropriately
      }
    }
  }

  const [filters, setFilters] = useState(decodedTopics);
  const [isPlanmodalopen, setIsPlanmodalopen] = useState(false);
  const [openNewPlanInput, setOpenNewPlanInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isProfileDataAvailable, setIsProfileDataAvailable] = useState(false);
  const [cost, setCost] = useState("Free");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsgFromAPI, setErrorMsgFromAPI] = useState("");
  const [totalData, setTotalData] = useState(0);

  const selectedData = useSelector((state) => ({
    followerAllFilters: state.follower,
    creatorGender: state.creatorGender,
    creatorAge: state.age,
    creatorLocation: state.creatorLocation,
    keywords: state.keywords,
    topicsNew: state.topics,
    creatorBrand: state.creatorBrand,
    numberOfContent: state.numberOfContent,
    isVpResigtered: state.isVpResigtered,
    isVerified: state.isVerified,
    hasContactDetails: state.hasContactDetails,
    mostRecentPost: state.mostRecentPost,
    creatorLanguage: state.creatorLanguage,
    creatorInterest: state.creatorInterest,
    bioPhrase: state.bioPhrase,
    accountType: state.accountType,
    hashtag: state.hashtag,
    mentions: state.mentions,
    audienceGenderRedux: state.audienceGender,
    audienceAge: state.audienceAge,
    audienceLocation: state.audienceLocation,
    audienceLanguage: state.audienceLanguage,
    audienceInterest: state.audienceInterest,
    audienceBrand: state.audienceBrand,
    averageLikes: state.averageLikes,
    engagementRateFilter: state.engagementRate,
    isSponcoredPost: state.isSponcoredPost,
    previousBrandSponcored: state.previousBrandSponcored,
    reelViews: state.reelViews,
    followerGrowth: state.followerGrowth,
    audienceLookALike: state.audienceLookALike,
    creatorLookALike: state.creatorLookALike,
    allFilterOpen: state.allFilterOpen,
    ethnicity: state.ethnicity,
    contact: state.contact,
    credibleAccount: state.credibleAccount,
    previousExported: state.previouslyExported,
    excludePrivateAccounts: state.excludePrivateAccounts,
    hasAudienceData: state.hasAudienceData,
    excludeOfficialArtists: state.excludeOfficialArtists,
    engAndEngrate: state.engAndEngrate,
    saves: state.saves,
    shares: state.shares,
    partnership: state.partnership,
  }));

  const {
    followerAllFilters,
    creatorGender,
    creatorAge,
    creatorLocation,
    keywords,
    topicsNew,
    creatorBrand,
    numberOfContent,
    isVpResigtered,
    isVerified,
    hasContactDetails,
    mostRecentPost,
    creatorLanguage,
    creatorInterest,
    bioPhrase,
    accountType,
    hashtag,
    mentions,
    audienceGenderRedux,
    audienceAge,
    audienceLocation,
    audienceLanguage,
    audienceInterest,
    audienceBrand,
    averageLikes,
    engagementRateFilter,
    isSponcoredPost,
    previousBrandSponcored,
    reelViews,
    followerGrowth,
    audienceLookALike,
    creatorLookALike,
    allFilterOpen,
    ethnicity,
    contact,
    credibleAccount,
    previousExported,
    excludePrivateAccounts,
    hasAudienceData,
    excludeOfficialArtists,
    engAndEngrate,
    saves,
    shares,
    partnership,
  } = selectedData;

  const [sorting, setSorting] = useState({
    followers: "",
    likes: "",
    views: "",
    eng: "",
    engrate: "",
  });

  // Parse the query parameters from the URL
  const searchParams = new URLSearchParams(location.search);

  const convertUrlParamToArray = (param) => {
    return param ? param.split(",") : [];
  };

  // Retrieve the individual parameters

  const platformUrl = convertUrlParamToArray(searchParams.get("platform"));
  const followersUrl = convertUrlParamToArray(searchParams.get("followers"));
  const creatorGenderUrl = convertUrlParamToArray(
    searchParams.get("creatorGender")
  );
  const creatorAgeUrl = convertUrlParamToArray(searchParams.get("creatorAge"));
  const creatorLocationUrl = convertUrlParamToArray(
    searchParams.get("creatorLocation")
  );
  const keywordsUrl = convertUrlParamToArray(searchParams.get("keywords"));
  const topicsNewUrl = convertUrlParamToArray(searchParams.get("topicsNew"));
  const creatorBrandUrl = convertUrlParamToArray(
    searchParams.get("creatorBrand")
  );
  const numberOfContentUrl = convertUrlParamToArray(
    searchParams.get("numberOfContent")
  );
  const mostRecentPostUrl = convertUrlParamToArray(
    searchParams.get("mostRecentPost")
  );
  const creatorLanguageUrl = convertUrlParamToArray(
    searchParams.get("creatorLanguage")
  );
  const creatorInterestUrl = convertUrlParamToArray(
    searchParams.get("creatorInterest")
  );
  const bioPhraseUrl = convertUrlParamToArray(searchParams.get("bioPhrase"));
  const accountTypeUrl = convertUrlParamToArray(
    searchParams.get("accountType")
  );
  const hashtagUrl = convertUrlParamToArray(searchParams.get("hashtag"));
  const mentionsUrl = convertUrlParamToArray(searchParams.get("mentions"));
  const audienceGenderReduxUrl = convertUrlParamToArray(
    searchParams.get("audienceGender")
  );
  const audienceAgeUrl = convertUrlParamToArray(
    searchParams.get("audienceAge")
  );
  const audienceLocationUrl = convertUrlParamToArray(
    searchParams.get("audienceLocation")
  );
  const audienceLanguageUrl = convertUrlParamToArray(
    searchParams.get("audienceLanguage")
  );
  const audienceInterestUrl = convertUrlParamToArray(
    searchParams.get("audienceInterest")
  );
  const audienceBrandUrl = convertUrlParamToArray(
    searchParams.get("audienceBrand")
  );
  const averageLikesUrl = convertUrlParamToArray(
    searchParams.get("averageLikes")
  );
  const previousBrandSponcoredUrl = convertUrlParamToArray(
    searchParams.get("previousBrandSponcored")
  );

  const savesParams = searchParams.get("saves");
  const reelViewsUrl = convertUrlParamToArray(searchParams.get("reelViews"));
  const reelPlaysUrl = convertUrlParamToArray(searchParams.get("reel_plays"));
  const savesUrl = convertUrlParamToArray(savesParams);
  const sharesUrl = convertUrlParamToArray(searchParams.get("shares"));
  const followerGrowthUrl = convertUrlParamToArray(
    searchParams.get("followerGrowth")
  );
  const audienceLookALikeUrl = convertUrlParamToArray(
    searchParams.get("audienceLookALike")
  );
  const creatorLookALikeUrl = convertUrlParamToArray(
    searchParams.get("creatorLookALike")
  );
  const isVpResigteredUrl = searchParams.get("isVpResigtered") === "true";
  const isVerifiedUrl = searchParams.get("isVerified");
  const isCredibleAccountUrl = searchParams.get("credibleAccount");
  const isPreviousExportedUrl = searchParams.get("previousExported");
  const isExcludePrivateAccounts = searchParams.get("excludePrivateAccounts");
  const isExcludeOfficialArtistsUrl = searchParams.get(
    "excludeOfficialArtists"
  );
  const isAudienceData = searchParams.get("hasAudienceData");
  const isEthnicityUrl = searchParams.get("ethnicity");

  // const isEthnicityUrl = convertUrlParamToArray(searchParams.get("ethnicity"));
  console.log("Ethnicity", isEthnicityUrl);
  const isContactUrl = searchParams.get("contact");
  const isLastPostUrl = searchParams.get("lastPost");
  const isAudienceTypeUrl = searchParams.get("audienceType");
  // const engagementRateUrl = convertUrlParamToArray(searchParams.get("engagementRate"));
  const engAndEngrateUrl = searchParams.get("eng_rate");
  const engagementsUrl = searchParams.get("engagements");
  const partnershipUrl = convertUrlParamToArray(quaryPartnership);
  const hasContactDetailsUrl = searchParams.get("hasContactDetails") === "true";
  const isSponcoredPostUrl = searchParams.get("isSponcoredPost") === "true";

  const mapUrlParamsToNames = (urlParams) => {
    return urlParams.map((item) => {
      const parts = item.split("-k-");
      return parts.length === 2 ? parts[1] : null;
    });
  };

  const creatorLocationNames = mapUrlParamsToNames(creatorLocationUrl);
  const audienceLocationNames = mapUrlParamsToNames(audienceLocationUrl);
  const creatorBrandNames = mapUrlParamsToNames(creatorBrandUrl);
  const audienceBrandNames = mapUrlParamsToNames(audienceBrandUrl);
  const creatorLanguageNames = mapUrlParamsToNames(creatorLanguageUrl);
  const audienceLanguageNames = mapUrlParamsToNames(audienceLanguageUrl);
  const previousBrandSponsorNames = mapUrlParamsToNames(
    previousBrandSponcoredUrl
  );
  const partnershipNames = mapUrlParamsToNames(partnershipUrl);
  console.log(partnershipNames);

  const getPlans = async () => {
    try {
      let data = new FormData();
      data.append("logintype", "planlist");
      data.append("device_id", "search");
      data.append("status", "1");

      const response = await axiosInstance.post(GET_PLANS_URL, data);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          return false;
        }

        setPlans(response.data.list);
        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getList = async (val) => {
    // const plan_id = val
    setPlanId(val);
    setOpenNewPlanInput(false);
    // setCurplanname(val)
    try {
      let data = new FormData();
      data.append("logintype", "viewplan");
      data.append("device_id", "search");
      data.append("planid", val);
      data.append("status", "1");

      const response = await axiosInstance.post(VIEW_PLAN_URL, data);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          return false;
        }

        setLists(response.data.list.list_details);
        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCurList = (list_id) => {
    // setCurlistname(list_name)
    setCurListId(list_id);
  };

  const fetchData = async () => {
    var formdata = new FormData();
    formdata.append("page", page);
    formdata.append("logintype", "searchlist");
    formdata.append("device_id", "search");
    // formdata.append("logintype", "calculate-engagement");
    // formdata.append("device_id", "seacrh");
    formdata.append("platform", platform.label);
    formdata.append(
      "csvfile",
      new Blob([csvData], { type: "text/csv" }),
      "username.csv"
    );
    formdata.append("uploadfile", searchValue === "" ? "1" : "0");
    console.log(username, "checknig", searchResult);
    // formdata.append(
    // 	"username",
    // 	username.length > 0 ? username : searchResult
    // );
    formdata.append("similarsearch", "1");
    formdata.append("creator_lookalike", username || "");
    try {
      const response = await axiosInstance.post(
        `livesearch/searchlist`,
        formdata
      );
      console.log(response.data, "response.data");

      if (response?.data?.list?.data) {
        setTotalData(response?.data?.list?.data[0]?.totalprofile);
      }

      console.log(response?.data?.list?.data, "response?.data?.list?.data");

      if (response?.data?.status === 0) {
        setShowErrorMsg(true);
        setErrorMsgFromAPI(response?.data?.msg);
        // toast.error(response?.data?.msg, {
        //   pauseOnHover: false,
        //   closeOnClick: false  // Set closeOnClick to false
        // });
        return;
      }

      if (response?.data?.list?.data?.length === 0) {
        setIsProfileDataAvailable(true);
      }

      // setTotalData(response?.data?.list?.totalprofile);
      if (response?.data?.list?.data?.length < 10) {
        setIsEnd(true);
      } else setIsEnd(false);
      setPage((prev) => prev + 1);
      return response?.data?.list?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleCheckBox = (social_name) => {
    setSelectedInfluencers((prevSelectedInfluencers) => {
      console.log(prevSelectedInfluencers, typeof prevSelectedInfluencers);
      const influencerExists = prevSelectedInfluencers.some(
        (influencer) => influencer.social_name === social_name
      );

      if (influencerExists) {
        return prevSelectedInfluencers.filter(
          (influencer) => influencer.social_name !== social_name
        );
      } else {
        return [...prevSelectedInfluencers, ...social_name];
      }
    });
  };

  const handleSelectAll = () => {
    console.log(data, typeof data);
    if (selectedInfluencers.length === data.length) {
      setSelectedInfluencers([]);
    } else {
      setSelectedInfluencers(data?.map((d) => d.social_name));
    }
  };

  const handleSort = (e) => {
    // alert("sadasd")
    const sort_name = e.target.id;

    if (
      sort_name !== "followers" &&
      sort_name !== "likes" &&
      sort_name !== "views" &&
      sort_name !== "eng" &&
      sort_name !== "engrate"
    ) {
      return;
    }

    setSorting((prev) => {
      const updatedSorting = {
        followers: "",
        likes: "",
        views: "",
        eng: "",
        engrate: "",
      };
      updatedSorting[sort_name] = prev[sort_name] === "ASC" ? "DESC" : "ASC";
      console.log(updatedSorting, "sortn");
      return updatedSorting;
    });
  };

  const downloadCsv = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  const handlePlatform = (platform) => {
    setPlatform(platform);
  };

  const handleCSVFileChange = (event) => {
    setSearchValue("");
    setSearchResult([]);
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setCsvData(e.target.result);
        Papa.parse(e.target.result, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (result) => {
            setJsonResult(result.data);
          },
          error: (error) => {
            console.error("Error converting CSV to JSON: ", error.message);
          },
        });
      };

      reader.readAsText(file);
    } else {
      // Handle the case where no file was selected
      setCsvData("");
      setJsonResult([]);
    }
  };

  const handleSearchValue = (e) => {
    if (e.target.value.length === 0) {
      setShowSuggestion(false);
      setDataFetched(false);
    } else {
      setShowSuggestion(true);
    }
    setSearchValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    fetchData() // Fetch data for the initial page (page 0)
      .then((responseData) => {
        console.log(responseData, "updated data checking");
        setIsLoading(false);
        setData(responseData);
        setShowDownload(true);

        console.log(responseData?.data, responseData);
      })
      .catch((error) => {
        console.log(`Error loading data: ${error.message}`);
        setIsLoading(false);
        setShowDownload(false);
      });
    console.log(setSearchResult(searchValue.split(", ")));
    setRealUserData([]);
    setShowSuggestion(false);
    setDataFetched(true);
  };

  useEffect(() => {
    setLoading(true); // Start loading

    let userDataUpdated = false;

    const fetchInitialData = async () => {
      setShowDownload(false);
      try {
        var formdata = new FormData();
        formdata.append("logintype", "searchlist");
        formdata.append("device_id", "search");
        // formdata.append("logintype", "calculate-engagement");
        // formdata.append("device_id", "seacrh");
        formdata.append("platform", platform.label);
        formdata.append(
          "csvfile",
          new Blob([csvData], { type: "text/csv" }),
          "username.csv"
        );
        formdata.append("uploadfile", searchValue === "" ? "1" : "0");
        console.log(username, "checknig", searchResult);
        // formdata.append(
        // 	"username",
        // 	username.length > 0 ? username : searchResult
        // );
        formdata.append("similarsearch", "1");
        formdata.append("creator_lookalike", username || "");

        // const response = await axiosInstance.post(
        // 	"livesearch/calculate-engagement",
        // 	formdata
        // );

        const response = await axiosInstance.post(
          `livesearch/searchlist`,
          formdata
        );
        console.log("response first", response?.data);
        console.log(username, "checknig", searchResult);
        userDataUpdated = true;
        setUserData(response?.data);
        setLoading(false); // Stop loading
        if (response?.data.list.length > 0) {
          setReportId(response?.data.report_id);
          setShowDownload(true);
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }

      return () => {
        if (!userDataUpdated) {
          setLoading(false); // Stop loading if userData is not updated
        }
      };
    };

    fetchInitialData();
  }, [csvData, searchResult]);

  const convertToCsv = () => {
    const csvHeaders = [
      "Average Likes",
      "Average Views",
      "Engagement rate",
      "Followers",
      "Name",
      "Platform UserName",
    ];

    const csvData = [csvHeaders];
    console.log(data);
    data?.forEach((item) => {
      const row = [
        item.avg_like,
        item.avg_views,
        item.engagement_rate,
        item.followers,
        item.social_name,
        item.social_user_name,
      ];
      console.log(row);
      csvData.push(row);
    });

    return Papa.unparse(csvData);
  };

  const handleDownload = () => {
    const csvData = convertToCsv();

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "userData.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Bearer auZKqWbYZYJFL5qBuAU00brgrQwlqkUGqCJMjn9DQPUZL4I5Mv"
  );
  myHeaders.append("Cookie", "PHPSESSID=4r0t2656f9id1236bs23psr2rv");

  var formdata = new FormData();
  formdata.append("logintype", "get_eng_report");
  formdata.append("device_id", "seacrh");
  formdata.append("report_id", reportId);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  // const fetchData = async (val) => {
  // 	console.log("help");
  // 	try {
  // 		let formdata = new FormData();

  // 		formdata.append("logintype", "searchlist");
  // 		formdata.append("device_id", "search");
  // 		// formdata.append("logintype", "get_eng_report");
  // 		// formdata.append("device_id", "seacrh");
  // 		// formdata.append("report_id", val || reportId);

  // 		// Only fetch if shouldContinueInterval is true
  // 		// if (shouldContinueInterval && userData && userData?.list?.length > 0 && userData?.list !== ' ') {
  // 		const response = await axiosInstance.post(
  // 			"livesearch/get_eng_report",
  // 			formdata
  // 		);

  // 		console.log("data", response?.data);

  // 		setRealUserData(response?.data?.list?.data);

  // 		// Additional logic or processing with the fetched data can be added here

  // 		setLoading(false);

  // 		// Check if there are no items with scrap_status === 0
  // 		const noItemsWithScrapStatusZero = response?.data?.list.every(
  // 			(item) => item?.scrap_status !== "0"
  // 		);

  // 		// console.log("scrap status =", noItemsWithScrapStatusZero);

  // 		// If no items have scrap_status === 0, stop the interval
  // 		if (noItemsWithScrapStatusZero) {
  // 			setShouldContinueInterval(false);
  // 		}
  // 		// } else {
  // 		// Set loading to false when userData.length is 0 or is ' ' or undefined
  // 		setLoading(false);
  // 		setShowDownload(true);
  // 		// }
  // 	} catch (error) {
  // 		console.error("Error fetching data:", error);
  // 		setLoading(false);
  // 	}
  // };

  // useEffect(() => {
  // 	// Set up an interval to fetch data every 5 seconds
  // 	const newIntervalId = setInterval(() => {
  // 		// Fetch data only when shouldContinueInterval is true
  // 		if (
  // 			shouldContinueInterval &&
  // 			userData &&
  // 			userData?.list?.length > 0 &&
  // 			userData?.list !== " "
  // 		) {
  // 			// console.log({ TEST: "ADFASF", userData })
  // 			fetchData();
  // 		}
  // 	}, 3000);

  // 	// Cleanup the interval when the component unmounts or shouldContinueInterval is false
  // 	return () => {
  // 		clearInterval(newIntervalId);
  // 		if (!shouldContinueInterval) {
  // 			setShouldContinueInterval(true); // Reset the state for future use
  // 		}
  // 	};
  // }, [userData, shouldContinueInterval]); // Dependencies array should include userData and shouldContinueInterval

  const handleScroll = function () {
    setLoadingMore(true);

    // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // const windowHeight = document.documentElement.clientHeight;
    // const documentHeight = document.documentElement.offsetHeight;
    // const top_cl = Math.ceil(scrollTop + windowHeight);
    // if (top_cl >= documentHeight && isEnd === false) {
    // alert()
    // User has scrolled to the bottom, load next data
    fetchData(page)
      .then((response) => {
        setData((prevData) => [...prevData, ...response]);
        setLoadingMore((prev) => {
          if (prev === 10) {
            return false;
          }
        });
      })
      .catch((error) => {
        console.log(`Error loading data for page ${page}: ${error.message}`);
      });
    // }
  };

  // useEffect(() => {
  // 	setIsLoading(true);

  // 	fetchData(0) // Fetch data for the initial page (page 0)
  // 		.then((responseData) => {
  // 			console.log(responseData.data, "updated data checking");
  // 			setPage(1);
  // 			setIsLoading(false);
  // 			setData(responseData?.data);
  // 			console.log(responseData?.data);
  // 		})
  // 		.catch((error) => {
  // 			console.log(`Error loading data: ${error.message}`);
  // 			setIsLoading(false);
  // 		});
  // }, [
  // 	quaryPlatform,
  // 	quaryFollowers,
  // 	quaryAudienceGender,
  // 	quaryAudienceLanguage,
  // 	quaryCreatorLanguage,
  // 	quaryCreatorGender,
  // 	quaryCreatorAge,
  // 	quaryCreatorLocation,
  // 	quaryKeywords,
  // 	quaryTopics,
  // 	quaryCreatorBrand,
  // 	quaryNumberOfContent,
  // 	quaryHasContentDetails,
  // 	quaryMostRecentPost,
  // 	quaryCreatorInterest,
  // 	quaryBioPhrase,
  // 	// quaryAccountType,
  // 	quaryHashtag,
  // 	quaryMentions,
  // 	quaryAudienceAge,
  // 	quaryAudienceLocation,
  // 	// quaryAudienceLanguage,
  // 	quaryAudienceInterest,
  // 	quaryAudienceBrand,
  // 	quaryAverageLikes,
  // 	quaryEngRate,
  // 	quaryIsSponcoredPost,
  // 	quaryPreviousBrandSponcored,
  // 	quaryReelViewsUrl,
  // 	quaryFollowerGrowth,
  // 	quaryAudienceLookALike,
  // 	quaryCreatorLookALike,
  // 	quaryCredibleAccount,
  // 	quaryIsVerified,
  // 	quaryPreviouslyExported,
  // 	quaryExcludePrivateAccounts,
  // 	hasAudienceData,
  // 	excludeOfficialArtists,
  // 	ethnicity,
  // 	// engAndEngrate,
  // 	quaryEng_rate,
  // 	quaryEngagements,
  // 	sorting,
  // 	// partnershipUrl,
  // 	quaryReelPlays,
  // 	quaryAccountType,
  // 	quaryAudienceType,
  // 	quaryContact,
  // 	quaryPartnership,
  // 	// savesUrl
  // 	savesParams,
  // ]);

  useEffect(() => {
    rendertable(data);
  }, [data]);

  // useEffect(() => {
  // 	rendertable(data);
  // }, []);

  // useEffect(() => {
  // 	rendertable(data);
  // }, []);

  const clearCheckedInfluencer = () => {
    setSelectedInfluencers([]);
  };

  const closeprofilepopup = () => {
    setShow(false);
  };

  const openPlanModal = (social_name) => {
    getPlans();
    if (social_name) {
      Array.isArray(social_name)
        ? setSelectedInfluencers([...social_name])
        : setSelectedInfluencers([social_name]);
    }
    setIsPlanmodalopen(true);
  };

  const closePlanModal = () => {
    setIsPlanmodalopen(false);
    setPlanId("");
    setCurListId("");
  };

  const fetchSimilarUsername = async (value) => {
    // console.log(value, 'value')
    try {
      const formdata = new FormData();
      formdata.append("filter", "lookalike");
      formdata.append("logintype", "getfilters");
      formdata.append("device_id", "search");
      formdata.append("platform", platform.label.toLowerCase());
      formdata.append("search_string", value || "");

      const response = await axiosInstance.post(
        "livesearch/getfilters",
        formdata
      );
      // const response = await axiosInstance.post(
      // 				`livesearch/searchlist`,
      // 				formdata
      // 			);

      console.log("result of similar user", response?.data);
      if (response?.data?.status === 1) {
        setSearchUsername(response?.data?.response?.data?.data);
        console.log("lookalike data", response.data.response.data);
      } else {
        // setError(response.msg);
        console.error("Error:", response?.data?.msg);
      }
    } catch (error) {
      // setError('An error occurred while fetching data.');
      console.error("Error:", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchSimilarUsername(searchValue);
  }, [searchValue]);

  const userDataFn = () => {
    return realUserData?.list?.length > 0 ? realUserData : userData;
  };

  function reportIdNew(val) {
    console.log({ val });
    fetchData(val);
    setShowDownload(true);
  }

  function usernamhHandler(val) {
    const allusernmae = [...username];
    const findUsername = allusernmae.find((name) => {
      return name === val.username;
    });
    setSearchValue("");
    setShowSuggestion(false);

    if (findUsername === undefined) {
      setAllusername((prev) => [...prev, val.username]);
    }
    console.log(findUsername);
  }

  const removeFromSearch = (index) => {
    const updatedSearches = [...username];
    updatedSearches.splice(index, 1);
    setAllusername(updatedSearches);
    const updatedSearchNames = [...username];
    updatedSearchNames.splice(index, 1);
    setAllusername(updatedSearchNames);
  };

  // useEffect(() => {
  // 	const fetchData = async (value) => {
  // 		setLoadingTopics(true);

  // 		try {
  // 			const formdata = new FormData();
  // 			formdata.append("logintype", "searchtopic");
  // 			formdata.append("device_id", "seacrh");
  // 			formdata.append("platform", selectedPlatform.label);
  // 			formdata.append("filter", "lookalike");
  // 			formdata.append("search_string", value.substring(1)); // Remove '@' from the username

  // 			const response = await axiosInstance.post(
  // 				"livesearch/searchtopic",
  // 				formdata
  // 			);

  // 			setSearchTopic(response?.data?.response?.data);
  // 			setSearchUsername([]);
  // 			setLoadingTopics(false);

  // 			if (searchInput.length === 0) {
  // 				setSearchTopic([]);
  // 			}
  // 		} catch (error) {
  // 			console.error("Error:", error);
  // 		}
  // 	};

  // 	if (searchValue.length > 1) {
  // 		fetchData(searchValue);
  // 	}
  // }, [searchValue, selectedPlatform.label]);

  const rendertable = function (data) {
    if (!Array.isArray(data)) {
      setFinal(
        <tr>
          <td colSpan={5} className="text-center py-5">
            No Data Found
          </td>
        </tr>
      );
      setNoDataFound(false);
      return null;
    } else {
      setNoDataFound(true);
    }

    const updatedFinal = data?.map((filteredinfluencer, index) => (
      <>
        <tr
          key={`${filteredinfluencer?.social_name}`}
          className=" hover:bg-[#EEF5FF] text-sm h-24 border text-black"
        >
          <td className="py-2">
            <div className="flex items-center whitespace-nowrap font-bold text-align-left">
              {JSON.parse(decryptData(localStorage.getItem("rights_action")))[
                "73"
              ].includes("119") && (
                <label className="container1 flex items-center pl-4 pr-2">
                  <input
                    type="checkbox"
                    key={filteredinfluencer?.social_name}
                    checked={selectedInfluencers?.some(
                      (influencer) =>
                        influencer === filteredinfluencer?.social_name
                    )}
                    onChange={() =>
                      handleCheckBox(filteredinfluencer?.social_name)
                    }
                    className="left-2 rounded-[4px] bg-gray-100 border border-gray-300 w-3.5 h-3.5"
                  />{" "}
                  {/* {console.log(typeof selectedInfluencers, selectedInfluencers)} */}
                  <div className="checkmark"></div>
                </label>
              )}
              <div className="w-12 h-12 ml-2">
                <img
                  alt=""
                  className="!rounded-full w-full h-full"
                  // src={`https://storage.googleapis.com/viralimage/${filteredinfluencer.im}`}
                  src={`${filteredinfluencer?.im}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null || undefined; // prevents looping
                    currentTarget.src = "/asset/onErrorImage.jpg";
                  }}
                />
              </div>

              <div className="pl-2 font-medium">
                <div className="flex items-center ">
                  {filteredinfluencer?.social_user_name?.slice(0, 20)}
                  {filteredinfluencer?.is_verified === true && (
                    <span>
                      <HiBadgeCheck className="text-primary-500" size={18} />
                    </span>
                  )}
                </div>
                <a
                  href={
                    selectedPlatform.label === "TikTok"
                      ? "https://tiikok.com/@" + filteredinfluencer?.social_name
                      : selectedPlatform.label === "Youtube"
                      ? "https://www.youtube.com/channel/" +
                        filteredinfluencer?.social_name
                      : "https://instagram.com/" +
                        filteredinfluencer?.social_name
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-sm ">
                    @{filteredinfluencer?.social_name}
                  </div>
                </a>
              </div>
            </div>
          </td>
          <td className=" px-4 py-2">
            {Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(filteredinfluencer?.followers)}
          </td>
          <td className=" px-4 py-2">
            {Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(parseInt(filteredinfluencer?.avg_like))}
          </td>
          <td className=" px-4 py-2">
            {Math.round(filteredinfluencer?.engagement_rate * 100) / 100}%
          </td>

          {reelViewsUrl?.length > 0 && (
            <td className=" px-4 py-2">
              {Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 1,
              }).format(filteredinfluencer?.avg_views)}
            </td>
          )}
          <td className="py-2 flex justify-center">
            <div className="flex text-xl gap-1 p-2">
              <div className="flex flex-col">
                <div className=" py-1 flex gap-0 rounded-md">
                  {JSON.parse(
                    decryptData(localStorage.getItem("rights_action"))
                  )["73"].includes("120") && (
                    <div
                      onClick={() => openPlanModal(filteredinfluencer)}
                      className="group relative px-2 cursor-pointer"
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-full hover:text-blue-500">
                        <BsPlusCircle size="24" />
                      </div>
                      <span className="w-[90px] absolute -top-8 left-[50%] -translate-x-[50%] whitespace-nowrap z-20 origin-left scale-0 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium shadow-md transition-all duration-300 ease-in-out group-hover:scale-100">
                        Add to List
                      </span>
                    </div>
                  )}
                  {JSON.parse(
                    decryptData(localStorage.getItem("rights_action"))
                  )["73"].includes("119") && (
                    <Link
                      to={`/profile/?id=${
                        filteredinfluencer?.social_name
                      }&platformUrl=${encodeURIComponent(
                        selectedPlatform.label
                      )}`}
                    >
                      <div className="group relative px-2 cursor-pointer">
                        <div className="flex h-10 w-10 items-center justify-center rounded-full hover:text-blue-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            height="24"
                            width="24"
                          >
                            <path
                              strokeLinejoin="round"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
                            ></path>
                          </svg>
                        </div>
                        <span className="absolute -top-8 left-[50%] -translate-x-[50%] z-20 origin-left scale-0 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium shadow-md transition-all duration-300 ease-in-out group-hover:scale-100">
                          Profile
                        </span>
                      </div>
                    </Link>
                  )}{" "}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </>
    ));
    setFinal(updatedFinal);
  };

  return (
    <div className="mt-2 w-full flex flex-col items-center  bg-[#FAFAFA]">
      <div className="w-11/12 z-40 flex flex-col items-center justify-center rounded-lg  py-9">
        <p className="text-3xl mb-1 font-[600] ">
          {" "}
          Effortlessly Discover Similar Influencers Using Built-In Tools and
          Recommendation Algorithms.
          <span
            style={{
              backgroundImage:
                "linear-gradient(to right, #7279FD, #7279FD, #BF00DB)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
              fontWeight: "600",
            }}
          >
            {" "}
            Search for Similar Influencer Profiles .
          </span>{" "}
        </p>

        <div className="w-full justify-center flex flex-col my-4 m-auto mt-10">
          <div className="flex  w-full overflow-x-auto  ">
            {platforms.map((socialplatform) => (
              <button
                key={socialplatform.id}
                className={`flex-shrink-0 shadow-lg inline-flex justify-center font items-center h-14 w-[160px] py-3 px-4 text-base font-medium text-center
                                ${
                                  platform.id === socialplatform.id
                                    ? "bg-black text-white rounded-[8px]"
                                    : "bg-white slide-animation"
                                }
                                focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600 relative`}
                onClick={() => handlePlatform(socialplatform)}
                type="button"
                style={{
                  transition:
                    "background-color 0.3s, color 0.3s, border-radius 0.3s",
                }}
              >
                <div className="flex gap-1 items-center justify-center">
                  {/* Use appropriate icon based on the selected platform */}
                  {socialplatform.id === platform.id ? (
                    <img
                      src={socialplatform.Activeicon}
                      className="w-[20px]"
                      alt="icon"
                    />
                  ) : (
                    socialplatform.icon
                  )}
                  {socialplatform.label}
                </div>
              </button>
            ))}
          </div>

          <div className="shadow-md ">
            <div className="relative py-6 px-6 rounded-tr-[8px] w-full bg-white ">
              <span className="w-full pl-12 flex overflow-auto min-w-[300px] items-center border bg-gray-50 border-gray-300 p-0 gap-[5px]">
                <div className="max-w-auto flex overflow-auto">
                  {username &&
                    username?.map((name, index) => (
                      <button
                        key={index}
                        className="my-1 mx-1 px-2 py-1 flex whitespace-nowrap items-center capitalize bg-blue-100 text-blue-800 rounded-lg text-sm"
                        onClick={() => removeFromSearch(index)}
                      >
                        {name}
                        <RiCloseFill />
                      </button>
                    ))}
                </div>
                <div className="absolute z-[24] w-[20px] left-[50px] top-[43%]">
                  {" "}
                  <IoIosSearch />
                </div>
                <input
                  onChange={handleSearchValue}
                  // onFocus={() => setShowSearchSuggestion(true)}
                  // onClick={handleShowSuggest}
                  value={searchValue}
                  type="search"
                  className={`p-3 ${
                    searchValue.length > 0 ? "w-fit min-w-[50px]" : "w-full"
                  } pl-0 border-none z-20 text-base text-black bg-gray-50 border-0 focus:ring-transparent`}
                  placeholder="Find 🔍 Similar Profiles 👯"
                  required
                  autoComplete="false"
                />
                <div
                  className={`absolute left-0 bg-white z-50 h-[300px] w-[80%] border overflow-auto  ${
                    loadingTopics || !showSuggestion
                      ? "bottom-0 top-[100%]"
                      : "bottom-0 top-[100%]"
                  } rounded-lg  ${showSuggestion ? "" : "hidden"}`}
                >
                  {loadingTopics || !showSuggestion ? (
                    // Render loading indicator while data is being fetched
                    <div className="text-lg font-semibold pb-5 p-5">
                      Loading...
                    </div>
                  ) : searchUsername?.length > 0 ? (
                    <div className="pl-5 pt-5 w-full">
                      <div className="text-gray-500 text-lg font-normal">
                        Similer Username
                      </div>
                      <div className="flex flex-wrap">
                        {searchUsername
                          ?.filter((category) => category?.username) // Filter out items without a username
                          .slice(0, 20) // Limit the display to the first 20 items
                          .map((category) => (
                            <button
                              key={category?.user_id}
                              onClick={() => {
                                usernamhHandler(category);
                              }}
                            >
                              <Badge className="my-2 rounded-full mr-2 p-1 pr-3 cursor-pointer bg-indigo-100 text-indigo capitalize">
                                <div className="flex items-center gap-1">
                                  {category?.picture && (
                                    <div className="w-8 h-8 rounded-full overflow-hidden">
                                      <img
                                        className="w-full h-full object-cover"
                                        src={category?.picture}
                                        alt="img"
                                      />
                                    </div>
                                  )}

                                  <div className="flex items-center gap-1">
                                    <div className="flex flex-col justify-start items-start">
                                      <div className="flex gap-1">
                                        <p>{category?.username}</p>
                                        <div>
                                          {category?.is_verified && (
                                            <HiBadgeCheck
                                              className="text-primary-500"
                                              size={16}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <p className="text-gray-600 text-xs font-thin">
                                        {Intl.NumberFormat("en-US", {
                                          notation: "compact",
                                          maximumFractionDigits: 1,
                                        }).format(category?.followers)}
                                        &nbsp; Followers
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Badge>
                            </button>
                          ))}
                      </div>
                    </div>
                  ) : (
                    searchValue !== "" &&
                    searchValue.length > 1 && (
                      <>
                        <button>
                          <Badge className="my-2 rounded-full mr-2 p-1 pr-3 cursor-pointer bg-indigo-100 text-indigo capitalize">
                            <div className="flex items-center gap-1">
                              {
                                <div className="w-8 h-8 rounded-full overflow-hidden">
                                  <img
                                    className="w-full h-full object-cover"
                                    src={"/avatar.png"}
                                    alt="img"
                                  />
                                </div>
                              }

                              <div className="flex items-center gap-1">
                                <div className="flex flex-col justify-start border items-start">
                                  <div className="flex gap-1">
                                    <p
                                      onClick={() => {
                                        setAllusername((prev) => [
                                          ...prev,
                                          searchValue,
                                        ]);
                                      }}
                                    >
                                      {searchValue}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Badge>
                        </button>
                      </>
                    )
                  )}
                </div>
              </span>
            </div>
            <div className="pl-6 rounded-lg flex justify-between bg-white">
              <div>
                <SearchedHistory sendReportId={reportIdNew} reportType={1} />
              </div>
              <div className="w-auto">
                <label
                  htmlFor="csvFileInput"
                  className="w-auto rounded-md px-3  bg-white py-3 flex items-center gap-1 text-black border-[0.6px] border-[#D9D9D9]   text-sm whitespace-nowrap cursor-pointer"
                >
                  <LuUpload className="text-md" />
                  Upload CSV
                </label>
                <input
                  type="file"
                  id="csvFileInput"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={handleCSVFileChange}
                />
              </div>
              <div
                onClick={() => {
                  downloadCsv(CSVFILE_URL);
                }}
              >
                <div className="w-auto rounded-md px-3 ml-2 bg-white py-3 flex items-center gap-1 text-black border-[0.6px] border-[#D9D9D9]   text-sm whitespace-nowrap cursor-pointer">
                  <AiOutlineDownload className="text-md" />
                  Sample CSV
                </div>
              </div>

              <div className="w-full items-center  flex justify-end">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="rounded-[4px] mb-6 mx-6  bg-[#686FFD] text-white py-3 px-4"
                >
                  {" "}
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDownload && (
        <div className="w-11/12 sticky top-0 bg-white z-20 py-5 flex gap-2 justify-end">
          {/* <ToggleSearchedView /> */}

          <div>
            <button
              className="shadow-md px-3 py-2 flex gap-1 items-center rounded-lg bg-[#323237] text-white"
              onClick={handleDownload}
            >
              <AiOutlineDownload className="text-2xl" /> Download
            </button>
          </div>
        </div>
      )}

      <div className="w-11/12 bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
        <div className="w-full">
          <div className="overflow-x-auto">
            <table className=" w-full rounded-[8px] table-auto border-collapse">
              <thead
                className="static top-0 border  bg-[#EEF5FF]  text-[#828282] whitespace-nowrap"
                onClick={handleSort}
              >
                <tr className="h-14">
                  <th className="capitalize font-medium text-sm pt-3 pb-3">
                    <div id="insta" className="flex items-center">
                      <label className="container1 flex items-center pl-4 pb-1 pr-4">
                        <input
                          type="checkbox"
                          checked={
                            (selectedInfluencers?.length || 0) ===
                              (data?.length || 0) && (data?.length || 0) > 0
                          }
                          onChange={handleSelectAll}
                          className="left-2 rounded-[4px] bg-gray-100 border border-gray-300 w-3.5 h-3.5"
                        />
                        <div className="checkmark"></div>
                      </label>

                      {selectedPlatform.label === "Instagram" &&
                        "Instagram Profiles"}
                      {selectedPlatform.label === "YouTube" &&
                        "Youtube Profiles"}
                      {selectedPlatform.label === "TikTok" && "TikTok Profiles"}
                    </div>
                  </th>

                  <th className="cursor-pointer capitalize font-medium text-sm p-3">
                    <div id="followers" className="flex">
                      Followers
                      {sorting?.followers === "ASC" ? (
                        <BiSortDown size={17} id="followers" />
                      ) : (
                        <BiSortUp size={17} id="followers" />
                      )}
                    </div>
                  </th>

                  <th className="cursor-pointer capitalize font-medium text-sm p-3">
                    <div id="eng" className="flex">
                      Engagement
                      {sorting?.eng === "ASC" ? (
                        <BiSortDown size={17} id="eng" />
                      ) : (
                        <BiSortUp size={17} id="eng" />
                      )}
                    </div>
                  </th>

                  <th className="cursor-pointer capitalize font-medium text-sm p-3">
                    <div id="engrate" className="flex">
                      Engagement Rate
                      {sorting?.engrate === "ASC" ? (
                        <BiSortDown size={17} id="engrate" />
                      ) : (
                        <BiSortUp size={17} id="engrate" />
                      )}
                    </div>
                  </th>

                  {reelViewsUrl?.length > 0 &&
                    (selectedPlatform.label === "Instagram" ? (
                      <th className="cursor-pointer capitalize font-medium text-sm p-3">
                        <div id="eng" className="flex">
                          Reel Views
                        </div>
                      </th>
                    ) : selectedPlatform.label === "Youtube" ? (
                      <th className="cursor-pointer capitalize font-medium text-sm p-3">
                        <div id="eng" className="flex">
                          Views
                        </div>
                      </th>
                    ) : (
                      <th className="cursor-pointer capitalize font-medium text-sm p-3">
                        <div id="eng" className="flex">
                          Average Views
                        </div>
                      </th>
                    ))}

                  <th className="cursor-pointer capitalize font-medium text-sm p-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>{final}</tbody>
            </table>
          </div>
        </div>
      </div>

      {isEnd ? (
        <div className="text-center flex justify-center">
          <p className="px-6 py-1 rounded-md text-black bg-[#f7cf5f]">
            End Result
          </p>
        </div>
      ) : (
        noDataFound && (
          <div className="flex justify-center my-5">
            {loadingMore ? (
              <button className="bg-[#201E20] duration-200 transition-all hover:opacity-90 text-slate-50 px-4 py-2 rounded-lg">
                <LoadingSpinner /> Loading
              </button>
            ) : (
              <button
                className="bg-[#201E20] duration-200 transition-all hover:opacity-90 text-slate-50 px-4 py-2 rounded-lg"
                onClick={handleScroll}
              >
                Load more
              </button>
            )}
          </div>
        )
      )}
      <AddToList
        plans={plans}
        getPlans={getPlans}
        lists={lists}
        curListId={curListId}
        handleCurList={handleCurList}
        planId={planId}
        selectedInfluencers={selectedInfluencers}
        closePlanModal={closePlanModal}
        isPlanmodalopen={isPlanmodalopen}
        getList={getList}
        openNewPlanInput={openNewPlanInput}
        setOpenNewPlanInput={setOpenNewPlanInput}
        clearCheckedInfluencer={clearCheckedInfluencer}
        setSelectedInfluencers={setSelectedInfluencers}
      />
    </div>
  );
};

export default SimilarProfile;
