import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { IoMdArrowRoundBack, IoMdWallet } from "react-icons/io";
import { BiSortDown, BiSortUp } from "react-icons/bi"; // Import sorting icons
import axiosInstance from "../../interseptor";
import LoadingSpinner from "../ResultPage/LoadingSpinner";
import BuyCreditsModal from "./BuyCreditsModal"; // Import the modal component
import ErrorMsgModal from "../ErrorMsgModal";
const GET_CREDIT_HISTORY = "/livesearch/credit_history";

const WalletHistory = () => {
  const [creditHistory, setCreditHistory] = useState([]);
  const [displayedHistory, setDisplayedHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [availablePoints, setAvailablePoints] = useState();
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsgFromAPI, setErrorMsgFromAPI] = useState("");
  const [showBuyCreditsModal, setShowBuyCreditsModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const getCreditHistory = async () => {
    let data = new FormData();
    data.append("logintype", "credit_history");
    data.append("device_id", "seacrh");

    try {
      const response = await axiosInstance.post(GET_CREDIT_HISTORY, data);

      if (response?.data?.status === 0) {
        setShowErrorMsg(true);
        setErrorMsgFromAPI(response?.data?.msg);
        setIsLoading(false);
        return;
      }

      if (response?.data?.status_code === 200) {
        const history = response?.data?.list;
        setCreditHistory(history);
        setAvailablePoints(response?.data?.avaliable_points);
        setDisplayedHistory(history.reverse().slice(0, itemsPerPage));
        setIsLoading(false);
      } else if (response?.data?.status === 0 || response?.data?.status === 3) {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Sort data based on selected column and order
  const sortData = (data, column, order) => {
    if (!column) return data;

    return [...data].sort((a, b) => {
      let aValue = a[column];
      let bValue = b[column];

      if (column === "date") {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }

      if (order === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  };

  // Handle sorting logic
  const handleSort = (column) => {
    const newSortOrder =
      column === sortColumn && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(newSortOrder);
    const sortedData = sortData(creditHistory, column, newSortOrder);
    setDisplayedHistory(sortedData.slice(0, currentPage * itemsPerPage));
  };

  const datefunc = (datestring) => {
    const createdOn = new Date(datestring);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    return createdOn.toLocaleString("en-US", options);
  };

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const newTransactions = creditHistory.slice(0, nextPage * itemsPerPage);
    setDisplayedHistory(newTransactions);
    setCurrentPage(nextPage);
  };

  useEffect(() => {
    getCreditHistory();
  }, []);

  return (
    <div className="w-full mt-2 px-8 mb-5">
      {showErrorMsg && <ErrorMsgModal errorMsgFromAPI={errorMsgFromAPI} />}
      <div
        className="flex gap-1 items-center"
        onClick={() => navigate("/home")}
      >
        <IoMdArrowRoundBack className="text-lg cursor-pointer" />
        <p className="font-semibold">Wallet</p>
      </div>

      <div className="h-[150px] w-full flex items-center justify-between rounded-lg bg-[#EEF5FF] mt-5">
        <div className="ml-5 text-black">
          <IoMdWallet className="text-2xl text-black-100" />
          <p className="text-2xl text-black">Total Balance</p>
          <div className="flex items-end gap-5">
            <p className="text-4xl font-bold text-black-100">
              {availablePoints || 0}
            </p>
            <p className="text-black text-sm">
              Last Transaction:{" "}
              {creditHistory?.length > 0 && datefunc(creditHistory?.[0]?.date)}
            </p>
          </div>
        </div>
        <div>
          <Link
            to="/wallet-history/payment-history"
            className="border rounded-md p-3 antialiased bg-blue-500 mr-4 text-white"
          >
            View Transactions
          </Link>
          <button
            className="border rounded-md px-3 py-[0.6rem] bg-green-500 mr-4 text-white"
            onClick={() => setShowBuyCreditsModal(true)}
          >
            Buy Credits
          </button>
        </div>
      </div>

      <h1 className="my-5 font-bold">Transaction History</h1>

      <table className="my-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="sticky top-0 bg-[#EEF5FF] text-black">
          <tr className="h-12">
            <th className="text-sm font-medium capitalize pl-2">S. No.</th>
            <th className="text-sm font-medium capitalize">
              <div
                onClick={() => handleSort("date")}
                className="flex items-center cursor-pointer"
              >
                Date
                <span className="px-1"></span>
                {sortOrder === "asc" ? (
                  <BiSortDown size={17} />
                ) : (
                  <BiSortUp size={17} />
                )}
              </div>
            </th>
            <th className="text-sm font-medium capitalize">
              <div
                onClick={() => handleSort("points")}
                className="flex items-center cursor-pointer"
              >
                Points <span className="px-1"></span>
                {sortOrder === "asc" ? (
                  <BiSortDown size={17} />
                ) : (
                  <BiSortUp size={17} />
                )}
              </div>
            </th>
            <th className="text-sm font-medium capitalize">
              <div
                onClick={() => handleSort("transaction_type")}
                className="flex items-center cursor-pointer"
              >
                Transaction Type
                <span className="px-1"></span>
                {sortOrder === "asc" ? (
                  <BiSortDown size={17} />
                ) : (
                  <BiSortUp size={17} />
                )}
              </div>
            </th>
            <th className="text-sm font-medium capitalize">Status</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="6">
                <div className="flex justify-center items-center h-64">
                  <LoadingSpinner />
                </div>
              </td>
            </tr>
          ) : displayedHistory.length > 0 ? (
            displayedHistory.map((credit, index) => (
              <tr key={index} className="h-12">
                <td className="pl-2">{index + 1}</td>
                <td>{datefunc(credit?.date)}</td>
                <td>{credit?.points}</td>
                <td>{credit?.transaction_type}</td>
                <td>{credit?.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No transactions found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {displayedHistory.length < creditHistory.length && (
        <div className="text-center">
          <button
            className="border rounded-md p-2 bg-gray-200"
            onClick={handleLoadMore}
          >
            Load More
          </button>
        </div>
      )}

      {/* Render the BuyCreditsModal */}
      <BuyCreditsModal
        show={showBuyCreditsModal}
        onClose={() => setShowBuyCreditsModal(false)}
      />
    </div>
  );
};

export default WalletHistory;
