import React, { useEffect, useState } from "react";
import InfoIconTooltip from "./InfoIconTooltip";
import { useSearchParams } from "react-router-dom";

const PreviouslyExportedFilter = () => {
	const [searchParam, setSearchParam] = useSearchParams();
	const quaryIsPrevExported = searchParam.get("isPreviousExported");
	const [isChecked, setIsChecked] = useState(quaryIsPrevExported);

	const handleButtonClick = () => {
		// handleMouseOver("isPreviouslyExported");
		if (!isChecked) {
			setSearchParam((searchParams) => {
				searchParams.set("isPreviousExported", true);
				return searchParams;
			});
		} else {
			setSearchParam((searchParams) => {
				searchParams.delete("isPreviousExported");
				return searchParams;
			});
		}
		setIsChecked(!isChecked);
	};

	return (
		<div className="isVerified flex justify-start">
			<div className="relative w-full">
				<button
					onClick={handleButtonClick}
					className="flex items-center w-full justify-between bg-white"
				>
					<span className={`text-[#3D3D3D] flex items-center`}>
						<input
							id="checkid"
							type="checkbox"
							className="rounded-md border-[0.6px] border-[#D9D9D9] mr-2"
							checked={isChecked}
							onChange={handleButtonClick}
						/>
						Exclude Previously Exported
					</span>
					<InfoIconTooltip
						infoContent={
							"Exclude influencers that have been previously exported to CSV, JSON or API."
						}
					/>
				</button>
			</div>
		</div>
	);
};

export default PreviouslyExportedFilter;
