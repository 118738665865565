import { SET_LASTPOST } from "../ActionTypes";

const initialState = [];

const LastPostReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LASTPOST:
            console.log("last post", action.payload);
            return action.payload;
        default:
            return state;
    }
};

export default LastPostReducer