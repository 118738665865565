import React from "react";
import { Modal } from "flowbite-react";

const CreditDeductionsModal = ({ show, onClose }) => {
  return (
    <Modal show={show} onClose={onClose} size="lg">
      <Modal.Header>Credit Deductions Chart</Modal.Header>
      <Modal.Body>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse">
            <thead className="bg-gray-50 text-gray-600">
              <tr>
                <th className="px-6 py-3 border border-gray-200 text-center">
                  Feature
                </th>
                <th className="px-6 py-3 border border-gray-200 text-center">
                  Action
                </th>
                <th className="px-6 py-3 border border-gray-200 text-center">
                  Credits Deducted
                </th>
              </tr>
            </thead>
            <tbody className="bg-white text-gray-700">
              <tr>
                <td
                  className="px-6 py-4 border border-gray-200 text-center"
                  rowSpan="3"
                  style={{ backgroundColor: "#e9f4ff" }}
                >
                  Influencer Discovery
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  Load More
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  0.2 Credits
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  View Profile
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  3 Credits
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  Exports (with contact)
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  0.5 Credits
                </td>
              </tr>
              <tr>
                <td
                  className="px-6 py-4 border border-gray-200 text-center"
                  rowSpan="1"
                  style={{ backgroundColor: "#e9f4ff" }}
                >
                  Plans
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  Plan Creation
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  2 Credits
                </td>
              </tr>
              <tr>
                <td
                  className="px-6 py-4 border border-gray-200 text-center"
                  rowSpan="2"
                  style={{ backgroundColor: "#e9f4ff" }}
                >
                  Reports
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  Report Creation
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  8 Credits
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  Refresh
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  10 Credits
                </td>
              </tr>
              <tr>
                <td
                  className="px-6 py-4 border border-gray-200 text-center"
                  rowSpan="1"
                  style={{ backgroundColor: "#e9f4ff" }}
                >
                  ER Calculator
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  #Reports
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  10 Credits + 5 + 5
                </td>
              </tr>
              <tr>
                <td
                  className="px-6 py-4 border border-gray-200 text-center"
                  rowSpan="1"
                  style={{ backgroundColor: "#e9f4ff" }}
                >
                  Similar Creators
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  Download
                </td>
                <td className="px-6 py-4 border border-gray-200 text-center">
                  5 Credits for 50
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreditDeductionsModal;
