// redux/blinkReducer.js

const initialState = false;

const blinkReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_BLINK":
			return true; // Start the blinking effect
		case "RESET_BLINK":
			return false; // Stop the blinking effect
		default:
			return state;
	}
};

// Action creators
export const setBlink = () => ({
	type: "SET_BLINK",
});

export const resetBlink = () => ({
	type: "RESET_BLINK",
});

export default blinkReducer;
