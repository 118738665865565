import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
import { Modal } from "flowbite-react";
import OTPInput from "react-otp-input";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../interseptor";
import { updateData } from "./Encryption";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import {
	setCreatorLocationFilter,
	setEngRateFilter,
	setMostRecentPostFilter,
	setPlatformFilter,
} from "../../redux/filters/actions";
const SIGNUP_URL = "/livesearch/signup";
const OTP_VERIFICATION_URL = "/livesearch/login_send_otp";

function SignUp({ setToken }) {

//default instagram
    const dispatch = useDispatch();
        const selectedData = useSelector((state) => ({
            creatorLocation: state.creatorLocation,
            mostRecentPost: state.mostRecentPost,
            engagementRate: state.engagementRate,
            selectedPlatform: state.platform,
        }));
    
        const { creatorLocation, mostRecentPost, engagementRate, selectedPlatform } =
            selectedData;
  // Form state
  const initialFormData = {
    workEmail: "",
    firstName: "",
    lastName: "",
    companyName: "",
    phoneNumber: "",
    countryCode: "+91",
    userType: "",
    password: "",
    confirmPassword: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const PHONE_VALIDATION_RULES = {
    "+91": { // India
      minLength: 10,
      maxLength: 10,
      pattern: /^\d{10}$/,
      errorMessage: "Indian phone numbers must be exactly 10 digits"
    },
    "+1": { // USA
      minLength: 10,
      maxLength: 10,
      pattern: /^\d{10}$/,
      errorMessage: "US phone numbers must be exactly 10 digits"
    },
    "+44": { // UK
      minLength: 10,
      maxLength: 11,
      pattern: /^\d{10,11}$/,
      errorMessage: "UK phone numbers must be 10 or 11 digits"
    },
    "default": {
      minLength: 6,
      maxLength: 15,
      pattern: /^\d{6,15}$/,
      errorMessage: "Phone number must be between 6 and 15 digits"
    }
  };
  const validatePhoneNumber = (phoneNumber, countryCode) => {
    const rules = PHONE_VALIDATION_RULES[countryCode] || PHONE_VALIDATION_RULES.default;
    
    // Remove any non-digit characters
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    if (!rules.pattern.test(cleanNumber)) {
      toast.error(rules.errorMessage);
      return false;
    }

    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "phoneNumber") {
      // Only allow digits
      const numbersOnly = value.replace(/\D/g, '');
      
      // Get validation rules for current country code
      const rules = PHONE_VALIDATION_RULES[formData.countryCode] || PHONE_VALIDATION_RULES.default;
      
      // Limit input length based on country rules
      if (numbersOnly.length <= rules.maxLength) {
        setFormData(prev => ({
          ...prev,
          [name]: numbersOnly
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const disallowedDomains = [
    "gmail.com", "yahoo.com", "hotmail.com", "outlook.com", "live.com",
    "icloud.com", "aol.com", "mail.com", "zoho.com", "yandex.com", 
    "protonmail.com", "gmx.com", "me.com", "msn.com", "comcast.net", 
    "sbcglobal.net", "verizon.net", "att.net", "mac.com", "cox.net", 
    "rocketmail.com", "qq.com", "rediffmail.com", "163.com", "126.com", 
    "yeah.net", "naver.com", "hanmail.net", "daum.net", "tutanota.com", 
    "pm.me", "fastmail.com"
    // Add more as necessary
  ];

  const countryCodes = [
    { value: "+91", label: "+91 (India)" },
	{ value: "+1", label: "+1 (USA)" },
	{ value: "+7", label: "+7 (Russia, Kazakhstan)" },
	{ value: "+20", label: "+20 (Egypt)" },
	{ value: "+27", label: "+27 (South Africa)" },
	{ value: "+30", label: "+30 (Greece)" },
	{ value: "+31", label: "+31 (Netherlands)" },
	{ value: "+32", label: "+32 (Belgium)" },
	{ value: "+33", label: "+33 (France)" },
	{ value: "+34", label: "+34 (Spain)" },
	{ value: "+36", label: "+36 (Hungary)" },
	{ value: "+39", label: "+39 (Italy)" },
	{ value: "+40", label: "+40 (Romania)" },
	{ value: "+41", label: "+41 (Switzerland)" },
	{ value: "+43", label: "+43 (Austria)" },
	{ value: "+44", label: "+44 (UK)" },
	{ value: "+45", label: "+45 (Denmark)" },
	{ value: "+46", label: "+46 (Sweden)" },
	{ value: "+47", label: "+47 (Norway)" },
	{ value: "+48", label: "+48 (Poland)" },
	{ value: "+49", label: "+49 (Germany)" },
	{ value: "+51", label: "+51 (Peru)" },
	{ value: "+52", label: "+52 (Mexico)" },
	{ value: "+53", label: "+53 (Cuba)" },
	{ value: "+54", label: "+54 (Argentina)" },
	{ value: "+55", label: "+55 (Brazil)" },
	{ value: "+56", label: "+56 (Chile)" },
	{ value: "+57", label: "+57 (Colombia)" },
	{ value: "+58", label: "+58 (Venezuela)" },
	{ value: "+60", label: "+60 (Malaysia)" },
	{ value: "+61", label: "+61 (Australia)" },
	{ value: "+62", label: "+62 (Indonesia)" },
	{ value: "+63", label: "+63 (Philippines)" },
	{ value: "+64", label: "+64 (New Zealand)" },
	{ value: "+65", label: "+65 (Singapore)" },
	{ value: "+66", label: "+66 (Thailand)" },
	{ value: "+81", label: "+81 (Japan)" },
	{ value: "+82", label: "+82 (South Korea)" },
	{ value: "+84", label: "+84 (Vietnam)" },
	{ value: "+86", label: "+86 (China)" },
	{ value: "+90", label: "+90 (Turkey)" },
	{ value: "+92", label: "+92 (Pakistan)" },
	{ value: "+93", label: "+93 (Afghanistan)" },
	{ value: "+94", label: "+94 (Sri Lanka)" },
	{ value: "+95", label: "+95 (Myanmar)" },
	{ value: "+98", label: "+98 (Iran)" },
	{ value: "+212", label: "+212 (Morocco)" },
	{ value: "+213", label: "+213 (Algeria)" },
	{ value: "+216", label: "+216 (Tunisia)" },
	{ value: "+218", label: "+218 (Libya)" },
	{ value: "+220", label: "+220 (Gambia)" },
	{ value: "+221", label: "+221 (Senegal)" },
	{ value: "+222", label: "+222 (Mauritania)" },
	{ value: "+223", label: "+223 (Mali)" },
	{ value: "+224", label: "+224 (Guinea)" },
	{ value: "+225", label: "+225 (Ivory Coast)" },
	{ value: "+226", label: "+226 (Burkina Faso)" },
	{ value: "+227", label: "+227 (Niger)" },
	{ value: "+228", label: "+228 (Togo)" },
	{ value: "+229", label: "+229 (Benin)" },
	{ value: "+230", label: "+230 (Mauritius)" },
	{ value: "+231", label: "+231 (Liberia)" },
	{ value: "+232", label: "+232 (Sierra Leone)" },
	{ value: "+233", label: "+233 (Ghana)" },
	{ value: "+234", label: "+234 (Nigeria)" },
	{ value: "+235", label: "+235 (Chad)" },
	{ value: "+236", label: "+236 (Central African Republic)" },
	{ value: "+237", label: "+237 (Cameroon)" },
	{ value: "+238", label: "+238 (Cape Verde)" },
	{ value: "+239", label: "+239 (São Tomé and Príncipe)" },
	{ value: "+240", label: "+240 (Equatorial Guinea)" },

    // Add the rest of the country codes here
  ];

  // UI state
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [rememberChecked, setRememberChecked] = useState(false);
  
  // Authentication state
  const [loggedIn, setLoggedIn] = useState(false);
//   const [token, setLocalToken] = useState(localStorage.getItem("token"));
//   const [redirectToHome, setRedirectToHome] = useState(false);
  const token = localStorage.getItem("token_data");

  
  // OTP state
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [otpSent, setOtpSent] = useState(false);
    const [updated, setUpdated] = useState(false);


  useEffect(() => {
    // console.log("Form data changed:", formData);
  }, [formData]);
  // Timer for OTP countdown
  useEffect(() => {
    let timer;
    if (seconds > 0 && otpSent) {
      timer = setInterval(() => setSeconds(prev => prev - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [seconds, otpSent]);

  // Function to add email to cookie for remember me functionality
  const addUniqueEmail = (email) => {
    const getCookie = (name) => {
      const cookieArr = document.cookie.split(";");
      for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if (name === cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1]);
        }
      }
      return null;
    };

    const existingEmails = getCookie("emails") ? JSON.parse(getCookie("emails")) : [];
    existingEmails.push(email);
    
    const expirationDate = rememberChecked 
      ? new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)  // 7 days
      : new Date(Date.now() + 24 * 60 * 60 * 1000);     // 1 day

    document.cookie = `emails=${JSON.stringify(existingEmails)}; expires=${expirationDate.toUTCString()}; path=/`;
  };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//   };


//   // Function to validate if email has a disallowed domain
//   const isEmailDisallowed = (email) => {
//     const domain = email.split("@")[1]?.toLowerCase().trim();
//     const isDisallowed = disallowedDomains.includes(domain);
//     console.log(isDisallowed, domain);  // Log the domain and if it is disallowed
//     return isDisallowed;
//   };
   // Extract domain from email
//    const emailDomain = formData.workEmail.split("@")[1]?.toLowerCase().trim();
//    if (disallowedDomains.includes(emailDomain)) {
//      toast.error("This email domain is not allowed.");
//      return;
//    }
 // Function to validate if email has a disallowed domain
 const isEmailDisallowed = (email) => {
    const domain = email.split("@")[1]?.toLowerCase().trim();
    return disallowedDomains.includes(domain);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    // console.log('Form submitted');  // Check if form is being submitted
    if (isEmailDisallowed(formData.workEmail)) {
        toast.error("This email domain is not allowed.");
        return;
      }

      if (!/^[A-Za-z\s]+$/.test(formData.firstName)) {
        toast.error("First Name can only contain alphabets and spaces.");
        return;
      }
      if (!/^[A-Za-z\s]+$/.test(formData.lastName)) {
        toast.error("Last Name can only contain alphabets and spaces.");
        return;
      }
     // Company Name validation
     if (!/^[A-Za-z\s]+$/.test(formData.companyName)) {
        toast.error("Company Name can only contain alphabets and spaces.");
        return;
      }

  // Phone Number validation
  if (!/^\d+$/.test(formData.phoneNumber)) {
    toast.error("Phone number must contain only digits.");
    return;
  }
   // Phone number validation
   if (!validatePhoneNumber(formData.phoneNumber, formData.countryCode)) {
    return;
  }

  // Password validation
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (!strongPasswordRegex.test(formData.password)) {
    toast.error(
      "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character."
    );
    return;
  }

  // Confirm Password validation
  if (formData.password !== formData.confirmPassword) {
    toast.error("Passwords do not match.");
    return;
  }
//   toast.success("Form submitted successfully!");
//   console.log("Form data:", formData);

    try {
      const signupFormData = new FormData();
      signupFormData.append("logintype", "sign_up");
      signupFormData.append("device_id", "search");
      signupFormData.append("work_email", formData.workEmail);
      signupFormData.append("first_name", formData.firstName);
      signupFormData.append("last_name", formData.lastName);
      signupFormData.append("company_name", formData.companyName);
      signupFormData.append("phone_number", formData.phoneNumber);
      signupFormData.append("user_type", formData.userType);
      signupFormData.append("password", formData.password);
      signupFormData.append("confirm_password", formData.confirmPassword);
    //   Object.keys(formData).forEach(key => {
    //     signupFormData.append(key, formData[key]);
    //   });
      const response = await axiosInstance.post(SIGNUP_URL, signupFormData);
      
      if (response.data.status === 1) {
        toast.success("Registration successful! Please verify OTP.");
        setOtpSent(true);
         setLoggedIn(true);
         setSeconds(60);
        } else {
          toast.error(response.data.msg || "Email Already exists");
        }
      } catch (error) {
        console.error("Signup error:", error);
        toast.error(error.response?.data?.msg || "An error occurred during signup");
      }
    };
       // setSeconds(60);
//         await sendOtp();
//       } else {
//         toast.error(response.data.msg || "Email Already exists");
//       }
//     } catch (error) {
//       toast.error(error.response?.data?.msg || "An error occurred during signup");
//     }
//   };

//   const sendOtp = async () => {
//     try {
//       const otpFormData = new FormData();
//       otpFormData.append("logintype", "verify_otp_sign_up");
//       otpFormData.append("device_id", "search");
//       otpFormData.append("email", formData.workEmail);

//       const response = await axiosInstance.post(OTP_VERIFICATION_URL, otpFormData);
      
//       if (response.data.status === 1) {
//         toast.success("OTP sent successfully");
//         setSeconds(60);
//         setOtpSent(true);
//       } else {
//         toast.error(response.data.msg || "Failed to send OTP");
//       }
//     } catch (error) {
//       toast.error("Failed to send OTP");
//     }
//   };
const resendOtp = async () => {
    try {
      const otpFormData = new FormData();
      otpFormData.append("logintype", "verify_otp_sign_up");
      otpFormData.append("device_id", "search");
      otpFormData.append("email", formData.workEmail);

      const response = await axiosInstance.post(OTP_VERIFICATION_URL, otpFormData);
      
      if (response.data.status === 1) {
        toast.success("OTP resent successfully");
        setSeconds(60);
      } else {
        toast.error(response.data.msg || "Failed to resend OTP");
      }
    } catch (error) {
      console.error("OTP resend error:", error);
      toast.error("Failed to resend OTP");
    }
  };
  const handleOtpVerification = async () => {
    try {
      const otpFormData = new FormData();
      otpFormData.append("logintype", "verify_otp_sign_up");
      otpFormData.append("email", formData.workEmail);
      otpFormData.append("otp", otp);
      otpFormData.append("device_id", "search");

      const response = await axiosInstance.post(OTP_VERIFICATION_URL, otpFormData);

      if (response.data.status === 1) {
        const { token_data, rights_navigation, rights_action } = response.data;
        
        // Save token
        localStorage.setItem("token", token_data.token);
        
        // Save navigation rights
        if (rights_navigation) {
          updateData("rights_navigation", rights_navigation);
          updateData("rights_action", JSON.stringify(rights_action));
        }
        
        // Update parent component token
        setToken(response.data);
        
        // Save email if remember me is checked
        if (rememberChecked) {
          addUniqueEmail(formData.workEmail);
        }

        toast.success("SignUp successful!");
        setLoggedIn(false);
        // setRedirectToHome(true);
        setUpdated(!updated);
      } else {
        toast.error(response.data.msg || "Invalid OTP");
      }
    } catch (error) {
      toast.error(error.response?.data?.msg || "OTP verification failed");
    }
  };

    useEffect(() => {
        let timer;
        if (loggedIn && seconds > 0) {
            dispatch(setPlatformFilter("Instagram"));
            dispatch(setCreatorLocationFilter(["304716-k-India"]));
            dispatch(setMostRecentPostFilter([1]));
            dispatch(setEngRateFilter([0.01]));
            timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            // setLoggedIn(false); // Automatically close modal when timer reaches 0
            // You can add additional actions when the timer reaches 0
        }

        return () => clearInterval(timer); // Clear interval when component unmounts or when loggedIn changes
    }, [loggedIn, seconds, dispatch]);
    const queryString = `?creatorLocation=304716-k-India&mostRecentPost=1&eng_rate=0.01&platform=Instagram`;

//  if (redirectToHome || token) {
//     return <Navigate to="/home" />;
//   }

  return (
    <>
    {token ? (
                    <Navigate to={`/home${queryString}`}></Navigate>
                ) : (
    <div className=" bg-white">
 <div className="container mx-auto mt-24  sm:px-6 lg:px-20">
  {/* <div className="max-w-8xl mx-auto"> */}
    <div className="grid lg:grid-cols-2 gap-12 items-start ">
      {/* Left Column */}
      <div className="space-y-8 ">
        
        <h1 className="text-4xl md:text-5xl font-bold leading-tight  ml-4 sm:ml-2 md:ml-0 lg:ml-0 ">
        Unlock the Power of <br />
        Influencer Discovery 
        </h1>
        <p className="text-gray-600 text-base sm:text-lg leading-relaxed ml-4 sm:ml-2 md:ml-0 lg:ml-0">
        Join Viral Pitch and access tools to find, analyze, and connect with the perfect influencers for your campaigns. Signing up is quick, easy, and completely free!
        </p>
        <h2 className="text-2xl font-semibold mb-4 ml-4 sm:ml-2 md:ml-0 lg:ml-0">Why Sign Up for the Discovery Platform?</h2>
        <ul className="space-y-4 ml-4 sm:ml-2 md:ml-0 lg:ml-0">
  {[
    "Find Influencers Instantly: Use powerful search and filters to discover influencers that fit your goals.",
    "Boost Campaign Performance: Gain insights and analytics to make data-driven decisions.",
    "Simplify Collaboration: Manage your influencer campaigns efficiently from one platform.",
    "No Commitment Required: Explore all the features for free.",
  ].map((item, index) => {
    const [boldText, ...rest] = item.split(":");
    return (
      <li key={index} className="flex items-start space-x-3">
        <span className="text-red-500">✔</span>
        <span className="text-gray-700">
          <strong className="font-extrabold text-lg">{boldText || item}</strong>{rest.length > 0 ? `: ${rest.join(":")}` : ""}
        </span>
      </li>
    );
  })}
</ul>

<div className="flex flex-wrap gap-6 ml-4 sm:ml-2 md:ml-0 lg:ml-0">
  {[
    "/asset/award-1.png",
    "/asset/award-2.png",
    "/asset/ICMA.png",
    "/asset/ICMA-1.png",
    "/asset/influencer-awards.png",
  ].map((src, index) => (
    <img
      key={index}
      src={src}
      alt={`Award ${index + 1}`}
      className="h-24 w-32 rounded-lg object-contain"
    />
  ))}
</div> 

          
          
</div>

      {/* Right Column */}
      <div
  className="rounded-lg shadow-lg p-6 md:p-8 px-4"
  style={{ backgroundColor: '#fff6f6' }}
>        <h2 className="text-3xl font-bold mb-6 text-gray-800">Signup to Get Started</h2>
        <form className="space-y-6" onSubmit={handleSignUp}>
       
          <input
            type="email"
            name="workEmail"
            placeholder="Work Email"
            value={formData.workEmail}
            onChange={handleInputChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-sm focus:ring-red-200 focus:border-red-200"
            required
          />
          <div className="grid grid-cols-2 gap-4">
            <input
               type="text"
               name="firstName"
               placeholder="First Name"
               value={formData.firstName}
               onChange={handleInputChange}
              className="px-4 py-3 border border-gray-300 rounded-sm focus:ring-red-200 focus:border-red-200"
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
              className="px-4 py-3 border border-gray-300 rounded-sm focus:ring-red-200 focus:border-red-200"
              required
            />
          </div>
          <input
            type="text"
            name="companyName"
            placeholder="Company Name"
            value={formData.companyName}
            onChange={handleInputChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-sm focus:ring-red-200 focus:border-red-200"
            required
          />
          {/* <input
            type="text"
            name="jobTitle"
            placeholder="Job Title"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-red-500 focus:border-red-500"
            required
          /> */}
          <div className="flex space-x-4">
            <select
                name="countryCode"
                value={formData.countryCode}
                onChange={handleInputChange}
              className="px-2 py-3 border border-gray-300 rounded-sm focus:ring-red-200 focus:border-red-200 w-32"
              required
            >
              {/* Add your country codes here */}
              {countryCodes.map((code) => (
                <option key={code.value} value={code.value}>
                  {code.label}
                </option>
              ))}
            </select>
            <input
               type="tel"
               name="phoneNumber"
            //    placeholder="Enter 10-digit number"
 placeholder={`Enter ${PHONE_VALIDATION_RULES[formData.countryCode]?.minLength || '6-15'} digit number`}
               value={formData.phoneNumber}
               onChange={handleInputChange}
              className="flex-grow px-4 py-3 border border-gray-300 rounded-sm focus:ring-red-200 focus:border-red-200"
              required
            />
          </div>
          <div className="space-y-2">
            <p className="text-gray-700 font-medium">What best describes you?</p>
            <div className="flex items-center space-x-4">
              <label className="flex items-center space-x-2">
                <input  type="radio"
                  name="userType"
                  value="1"
                  checked={formData.userType === "1"}
                  onChange={handleInputChange}
                   required />
                <span>Brand</span>
              </label>
              <label className="flex items-center space-x-2">
                <input  type="radio"
                  name="userType"
                  value="2"
                  checked={formData.userType === "2"}
                  onChange={handleInputChange} />
                <span>Agency</span>
              </label>
              {/* <label className="flex items-center space-x-2">
                <input type="radio" name="userType" value="Creator" />
                <span>Creator</span>
              </label> */}
            </div>
          </div>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              className="w-full  px-4 py-3 border border-gray-300 rounded-sm focus:ring-red-200 focus:border-red-200"
              required
            />
            <button
              type="button"
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "HIDE" : "SHOW"}
            </button>
          </div>
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              className="w-full  px-4 py-3 border border-gray-300 rounded-sm focus:ring-red-200 focus:border-red-200"
              required
            />
            <button
              type="button"
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? "HIDE" : "SHOW"}
            </button>
          </div>
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={rememberChecked}
              onChange={(e) => setRememberChecked(e.target.checked)}
              className="w-4 h-4 border border-gray-300 rounded-sm focus:ring-red-200 focus:border-red-200"
              required
            />
            <p className="text-sm text-gray-700">
              I agree to the{" "}
              <a className="text-red-500 hover:underline">terms and conditions</a>
            </p>
          </div>
          <button
         type="submit"
            className="w-full py-3 bg-red-500 text-white font-semibold rounded-2xl hover:bg-black transition"
          >
           Get Started for Free<span className="pl-2">➤</span>
          </button>
          <p className="text-center text-sm text-gray-600">
            Already Registered?{" "}
            <Link to="/login" className="text-red-500 hover:underline">
              Login
            </Link>
          </p>
        </form>
      </div>
    </div>
  </div>



      {/* OTP Modal */}
      <Modal show={loggedIn} onClose={() => setLoggedIn(false)}>
        <Modal.Header>OTP Verification</Modal.Header>
        <Modal.Body className="flex flex-col justify-center">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            containerStyle={{ display: "flex", justifyContent: "center" }}
            renderSeparator={<span className="mr-2"></span>}
            inputStyle={{ width: "50px", borderRadius: "20px" }}
            renderInput={(props) => (
              <input 
                className="w-[50px] h-[50px] text-center border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500" 
                {...props} 
              />
            )}
          />
          {seconds === 0 ? (
            <p 
              onClick={resendOtp} 
              className="text-center mt-4 text-blue-500 hover:text-blue-600 cursor-pointer underline"
            >
              Didn't receive code? Resend OTP
            </p>
          ) : (
            <p className="text-center mt-4 text-gray-600">
              Resend OTP in {seconds} seconds
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="flex justify-center">
          <button
            className="bg-[#686FFD] text-white px-6 py-2.5 rounded-lg hover:bg-blue-600 transition duration-200"
            onClick={handleOtpVerification}
            disabled={otp.length !== 6}
          >
            Verify OTP
          </button>
          <button
            className="ml-4 px-6 py-2.5 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition duration-200"
            onClick={() => setLoggedIn(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
                )}
    {/* </div> */}
    <ToastContainer />
   
    </>
  );
}


SignUp.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default SignUp;




// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { Link, Navigate } from "react-router-dom";
// import { Modal } from "flowbite-react";
// import OTPInput from "react-otp-input";
// import { toast, ToastContainer } from "react-toastify";
// import axiosInstance from "../../interseptor";
// import { updateData } from "./Encryption";
// import { useFormValidation, validateWorkEmail, validatePassword, validatePhoneNumber } from './FormValidation';

// const SIGNUP_URL = "/livesearch/signup";
// const OTP_VERIFICATION_URL = "/livesearch/login_send_otp";

// function SignUp({ setToken }) {
//   // Form state
//   const [formData, setFormData] = useState({
//     workEmail: "",
//     firstName: "",
//     lastName: "",
//     companyName: "",
//     phoneNumber: "",
//     countryCode: "+91",
//     userType: "",
//     password: "",
//     confirmPassword: "",
//   });


//   const validateForm = (values) => {
//     const errors = {};
//      // Email validation
//      const emailError = validateWorkEmail(values.workEmail);
//      if (emailError) errors.workEmail = emailError;
 
//      // Name validation
//      if (!values.firstName.trim()) errors.firstName = 'First name is required';
//      if (!values.lastName.trim()) errors.lastName = 'Last name is required';
     
//      // Company validation
//      if (!values.companyName.trim()) errors.companyName = 'Company name is required';
     
//      // Phone validation
//      const phoneError = validatePhoneNumber(values.phoneNumber);
//      if (phoneError) errors.phoneNumber = phoneError;
     
//      // User type validation
//      if (!values.userType) errors.userType = 'Please select your user type';
     
//      // Password validation
//      const passwordErrors = validatePassword(values.password);
//      if (passwordErrors.length > 0) errors.password = passwordErrors;
     
//      if (values.password !== values.confirmPassword) {
//        errors.confirmPassword = 'Passwords do not match';
//      }
 
//      return errors;
//    };
//    const {
//     handleChange,
//     handleSubmit,
//     values,
//     errors
//   } = useFormValidation(initialState, validateForm);
//   // UI state
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [rememberChecked, setRememberChecked] = useState(false);
  
//   // Authentication state
//   const [loggedIn, setLoggedIn] = useState(false);
//   const [token, setLocalToken] = useState(localStorage.getItem("token"));
//   const [redirectToHome, setRedirectToHome] = useState(false);
  
//   // OTP state
//   const [otp, setOtp] = useState("");
//   const [seconds, setSeconds] = useState(60);
//   const [otpSent, setOtpSent] = useState(false);

//   // Timer for OTP countdown
//   useEffect(() => {
//     let timer;
//     if (seconds > 0 && otpSent) {
//       timer = setInterval(() => setSeconds(prev => prev - 1), 1000);
//     }
//     return () => clearInterval(timer);
//   }, [seconds, otpSent]);

//   // Function to add email to cookie for remember me functionality
//   const addUniqueEmail = (email) => {
//     const getCookie = (name) => {
//       const cookieArr = document.cookie.split(";");
//       for (let i = 0; i < cookieArr.length; i++) {
//         const cookiePair = cookieArr[i].split("=");
//         if (name === cookiePair[0].trim()) {
//           return decodeURIComponent(cookiePair[1]);
//         }
//       }
//       return null;
//     };

//     const existingEmails = getCookie("emails") ? JSON.parse(getCookie("emails")) : [];
//     existingEmails.push(email);
    
//     const expirationDate = rememberChecked 
//       ? new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)  // 7 days
//       : new Date(Date.now() + 24 * 60 * 60 * 1000);     // 1 day

//     document.cookie = `emails=${JSON.stringify(existingEmails)}; expires=${expirationDate.toUTCString()}; path=/`;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//   };

//   const handleSignUp = async (e) => {
//     e.preventDefault();
    
//     if (formData.password !== formData.confirmPassword) {
//       toast.error("Passwords do not match");
//       return;
//     }

//     try {
//       const signupFormData = new FormData();
//       signupFormData.append("logintype", "sign_up");
//       signupFormData.append("device_id", "search");
//       signupFormData.append("work_email", formData.workEmail);
//       signupFormData.append("first_name", formData.firstName);
//       signupFormData.append("last_name", formData.lastName);
//       signupFormData.append("company_name", formData.companyName);
//       signupFormData.append("phone_number", formData.phoneNumber);
//       signupFormData.append("user_type", formData.userType);
//       signupFormData.append("password", formData.password);
//       signupFormData.append("confirm_password", formData.confirmPassword);

//       const response = await axiosInstance.post(SIGNUP_URL, signupFormData);
      
//       if (response.data.status === 1) {
//         toast.success("Registration successful! Please verify OTP.");
//         setOtpSent(true);
//         setLoggedIn(true);
//         setSeconds(60);
//         await sendOtp();
//       } else {
//         toast.error(response.data.msg || "Registration failed");
//       }
//     } catch (error) {
//       toast.error(error.response?.data?.msg || "An error occurred during signup");
//     }
//   };

//   const sendOtp = async () => {
//     try {
//       const otpFormData = new FormData();
//       otpFormData.append("logintype", "verify_otp_sign_up");
//       otpFormData.append("device_id", "search");
//       otpFormData.append("email", formData.workEmail);

//       const response = await axiosInstance.post(OTP_VERIFICATION_URL, otpFormData);
      
//       if (response.data.status === 1) {
//         toast.success("OTP sent successfully");
//         setSeconds(60);
//         setOtpSent(true);
//       } else {
//         toast.error(response.data.msg || "Failed to send OTP");
//       }
//     } catch (error) {
//       toast.error("Failed to send OTP");
//     }
//   };

//   const handleOtpVerification = async () => {
//     try {
//       const otpFormData = new FormData();
//       otpFormData.append("logintype", "verify_otp_sign_up");
//       otpFormData.append("email", formData.workEmail);
//       otpFormData.append("otp", otp);
//       otpFormData.append("device_id", "search");

//       const response = await axiosInstance.post(OTP_VERIFICATION_URL, otpFormData);

//       if (response.data.status === 1) {
//         const { token_data, rights_navigation, rights_action } = response.data;
        
//         // Save token
//         localStorage.setItem("token", token_data.token);
        
//         // Save navigation rights
//         if (rights_navigation) {
//           updateData("rights_navigation", rights_navigation);
//           updateData("rights_action", JSON.stringify(rights_action));
//         }
        
//         // Update parent component token
//         setToken(response.data);
        
//         // Save email if remember me is checked
//         if (rememberChecked) {
//           addUniqueEmail(formData.workEmail);
//         }

//         toast.success("SignUp successful!");
//         setLoggedIn(false);
//         setRedirectToHome(true);
//       } else {
//         toast.error(response.data.msg || "Invalid OTP");
//       }
//     } catch (error) {
//       toast.error(error.response?.data?.msg || "OTP verification failed");
//     }
//   };

//   if (redirectToHome || token) {
//     return <Navigate to="/home" />;
//   }

//   return (
//     <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 px-4 py-8 md:py-12">
//       <div className="max-w-6xl mx-auto grid lg:grid-cols-2 gap-8 items-start">
//         {/* Left Column */}
//         <div className="space-y-8 lg:sticky lg:top-8">
//           <h1 className="text-4xl md:text-5xl font-bold leading-tight bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
//             Schedule A Customized<br />
//             Consultation With An Expert
//           </h1>
//           <p className="text-gray-600 text-lg md:text-xl leading-relaxed">
//             Discover how Viral Pitch can elevate your influencer marketing strategy to new heights.
//           </p>
//           <ul className="space-y-4 text-gray-700 text-lg">
//             {[
//               "Request a free influencer marketing demo",
//               "Learn how we assist in identifying the perfect influencers",
//               "Discover strategies to elevate your brand's presence",
//               "Have all your burning questions answered!",
//             ].map((item, index) => (
//               <li key={index} className="flex items-start space-x-2">
//                 <span className="text-red-500 font-bold">✓</span>
//                 <span>{item}</span>
//               </li>
//             ))}
//           </ul>
//         </div>

//         {/* Right Column */}
//         <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8">
//           <h2 className="text-3xl font-bold mb-6 text-gray-800">Get a Demo</h2>
//           <form className="space-y-6" onSubmit={handleSignUp}>
//             <input
//               type="email"
//               name="workEmail"
//               placeholder="Work Email"
//               value={formData.workEmail}
//               onChange={handleInputChange}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//               required
//             />

//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//               <input
//                 type="text"
//                 name="firstName"
//                 placeholder="First Name"
//                 value={formData.firstName}
//                 onChange={handleInputChange}
//                 className="px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//               <input
//                 type="text"
//                 name="lastName"
//                 placeholder="Last Name"
//                 value={formData.lastName}
//                 onChange={handleInputChange}
//                 className="px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//             </div>

//             <input
//               type="text"
//               name="companyName"
//               placeholder="Company Name"
//               value={formData.companyName}
//               onChange={handleInputChange}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//               required
//             />

//             <div className="flex space-x-4">
//               <select
//                 name="countryCode"
//                 value={formData.countryCode}
//                 onChange={handleInputChange}
//                 className="px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               >
//                 <option value="+91">+91 (India)</option>
//                 <option value="+1">+1 (USA)</option>
//                 <option value="+44">+44 (UK)</option>
//               </select>
//               <input
//                 type="tel"
//                 name="phoneNumber"
//                 placeholder="Enter 10-digit number"
//                 value={formData.phoneNumber}
//                 onChange={handleInputChange}
//                 className="flex-grow px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//             </div>

//             <div className="space-y-2">
//               <p className="text-gray-700 font-medium">What best describes you?</p>
//               <div className="flex items-center space-x-4">
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="radio"
//                     name="userType"
//                     value="1"
//                     checked={formData.userType === "1"}
//                     onChange={handleInputChange}
//                     required
//                   />
//                   <span>Brand</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="radio"
//                     name="userType"
//                     value="2"
//                     checked={formData.userType === "2"}
//                     onChange={handleInputChange}
//                   />
//                   <span>Agency</span>
//                 </label>
//               </div>
//             </div>

//             <div className="relative">
//               <input
//                 type={showPassword ? "text" : "password"}
//                 name="password"
//                 placeholder="Password"
//                 value={formData.password}
//                 onChange={handleInputChange}
//                 className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//               <button
//                 type="button"
//                 className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
//                 onClick={() => setShowPassword(!showPassword)}
//               >
//                 {showPassword ? "🙈" : "👁️"}
//               </button>
//             </div>

//             <div className="relative">
//               <input
//                 type={showConfirmPassword ? "text" : "password"}
//                 name="confirmPassword"
//                 placeholder="Confirm Password"
//                 value={formData.confirmPassword}
//                 onChange={handleInputChange}
//                 className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//               <button
//                 type="button"
//                 className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
//                 onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//               >
//                 {showConfirmPassword ? "🙈" : "👁️"}
//               </button>
//             </div>

//             <div className="flex items-center space-x-2">
//               <input
//                 type="checkbox"
//                 checked={rememberChecked}
//                 onChange={(e) => setRememberChecked(e.target.checked)}
//                 className="w-4 h-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
//                 required
//               />
//               <p className="text-sm text-gray-700">
//                 I agree to the{" "}
//                 <a href="#" className="text-blue-500 hover:underline">
//                   terms and conditions
//                 </a>
//               </p>
//             </div>

//             <button
//               type="submit"
//               className="w-full py-3 bg-red-500 text-white font-medium rounded-lg hover:bg-red-600 transition"
//             >
//               Submit
//             </button>
            
//             <p className="text-center text-sm text-gray-600">
//               Already Registered?{" "}
//               <Link to="/login" className="text-blue-500 hover:underline">
//                 Login
//               </Link>
//             </p>
//           </form>
//         </div>
//       </div>
//       {/* OTP Modal */}
//       <Modal show={loggedIn} onClose={() => setLoggedIn(false)}>
//         <Modal.Header>OTP Verification</Modal.Header>
//         <Modal.Body className="flex flex-col justify-center">
//           <OTPInput
//             value={otp}
//             onChange={setOtp}
//             numInputs={6}
//             containerStyle={{ display: "flex", justifyContent: "center" }}
//             renderSeparator={<span className="mr-2"></span>}
//             inputStyle={{ width: "50px", borderRadius: "20px" }}
//             renderInput={(props) => (
//               <input 
//                 className="w-[50px] h-[50px] text-center border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500" 
//                 {...props} 
//               />
//             )}
//           />
//           {seconds === 0 ? (
//             <p 
//               onClick={sendOtp} 
//               className="text-center mt-4 text-blue-500 hover:text-blue-600 cursor-pointer underline"
//             >
//               Didn't receive code? Resend OTP
//             </p>
//           ) : (
//             <p className="text-center mt-4 text-gray-600">
//               Resend OTP in {seconds} seconds
//             </p>
//           )}
//         </Modal.Body>
//         <Modal.Footer className="flex justify-center">
//           <button
//             className="bg-[#686FFD] text-white px-6 py-2.5 rounded-lg hover:bg-blue-600 transition duration-200"
//             onClick={handleOtpVerification}
//             disabled={otp.length !== 6}
//           >
//             Verify OTP
//           </button>
//           <button
//             className="ml-4 px-6 py-2.5 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition duration-200"
//             onClick={() => setLoggedIn(false)}
//           >
//             Cancel
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }
// <ToastContainer />
// SignUp.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };

// export default SignUp;



// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { Link, Navigate } from "react-router-dom";
// import { Modal } from "flowbite-react";
// import OTPInput from "react-otp-input";
// import { toast, ToastContainer } from "react-toastify";
// import axiosInstance from "../../interseptor";
// import { updateData } from "./Encryption";

// const SIGNUP_URL = "/livesearch/signup";
// const OTP_VERIFICATION_URL = "/livesearch/login_send_otp";

// function SignUp({ setToken }) {
//   // Form state
//   const [formData, setFormData] = useState({
//     workEmail: "",
//     firstName: "",
//     lastName: "",
//     companyName: "",
//     phoneNumber: "",
//     countryCode: "+91",
//     userType: "",
//     password: "",
//     confirmPassword: "",
//   });


  
//   // UI state
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [rememberChecked, setRememberChecked] = useState(false);
  
//   // Authentication state
//   const [loggedIn, setLoggedIn] = useState(false);
//   const [token, setLocalToken] = useState(localStorage.getItem("token"));
//   const [redirectToHome, setRedirectToHome] = useState(false);
  
//   // OTP state
//   const [otp, setOtp] = useState("");
//   const [seconds, setSeconds] = useState(60);
//   const [otpSent, setOtpSent] = useState(false);

//   // Timer for OTP countdown
//   useEffect(() => {
//     let timer;
//     if (seconds > 0 && otpSent) {
//       timer = setInterval(() => setSeconds(prev => prev - 1), 1000);
//     }
//     return () => clearInterval(timer);
//   }, [seconds, otpSent]);

//   // Function to add email to cookie for remember me functionality
//   const addUniqueEmail = (email) => {
//     const getCookie = (name) => {
//       const cookieArr = document.cookie.split(";");
//       for (let i = 0; i < cookieArr.length; i++) {
//         const cookiePair = cookieArr[i].split("=");
//         if (name === cookiePair[0].trim()) {
//           return decodeURIComponent(cookiePair[1]);
//         }
//       }
//       return null;
//     };

//     const existingEmails = getCookie("emails") ? JSON.parse(getCookie("emails")) : [];
//     existingEmails.push(email);
    
//     const expirationDate = rememberChecked 
//       ? new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)  // 7 days
//       : new Date(Date.now() + 24 * 60 * 60 * 1000);     // 1 day

//     document.cookie = `emails=${JSON.stringify(existingEmails)}; expires=${expirationDate.toUTCString()}; path=/`;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//   };

//   const handleSignUp = async (e) => {
//     e.preventDefault();
    
//     if (formData.password !== formData.confirmPassword) {
//       toast.error("Passwords do not match");
//       return;
//     }

//     try {
//       const signupFormData = new FormData();
//       signupFormData.append("logintype", "sign_up");
//       signupFormData.append("device_id", "search");
//       signupFormData.append("work_email", formData.workEmail);
//       signupFormData.append("first_name", formData.firstName);
//       signupFormData.append("last_name", formData.lastName);
//       signupFormData.append("company_name", formData.companyName);
//       signupFormData.append("phone_number", formData.phoneNumber);
//       signupFormData.append("user_type", formData.userType);
//       signupFormData.append("password", formData.password);
//       signupFormData.append("confirm_password", formData.confirmPassword);

//       const response = await axiosInstance.post(SIGNUP_URL, signupFormData);
      
//       if (response.data.status === 1) {
//         toast.success("Registration successful! Please verify OTP.");
//         setOtpSent(true);
//         setLoggedIn(true);
//         setSeconds(60);
//         await sendOtp();
//       } else {
//         toast.error(response.data.msg || "Registration failed");
//       }
//     } catch (error) {
//       toast.error(error.response?.data?.msg || "An error occurred during signup");
//     }
//   };

//   const sendOtp = async () => {
//     try {
//       const otpFormData = new FormData();
//       otpFormData.append("logintype", "verify_otp_sign_up");
//       otpFormData.append("device_id", "search");
//       otpFormData.append("email", formData.workEmail);

//       const response = await axiosInstance.post(OTP_VERIFICATION_URL, otpFormData);
      
//       if (response.data.status === 1) {
//         toast.success("OTP sent successfully");
//         setSeconds(60);
//         setOtpSent(true);
//       } else {
//         toast.error(response.data.msg || "Failed to send OTP");
//       }
//     } catch (error) {
//       toast.error("Failed to send OTP");
//     }
//   };

//   const handleOtpVerification = async () => {
//     try {
//       const otpFormData = new FormData();
//       otpFormData.append("logintype", "verify_otp_sign_up");
//       otpFormData.append("email", formData.workEmail);
//       otpFormData.append("otp", otp);
//       otpFormData.append("device_id", "search");

//       const response = await axiosInstance.post(OTP_VERIFICATION_URL, otpFormData);

//       if (response.data.status === 1) {
//         const { token_data, rights_navigation, rights_action } = response.data;
        
//         // Save token
//         localStorage.setItem("token", token_data.token);
        
//         // Save navigation rights
//         if (rights_navigation) {
//           updateData("rights_navigation", rights_navigation);
//           updateData("rights_action", JSON.stringify(rights_action));
//         }
        
//         // Update parent component token
//         setToken(response.data);
        
//         // Save email if remember me is checked
//         if (rememberChecked) {
//           addUniqueEmail(formData.workEmail);
//         }

//         toast.success("SignUp successful!");
//         setLoggedIn(false);
//         setRedirectToHome(true);
//       } else {
//         toast.error(response.data.msg || "Invalid OTP");
//       }
//     } catch (error) {
//       toast.error(error.response?.data?.msg || "OTP verification failed");
//     }
//   };

//   if (redirectToHome || token) {
//     return <Navigate to="/home" />;
//   }

//   return (
//     <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 px-4 py-8 md:py-12">
//       <div className="max-w-6xl mx-auto grid lg:grid-cols-2 gap-8 items-start">
//         {/* Left Column */}
//         <div className="space-y-8 lg:sticky lg:top-8">
//           <h1 className="text-4xl md:text-5xl font-bold leading-tight bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
//             Schedule A Customized<br />
//             Consultation With An Expert
//           </h1>
//           <p className="text-gray-600 text-lg md:text-xl leading-relaxed">
//             Discover how Viral Pitch can elevate your influencer marketing strategy to new heights.
//           </p>
//           <ul className="space-y-4 text-gray-700 text-lg">
//             {[
//               "Request a free influencer marketing demo",
//               "Learn how we assist in identifying the perfect influencers",
//               "Discover strategies to elevate your brand's presence",
//               "Have all your burning questions answered!",
//             ].map((item, index) => (
//               <li key={index} className="flex items-start space-x-2">
//                 <span className="text-red-500 font-bold">✓</span>
//                 <span>{item}</span>
//               </li>
//             ))}
//           </ul>
//         </div>

//         {/* Right Column */}
//         <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8">
//           <h2 className="text-3xl font-bold mb-6 text-gray-800">Get a Demo</h2>
//           <form className="space-y-6" onSubmit={handleSignUp}>
//             <input
//               type="email"
//               name="workEmail"
//               placeholder="Work Email"
//               value={formData.workEmail}
//               onChange={handleInputChange}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//               required
//             />

//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//               <input
//                 type="text"
//                 name="firstName"
//                 placeholder="First Name"
//                 value={formData.firstName}
//                 onChange={handleInputChange}
//                 className="px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//               <input
//                 type="text"
//                 name="lastName"
//                 placeholder="Last Name"
//                 value={formData.lastName}
//                 onChange={handleInputChange}
//                 className="px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//             </div>

//             <input
//               type="text"
//               name="companyName"
//               placeholder="Company Name"
//               value={formData.companyName}
//               onChange={handleInputChange}
//               className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//               required
//             />

//             <div className="flex space-x-4">
//               <select
//                 name="countryCode"
//                 value={formData.countryCode}
//                 onChange={handleInputChange}
//                 className="px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               >
//                 <option value="+91">+91 (India)</option>
//                 <option value="+1">+1 (USA)</option>
//                 <option value="+44">+44 (UK)</option>
//               </select>
//               <input
//                 type="tel"
//                 name="phoneNumber"
//                 placeholder="Enter 10-digit number"
//                 value={formData.phoneNumber}
//                 onChange={handleInputChange}
//                 className="flex-grow px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//             </div>

//             <div className="space-y-2">
//               <p className="text-gray-700 font-medium">What best describes you?</p>
//               <div className="flex items-center space-x-4">
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="radio"
//                     name="userType"
//                     value="1"
//                     checked={formData.userType === "1"}
//                     onChange={handleInputChange}
//                     required
//                   />
//                   <span>Brand</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="radio"
//                     name="userType"
//                     value="2"
//                     checked={formData.userType === "2"}
//                     onChange={handleInputChange}
//                   />
//                   <span>Agency</span>
//                 </label>
//               </div>
//             </div>

//             <div className="relative">
//               <input
//                 type={showPassword ? "text" : "password"}
//                 name="password"
//                 placeholder="Password"
//                 value={formData.password}
//                 onChange={handleInputChange}
//                 className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//               <button
//                 type="button"
//                 className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
//                 onClick={() => setShowPassword(!showPassword)}
//               >
//                 {showPassword ? "🙈" : "👁️"}
//               </button>
//             </div>

//             <div className="relative">
//               <input
//                 type={showConfirmPassword ? "text" : "password"}
//                 name="confirmPassword"
//                 placeholder="Confirm Password"
//                 value={formData.confirmPassword}
//                 onChange={handleInputChange}
//                 className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
//                 required
//               />
//               <button
//                 type="button"
//                 className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
//                 onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//               >
//                 {showConfirmPassword ? "🙈" : "👁️"}
//               </button>
//             </div>

//             <div className="flex items-center space-x-2">
//               <input
//                 type="checkbox"
//                 checked={rememberChecked}
//                 onChange={(e) => setRememberChecked(e.target.checked)}
//                 className="w-4 h-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
//                 required
//               />
//               <p className="text-sm text-gray-700">
//                 I agree to the{" "}
//                 <a href="#" className="text-blue-500 hover:underline">
//                   terms and conditions
//                 </a>
//               </p>
//             </div>

//             <button
//               type="submit"
//               className="w-full py-3 bg-red-500 text-white font-medium rounded-lg hover:bg-red-600 transition"
//             >
//               Submit
//             </button>
            
//             <p className="text-center text-sm text-gray-600">
//               Already Registered?{" "}
//               <Link to="/login" className="text-blue-500 hover:underline">
//                 Login
//               </Link>
//             </p>
//           </form>
//         </div>
//       </div>
//       {/* OTP Modal */}
//       <Modal show={loggedIn} onClose={() => setLoggedIn(false)}>
//         <Modal.Header>OTP Verification</Modal.Header>
//         <Modal.Body className="flex flex-col justify-center">
//           <OTPInput
//             value={otp}
//             onChange={setOtp}
//             numInputs={6}
//             containerStyle={{ display: "flex", justifyContent: "center" }}
//             renderSeparator={<span className="mr-2"></span>}
//             inputStyle={{ width: "50px", borderRadius: "20px" }}
//             renderInput={(props) => (
//               <input 
//                 className="w-[50px] h-[50px] text-center border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500" 
//                 {...props} 
//               />
//             )}
//           />
//           {seconds === 0 ? (
//             <p 
//               onClick={sendOtp} 
//               className="text-center mt-4 text-blue-500 hover:text-blue-600 cursor-pointer underline"
//             >
//               Didn't receive code? Resend OTP
//             </p>
//           ) : (
//             <p className="text-center mt-4 text-gray-600">
//               Resend OTP in {seconds} seconds
//             </p>
//           )}
//         </Modal.Body>
//         <Modal.Footer className="flex justify-center">
//           <button
//             className="bg-[#686FFD] text-white px-6 py-2.5 rounded-lg hover:bg-blue-600 transition duration-200"
//             onClick={handleOtpVerification}
//             disabled={otp.length !== 6}
//           >
//             Verify OTP
//           </button>
//           <button
//             className="ml-4 px-6 py-2.5 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition duration-200"
//             onClick={() => setLoggedIn(false)}
//           >
//             Cancel
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }
// <ToastContainer />
// SignUp.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };

// export default SignUp;







