import { SET_ENG_RATE_FILTER } from "../ActionTypes";

const initialState = [];

const EngRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENG_RATE_FILTER:
      return action.payload || [];
    default:
      return state;
  }
};

export default EngRateReducer;
