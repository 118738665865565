import React, { useState } from "react";

const PaymentHistory = () => {
  const [paymentData, setPaymentData] = useState([
    {
      orderId: "VP-1732220775-4372",
      billingDate: "22 Nov 2024",
      amount: "Rs. 1,180.00",
      paymentMethod: "Retry",
      status: "Not Started",
    },
    {
      orderId: "VP-1731355750-7103",
      billingDate: "12 Nov 2024",
      amount: "Rs. 5,389.06",
      paymentMethod: "Retry",
      status: "Not Started",
    },
    {
      orderId: "VP-16638777787-7144",
      billingDate: "23 Sep 2022",
      amount: "Rs. 11,798.82",
      paymentMethod: "Retry",
      status: "Not Started",
    },
    {
      orderId: "VP-1663791925-21155",
      billingDate: "15 Sep 2022",
      amount: "Rs. 118.00",
      paymentMethod: "IMPS",
      status: "Success",
    },
    {
      orderId: "VP-1663704220-58102",
      billingDate: "10 Sep 2022",
      amount: "Rs. 118.00",
      paymentMethod: "IMPS",
      status: "Success",
    },
    {
      orderId: "VP-1663703642-15971",
      billingDate: "08 Sep 2022",
      amount: "Rs. 118.00",
      paymentMethod: "NEFT",
      status: "Success",
    },
  ]);

  const retryPayment = (orderId) => {
    console.log(`Retry payment for order ID: ${orderId}`);
    // Implement your retry logic here
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-xl font-semibold text-gray-800 mb-6">
        Payment History
      </h2>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-3 px-6 bg-gray-200 font-semibold text-sm text-gray-700 text-left border-b">
                Order ID
              </th>
              <th className="py-3 px-6 bg-gray-200 font-semibold text-sm text-gray-700 text-left border-b">
                Billing Date
              </th>
              <th className="py-3 px-6 bg-gray-200 font-semibold text-sm text-gray-700 text-left border-b">
                Amount
              </th>
              <th className="py-3 px-6 bg-gray-200 font-semibold text-sm text-gray-700 text-left border-b">
                Payment Method
              </th>
              <th className="py-3 px-6 bg-gray-200 font-semibold text-sm text-gray-700 text-left border-b">
                Status
              </th>
              <th className="py-3 px-6 bg-gray-200 font-semibold text-sm text-gray-700 text-left border-b">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {paymentData.map((payment, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="py-3 px-6 border-b text-sm text-gray-600">
                  {payment.orderId}
                </td>
                <td className="py-3 px-6 border-b text-sm text-gray-600">
                  {payment.billingDate}
                </td>
                <td className="py-3 px-6 border-b text-sm text-gray-600">
                  {payment.amount}
                </td>
                <td className="py-3 px-6 border-b text-sm text-gray-600">
                  {payment.paymentMethod}
                </td>
                <td
                  className={`py-3 px-6 border-b text-sm font-semibold ${
                    payment.status === "Not Started"
                      ? "text-red-600"
                      : "text-green-600"
                  }`}
                >
                  {payment.status}
                </td>
                <td className="py-3 px-6 border-b">
                  {payment.status === "Not Started" ? (
                    <button
                      onClick={() => retryPayment(payment.orderId)}
                      className="bg-red-500 hover:bg-red-600 text-white text-sm font-medium py-1 px-4 rounded"
                    >
                      Retry
                    </button>
                  ) : (
                    <span className="text-green-600 font-semibold">
                      Success
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentHistory;
