import React, { useEffect, useRef, useState } from "react";
import "antd";
import { Select } from "antd";
import { createPortal } from "react-dom";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  setAudienceLanguageFilter,
  setCreatorLanguageFilter,
} from "../../redux/filters/actions";
import MultiSelectDropdown from "./MultiSelectDropdown";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import axiosInstance from "../../interseptor";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign, MdPeopleAlt } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const percentageArr = ["1", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95"];

const CreatorLanguage = ({
  hoverStates,
  handleMouseOver,
  sendToSearchResultFilter,
  selectedPlatform,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchParam, setSearchParam] = useSearchParams();

  const [languageOptions, setLanguageOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const creatorLanguageURL = searchParams.get("creatorLanguage");
  const audienceLanguageURL = searchParams.get("audienceLanguage");

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition()

  const [selectedOption, setSelectedOption] = useState({});

  const audienceLanguageArray = audienceLanguageURL
    ? audienceLanguageURL.split(",").map((value) => {
        const parts = value.split("-k-");
        return {
          id: parts[0],
          name: parts[1],
          percentage: parts[2],
        };
      })
    : [];
  const creatorLanguageArray = creatorLanguageURL
    ? creatorLanguageURL.split(",")
    : [];

  const fetchLanguageData = async () => {
    setLanguageOptions([]);
    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append("filter", "languages");
      formData.append("logintype", "getfilters");
      formData.append("device_id", "search");
      formData.append(
        "platform",
        selectedPlatform === "Instagram"
          ? "instagram"
          : selectedPlatform === "YouTube"
          ? "youtube"
          : selectedPlatform === "TikTok"
          ? "tiktok"
          : ""
      );

      const response = await axiosInstance.post(
        "livesearch/getfilters",
        formData
      );
      const result = response.data;

      if (result.status === 1) {
        setLanguageOptions(result.response.data.languages);
      } else {
        setError(result.msg);
        console.error("Error:", result.msg);
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchLanguageClick = () => {
    fetchLanguageData();
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown
  };

  const handleLanguageChange = (selectedList) => {
    const selectedValues = selectedList.map(
      (item) => item.code + "-k-" + item.name
    );

    dispatch(setCreatorLanguageFilter(selectedValues));

    if (selectedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("creatorLanguage", selectedValues.join(","));
        return searchParams;
      });
    } else {
      setSearchParam((searchParams) => {
        searchParams.delete("creatorLanguage");
        return searchParams;
      });
    }
  };

  const handleAudienceLanguageChange = (selectedList) => {
    const selectedValues = selectedList.map(
      (item) => `${item.code}-k-${item.name}-k-${selectedOption[item.code] || '5'}` // Use the selected option for each item
    );

    dispatch(setAudienceLanguageFilter(selectedValues));

    if (selectedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("audienceLanguage", selectedValues.join(","));
        return searchParams;
      });
    } else {
      setSearchParam((searchParams) => {
        searchParams.delete("audienceLanguage");
        return searchParams;
      });
    }

    setIsDropdownOpen(false)
  };

  const handlePercentageChange = (id, value) => {
    setSelectedOption((prev) => {
      const updatedOptions = {
        ...prev,
        [id]: value,
      };
      const updatedValues = audienceLanguageArray.map(
        (item) => `${item.id}-k-${item.name}-k-${updatedOptions[item.id] || "5"}`
      );

      dispatch(setAudienceLanguageFilter(updatedValues));
      setSearchParam((searchParams) => {
        if (updatedValues.length > 0) {
          searchParams.set("audienceLanguage", updatedValues.join(","));
        } else {
          searchParams.delete("audienceLanguage");
        }
        return searchParams;
      });

      return updatedOptions;
    });

    setIsDropdownOpen(false);
  };

  const selectedValueAudience = audienceLanguageArray.map((value) => ({
    id: value.id,
    name: value.name,
  }));

  const selectedValueCreator = creatorLanguageArray.map((value) => {
    const [id, name] = value.split("-k-");
    return { id, name };
  });


  return (
    <>
      <div ref={buttonRef} className="CreatorLanguage flex justify-start">
        <div className="relative w-full">
          <button
            onClick={handleSearchLanguageClick}
            className="flex items-center bg-white"
          >
            <span
              className={`filterTextColor ${
                isDropdownOpen ? "font-semibold" : ""
              } flex items-center`}
            >
              Language
              {isDropdownOpen ? (
                <RxCross2 className="ml-1 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-1 text-lg" />
              )}
            </span>
          </button>
          <ul
            ref={dropdownRef}
            className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${
              isDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex gap-5">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-2">
                  <MdPeopleAlt className="text-2xl" /> Audience
                  <InfoIconTooltip
                    infoContent={`The languages used by the engaged audience.`}
                  />
                </div>
                <MultiSelectDropdown
                  options={languageOptions}
                  onSelect={handleAudienceLanguageChange}
                  onRemove={handleAudienceLanguageChange}
                  placeholder={"Select Language"}
                  selectedValues={selectedValueAudience}
                  loading={loading}
                />

                {selectedValueAudience.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between"
                  >
                    <span>{item.name}</span>
                    <select
                      value={selectedOption[item.id] || "5"}
                      onChange={(e) =>
                        handlePercentageChange(item.id, e.target.value)
                      }
                      className="cursor-pointer hover:bg-gray-200 rounded-lg shadow-md"
                      style={{
                        border: "0.6px solid #D9D9D9",
                        maxHeight: "400px",
                        overflowY: "auto",
                      }}
                    >
                      {percentageArr.map((option) => (
                        <option key={option} value={option}>
                          {`>${option}%`}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>

              <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Influencer
                  <InfoIconTooltip
                    infoContent={`Identify influencers by their language. We determine language of influencers by analyzing captions of recent posts.`}
                  />
                </div>
                <MultiSelectDropdown
                  options={languageOptions}
                  onSelect={handleLanguageChange}
                  onRemove={handleLanguageChange}
                  placeholder={"Select Language"}
                  selectedValues={selectedValueCreator}
                  loading={loading}
                />
              </div>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </ul>
        </div>
      </div>
    </>
  );
};

export default CreatorLanguage;