import React, { useEffect, useState } from "react";
import InfoIconTooltip from "./InfoIconTooltip";
import { useLocation, useSearchParams } from "react-router-dom";

const IncludeContact = () => {
	const [searchParam, setSearchParam] = useSearchParams();
	const quaryIncludeContact = searchParam.get("includeContact");

	const [isChecked, setIsChecked] = useState(
		quaryIncludeContact ? true : false
	);

	const handleButtonClick = () => {
        console.log(isChecked);
        if (!isChecked) {
			setSearchParam((searchParams) => {
				searchParams.set("includeContact", true);
				return searchParams;
			});
		} else {
			setSearchParam((searchParams) => {
				searchParams.delete("includeContact");
				return searchParams;
			});
		}
		setIsChecked(!isChecked);
	};

	return (
		<div className="isVerified flex justify-start">
			<div className="relative w-full">
				<button
					onClick={handleButtonClick}
					className="flex items-center w-full justify-between bg-white"
				>
					<span className={`text-[#3D3D3D] flex items-center`}>
						<input
							id="checkid"
							type="checkbox"
							className="rounded-md border-[0.6px] border-[#D9D9D9] mr-2"
							checked={isChecked}
							onChange={handleButtonClick}
						/>
						Include Contact details and demographic data
					</span>
					<InfoIconTooltip
						infoContent={
							"Include Contact Details like Email, Phone number of the influencer or brands to contact to. Also, their current region and location serving or they are present in (office)"
						}
					/>
				</button>
			</div>
		</div>
	);
};

export default IncludeContact;
