import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Select } from "antd";
import "antd";
import { useDispatch } from "react-redux";
import { setAudienceGenderFilter, setGenderFilter } from "../../redux/filters/actions";
import MultiSelectDropdown from "./MultiSelectDropdown";
import InfoIconTooltip from "./InfoIconTooltip";
import axiosInstance from "../../interseptor";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign, MdPeopleAlt } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const percentageArr = ["1", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95"];

const CreatorGender = ({ hoverStates, handleMouseOver, sendToSearchResultFilter, selectedPlatform }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const creatorGenderURL = searchParams.get("creatorGender");
  const audienceGenderURL = searchParams.get("audienceGender");

  const audienceGenderArray = audienceGenderURL ? audienceGenderURL.split(",").map(value => {
    const parts = value.split("-k-");
    return {
      name: parts[0],
      percentage: parts[1] || '50'
    };
  }) : [];
  const creatorGenderArray = creatorGenderURL ? creatorGenderURL.split(",") : [];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const [creatorGender, setCreatorGender] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [audienceGenderData, setAudienceGenderData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({}); // Store percentage options

  const fetchCreatorGenderData = async () => {
    setCreatorGender([]);
    setAudienceGenderData([]);
    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append("filter", "gender");
      formData.append("logintype", "getfilters");
      formData.append("device_id", "search");
      formData.append("platform", selectedPlatform);
      formData.append("gender_audience", "creator");

      const response = await axiosInstance.post("livesearch/getfilters", formData);
      const result = response.data;

      if (result.status === 1) {
        setCreatorGender(result.response.data.data);
        setAudienceGenderData(result.response.data.data);
      } else {
        setError(result.msg);
        console.error("Error:", result.msg);
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchCreatorGender = () => {
    fetchCreatorGenderData();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePercentageChange = (name, value) => {
    const updatedOptions = {
      ...selectedOption,
      [name]: value
    };

    const updatedValues = audienceGenderArray.map(
      (item) => `${item.name}-k-${updatedOptions[item.name] || '50'}`
    );

    dispatch(setAudienceGenderFilter(updatedValues));
    setSearchParam((searchParams) => {
      if (updatedValues.length > 0) {
        searchParams.set("audienceGender", updatedValues.join(","));
      } else {
        searchParams.delete("audienceGender");
      }
      return searchParams;
    });

    setSelectedOption(updatedOptions);
  };

  const handleAudienceGender = (selectedList) => {
    // Check if "Any" is in the selected list
    const hasAny = selectedList.some((item) => item.name === 'Any');
    
    // Build the selectedValues array
    const selectedValues = selectedList.map((item) => {
      // If "Any" is selected, set it without a percentage
      if (item.name === 'Any') {
        return `${item.name}`;
      }
      // Otherwise, include percentage if not "Any"
      return `${item.name}-k-${selectedOption[item.name] || '50'}`;
    });
  
    // If "Any" is selected, ensure it is the only filter applied
    if (hasAny) {
      dispatch(setAudienceGenderFilter([ 'Any' ]));
      setSearchParam((searchParams) => {
        searchParams.set("audienceGender", 'Any');
        return searchParams;
      });
    } else {
      dispatch(setAudienceGenderFilter(selectedValues));
      setSearchParam((searchParams) => {
        if (selectedValues.length > 0) {
          searchParams.set("audienceGender", selectedValues.join(","));
        } else {
          searchParams.delete("audienceGender");
        }
        return searchParams;
      });
    }
  };

  const handleCreatorGender = (selectedList) => {
    const selectedValues = selectedList.map((item) => item.name);
    dispatch(setGenderFilter(selectedValues));

    if (selectedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("creatorGender", selectedValues.join(","));
        return searchParams;
      });
    } else {
      setSearchParam((searchParams) => {
        searchParams.delete("creatorGender");
        return searchParams;
      });
    }
  };

  return (
    <div ref={buttonRef} className="CreatorGender flex justify-start">
      <div className="relative w-full">
        <button
          onClick={handleSearchCreatorGender}
          className="flex items-center bg-white filterTextColor"
        >
          <span
            className={`${
              isDropdownOpen ? "font-semibold" : ""
            } flex items-center`}
          >
            Gender
            {isDropdownOpen ? (
              <RxCross2 className="ml-2 text-lg" />
            ) : (
              <IoChevronDownSharp className="ml-2 text-lg" />
            )}
          </span>
        </button>
        <ul
          ref={dropdownRef}
          className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${
            isDropdownOpen ? "block" : "hidden"
          }`}
        >
          <div className="flex gap-5">
            <div className="flex flex-col gap-2 w-full">
              <div className="flex items-center gap-2">
                <MdPeopleAlt className="text-2xl" /> Audience
                <InfoIconTooltip
                  infoContent={`The gender of the engaged audience`}
                />
              </div>
              <MultiSelectDropdown
                options={audienceGenderData}
                onSelect={handleAudienceGender}
                onRemove={handleAudienceGender}
                placeholder={"Select Gender"}
                selectedValues={audienceGenderArray.map((value) => ({
                  name: value.name,
                }))}
                loading={loading}
              />
              {audienceGenderArray.map((item) => (
                <div
                  key={item.name}
                  className="flex items-center justify-between"
                >
                  <span>{item.name}</span>
                  {item.name !== "Any" && (
                    <select
                      value={selectedOption[item.name] || "50"}
                      onChange={(e) =>
                        handlePercentageChange(item.name, e.target.value)
                      }
                      className="cursor-pointer hover:bg-gray-200 rounded-lg shadow-md"
                      style={{ border: "0.6px solid #D9D9D9" }}
                    >
                      {percentageArr.map((option) => (
                        <option key={option} value={option}>
                          {`>${option}%`}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
            </div>

            <div className="flex flex-col gap-2 w-full">
              <div className="flex items-center gap-2">
                <MdCampaign className="text-2xl" /> Influencer
                <InfoIconTooltip
                  infoContent={`Identify influencers by gender. We determine gender of influencers by analyzing profile photos, name and text in profile bio and selfies in recent posts.`}
                />
              </div>
              <MultiSelectDropdown
                options={creatorGender}
                onSelect={handleCreatorGender}
                onRemove={handleCreatorGender}
                placeholder={"Select Gender"}
                selectedValues={creatorGenderArray.map((value) => ({
                  name: value,
                }))}
                loading={loading}
              />
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default CreatorGender;