// redux/handleSubmitReducer.js
const initialState = {
	handleSubmitFunction: false, // Initial state is false, can toggle to true
};

const handleSubmitReducer = (state = initialState, action) => {
	switch (action.type) {
		case "TOGGLE_HANDLE_SUBMIT":
			return {
				...state,
				handleSubmitFunction: !state.handleSubmitFunction, 
			};
		default:
			return state;
	}
};

// Action creator to toggle the handleSubmitFunction state
export const toggleHandleSubmit = () => ({
	type: "TOGGLE_HANDLE_SUBMIT",
});

export default handleSubmitReducer;
